import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FacebookEventDialogComponent } from '../../components/social/facebook-events/facebook-event-dialog/facebook-event-dialog.component'
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { leftJoin } from 'src/app/services/firestore.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { switchMap } from 'rxjs/operators';

export interface EventElement {
  id: string;
  name: string;
  description: string;
  place?: any;
  start_time?: string;
  end_time?: string;
  ticket_uri?: string;
  cover?: any;
  ELEMENT_DATA: any;
}
@Component({
  selector: 'app-events-main',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class EventsComponent implements OnInit {
  //ELEMENT_DATA: EventElement[];
  dataSource = [];
  columnsToDisplay = ['start_time', 'name', 'ticket_uri'];
  expandedElement: EventElement;
  events: any;
  eventos: any[];
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  user: any;
  paging: any;
  public dialogRef: MatDialogRef<FacebookEventDialogComponent>;

  selectedIndex: number;
  transform: number;
  dataSize: number;
  type = 'slider';
  ELEMENT_DATA: any;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private afs: AngularFirestore,
    public authService: AuthService
  ) { }

  ngOnInit() {
    let date: Date = new Date();
    let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    let fakeDate = '2019-' + date.getMonth() + '-' + date.getDate();

    this.authService.user$.subscribe(user => this.user = user);
    //Slider fields
    this.selectedIndex = 0;
    this.transform = 100;
    //this.events = this.getEventsList(this.startAt);
    this.getEvents();
  }

  getEvents() {
    this.authService.user$.subscribe(user => this.user = user);
    //this.polls$ = this.pollsService
    //this.pages = this.getPageList(this.startAt);
    const y = this.afs.collection('events', ref => ref.orderBy('start', 'asc')).snapshotChanges();
    y.subscribe(items => {
      //this.spinnerService.hide();
      this.eventos = [];
      items.forEach(item => {
        this.eventos.push({
          id: item.payload.doc.id,
          data: item.payload.doc.data()
        });
      });
    });
  }

  search(searchText) {
    this.startAt.next(searchText);
  }

  //Get Firebase Filtered Query
  getEventsFiltered() {
    let date: Date = new Date();
    let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    let fakeDate = '2019-' + date.getMonth() + '-' + date.getDate();
    //return this.afs.collection('events', ref => ref.where('questionId', '==', questionId))
    return this.afs.collection('events', ref => 
    ref
      //.where('timeKey', '<', endDate)
    )
      .snapshotChanges().pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(changes => {
          return changes.map(c => {
            console.log(c);
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            return { id, data };
          });
        }),
        //leftJoin(this.afs, 'uid', 'users')
      );
  }



  //Obserbable Filtered Query angularFire
  getEventsList(start: BehaviorSubject<string>): Observable<{}> {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        return this.afs
          .collection('events', ref =>
            ref
              .orderBy('title')
              .limit(10)
              .startAt(startText)
              .endAt(endText)
          )
          .snapshotChanges().pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(changes => {
              return changes.map(c => {
                //console.log(c);
                const data = c.payload.doc.data();
                const id = c.payload.doc.id;
                return { id, data };
              });
            }),
            leftJoin(this.afs, 'id', 'events')
          );
      }));
    
  }
  //JSON URL Query
  getEventsByUrl() {
    //console.log('Geting Events');
    let date: Date = new Date();
    let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    let fakeDate = '2019-' + date.getMonth() + '-' + date.getDate();
    const url = 'https://graph.facebook.com/' + Constants.page_id + '/events?access_token=' + Constants.accessToken + '&fields=event_times,name,start_time,ticket_uri,place,cover,description,end_time,timezone,type&since=' + fakeDate + '';


    //console.log('DATA EVENTS', url);
    this.http.get(url)
      .subscribe(data => {
        console.log('DATA EVENTS', data);
        //evento sin class
        this.ELEMENT_DATA = data['data'];
        this.ELEMENT_DATA.sort(function (a, b) {
          var keyA = new Date(a.start_time),
            keyB = new Date(b.start_time);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        console.log(this.ELEMENT_DATA);
        this.dataSource = this.ELEMENT_DATA;
        this.dataSize = data['data'].length;
      });
  }


  openFacebookEventDialog(index: number): void {
    this.dialogRef = this.dialog.open(FacebookEventDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box',
    });
    console.log('openFacebookEventDialog', index);
    this.dialogRef.componentInstance.events = this.dataSource;
    this.dialogRef.componentInstance.i = index;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogRef = null;
    });
  }

  /* Slider */
  // Slider Controlls
  selected(x) {
    this.downSelected(x);
    this.selectedIndex = x;
  }
  selectedPrev(x) {
    console.log('Prev Slider', x);
    this.downSelected((x - 1));
    this.selectedIndex = (x - 1);
  }
  selectedNext(x) {
    console.log('Next Slider', x);
    this.downSelected((x + 1));
    this.selectedIndex = (x + 1);
  }



  downSelected(i) {
    this.transform = 100 - (i) * 50;
    this.selectedIndex = this.selectedIndex + 1;
    if (this.selectedIndex > this.eventos.length) {
      this.selectedIndex = 0;
    }
  }
  /*
    getPosts() {
      console.log("Geting Post");
      console.log("get facebook events");
      // tslint:disable-next-line: max-line-length
      //const accessToken = 'EAAJPWEwTdqYBADhPteSWUwGFLhE5zYprqGtdi6fUfUDs2NAfZB9EDAklrekCAppowceYk9eF468v63IMvvNxwuA94DaSvRYiDH6L3dAevxJbIxVzsxf5BJVKdHOZBTZClzFXbYv428kbZBe0639poG4J7wkEqhAKyOaV229ifAZDZD';
      // tslint:disable-next-line: max-line-length
      const accessToken = 'EAAJPWEwTdqYBAKQx3loGWsnhfnVadPOcpZBL1h8vY0uX0ZAkVJDjoWivtJiVIz9ZCC7tfNCHI2M2iZBFfZBCe2aiIuFd3YelA1O6nSOVzuIDuQtYRtjna7R6ShZBWmts2hiVVmpMz092FPAnvJfdBKFEjUBWK8l1ZBrZBWQEZAswpqAZDZD';
      return this.fb.api(
          "/126539221037/events/?access_token=" + accessToken,
          'get',
          { "type": "attending", "q": "a", "limit": "100", "since": "0", "until": "next year" }
        );
    }*/
}
