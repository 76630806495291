// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments_list_item {
  font-size: 13px;
  margin: 10px 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZhY2Vib29rLWZlZWQtZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZUFBQTtFQUNBLGlCQUFBO0FBQ0oiLCJmaWxlIjoiZmFjZWJvb2stZmVlZC1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29tbWVudHNfbGlzdF9pdGVtIHtcbiAgICBmb250LXNpemU6IDEzcHg7XG4gICAgbWFyZ2luOiAxMHB4IDE2cHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/social/facebook-feed/facebook-feed-dialog/facebook-feed-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;AACJ;AACA,wYAAwY","sourcesContent":[".comments_list_item {\n    font-size: 13px;\n    margin: 10px 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
