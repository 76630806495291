import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/takeWhile";
import "rxjs/add/operator/startWith";
declare var $: any;

@Component({
  selector: 'app-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss']
})
export class SplitComponent implements OnInit {
  @Input() itemsArray: [];
  @Input() cols: any;
  @Input() swipe: boolean;
  columns:number;
  rowHeight: any;
    
  rowWidth: any;
  options: any;
  constructor(private observableMedia: MediaObserver) {
  }

  ngOnInit() {
    this.options = {
      dots: false,
      responsive: {
        "0": { items: (this.cols > 3) ? 2 : 1, margin: 5 },
        "430": { items: (this.cols > 3) ? 2 : 1, margin: 5 },
        "550": { items: 2, margin: 5 },
        "750": { items: (this.cols > 3) ? 3 : this.cols, margin: 5 },
        "950": { items: this.cols, margin: 5 }
      },
      autoplay: false,
      loop: false,
      autoplayTimeout: 3000,
      lazyLoad: true
     
    };
    
    if (this.swipe == false){
      switch (this.cols) {
        case '2':
          this.rowWidth = '48%';
          break;
        default:
          this.rowWidth = '100%';          
          break;
      }
    }else{
      this.rowWidth= '100%';
    }
    const grid = new Map([
      ["xs", 1],
      ["sm", 1],
      ["md", 1],
      ["lg", 2],
      ["xl", 2]
    ]);
    let start: number;
    grid.forEach((cols, mqAlias) => {
      if (this.observableMedia.isActive(mqAlias)) {
        start = cols;
      }
    });
    // on breakpoint, assign cols AND ROW HEIGHT appropriately
    /*this.box = this.observableMedia.asObservable()
      .map(change => {
        console.log(change);
        console.log(grid.get(change.mqAlias));
        this.rowHeight = this.heightToCols(grid.get(change.mqAlias));
        console.log(this.rowHeight);

        return grid.get(change.mqAlias);

      })
      .startWith(start);
*/

  }
  /**
    * The number of colums in the mat-grid-list directive.
    */


  public box: Observable<number>;
  heightToCols(cols: number): number {
    if (window.innerWidth <= 769) {
      return window.innerWidth * 0.9;
    } else if (window.innerWidth <= 960) {
      return window.innerWidth * 0.65;
    } else if (window.innerWidth <= 1280) {
      return window.innerWidth * 0.42;
    } else {
      return window.innerWidth * 0.3;
    }


  }
}
