import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl: string | null = null;
  public currentUrl: BehaviorSubject<string | null> = new BehaviorSubject(null);

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl.value;
      this.currentUrl.next(event.urlAfterRedirects);
    });
  }

  getPreviousUrl(): string | null {
    return this.previousUrl;
  }

  isNavigatingTo(targetUrl: string): boolean {
    return this.currentUrl.value === targetUrl;
  }
}
