import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit, AfterContentInit, HostBinding } from '@angular/core';

//import { FacebookService, InitParams } from 'ngx-facebook';
import { Observable, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { Constants } from 'src/app/shared/globals';
import { trigger, state, style, transition, animate, query, stagger, group } from '@angular/animations';


//declare const window: any;
// declare var FB: any;
// declare var FB: any;
// When the user scrolls down 20px from the top of the document, slide down the navbar
// When the user scrolls to the top of the page, slide up the navbar (50px out of the top view)

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
 
})

export class HomeComponent implements OnInit {


  @Input() page: any;
  @Input() blocks: any;
  @Input() listItems: any;
  @ViewChild('siteHead', { static: true }) header: ElementRef;
  @ViewChild('ajuste', { static: true }) ajuste: ElementRef;
  @ViewChild('scrollDown', { static: true }) scrollgif: ElementRef;
  sticky: number;
  state: string;
  constructor(private http: HttpClient,private el: ElementRef) {
  }


  ngOnInit() {


    // this.events = this.getEvents();
    // this.posts = this.getPosts();


    // (window as any).fbAsyncInit = function() {
    //   FB.init({
    //     appId      : '650190605481638',
    //     cookie     : true,
    //     xfbml      : true,
    //     version    : 'v3.3'
    //   });
    //   FB.AppEvents.logPageView();
    // };

    // (function(d, s, id){
    //    var js, fjs = d.getElementsByTagName(s)[0];
    //    if (d.getElementById(id)) {return;}
    //    js = d.createElement(s); js.id = id;
    //    js.src = "https://connect.facebook.net/en_US/sdk.js";
    //    fjs.parentNode.insertBefore(js, fjs);
    //  }(document, 'script', 'facebook-jssdk'));



/*

    const frameNumber = 0;
    const playbackConst = 1000;
    const setHeight = document.getElementById('set-height');
    const playbackPhoneConst = 1000;
    const setPhoneHeight = document.getElementById('set-phoneHeight');
    const mainVideo: HTMLVideoElement = <HTMLVideoElement>document.getElementById('v0');
    mainVideo.volume = 0;
    const phoneVideo: HTMLVideoElement = <HTMLVideoElement>document.getElementById('v1');
    phoneVideo.volume = 0;




    mainVideo.addEventListener('loadedmetadata', function () {
      setHeight.style.height = Math.floor(mainVideo.duration) * playbackConst - 380 + 'px';

    });

    phoneVideo.addEventListener('loadedmetadata', function () {
      setPhoneHeight.style.height = Math.floor(phoneVideo.duration) * playbackPhoneConst - 1080 + 'px';
    });

    function scrollPlay() {
      // tslint:disable-next-line: no-shadowed-variable
      const frameNumber = window.pageYOffset / playbackConst;
      mainVideo.currentTime = frameNumber;
      const framePhoneNumber = window.pageYOffset / playbackPhoneConst;
      phoneVideo.currentTime = framePhoneNumber;
      window.requestAnimationFrame(scrollPlay);
    }
    window.requestAnimationFrame(scrollPlay);
  }
  // ===========================================================================
  // TRY THIS
  // ===========================================================================


*/
 
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    this.scrollgif.nativeElement.classList.add("hidden");
  }
  
  scroll(id) {
    console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    el.scrollIntoView({ block: 'start',  behavior: 'smooth', inline: 'nearest' });
    this.scrollgif.nativeElement.classList.add('hidden')
  }
  
  /*@HostListener("window:scroll", [])
  onWindowScroll() {

    var ajuste = document.getElementById("ajuste");

    var header = document.getElementById("siteHead");
    var sticky = this.header.nativeElement.offsetHeight;
    //console.log('window.pageYOffset', window.pageYOffset);
    //console.log('sticky', sticky);
    //console.log('scroll diff', window.innerHeight - sticky);

    if (window.pageYOffset < 500) {
      header.classList.add("sticky");

      ajuste.classList.remove("top-padding");
    } else {
      header.classList.remove("sticky");
      //this.ajuste.nativeElement.css("marginTop", window.pageYOffset + sticky);

      ajuste.classList.add("top-padding");



    }
  }
  */


    /*
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number < 50) {
          console.log("scroll position", number);
          console.log("DES", document.getElementById('section1').offsetTop);
          //document.getElementById('section1').getElementsByTagName('img')[0].style.transform = "translate3d(0px, 0px, 0px)";
          //    console.log('You are 300px from the top to bottom');
        } else if (number > 1900) {
          console.log("scroll position", number);
          console.log("DES", document.getElementById('section1').offsetTop);
          
          //document.getElementById('section1').getElementsByTagName('img')[0].style.transform = 'translate3d(0%, -' + ((number - document.getElementById('section1').offsetTop) / 100) + '%, 0) scale(' + (100 + (number - document.getElementById('section1').offsetTop) / 5) / 100 + ')';
          //Blur suggestion from @janwagner: https://codepen.io/janwagner/ in comments
          //"-webkit-filter": "blur(" + (scroll/200) + "px)",
          //filter: "blur(" + (scroll/200) + "px)"
    
          console.log('You are 400px from the top to bottom');
        }*/



  /*
  var imageSrc = "";
  var currentImage = "";
  var imageCaption = "";
  var interfaceLock = false;
  
  var largerImage = "";
  var speechBubble = "";
  
  
  
  
  
  window.resize(function () {
    showGaleria();
  });
  
  */







  submitLogin() {
    console.log('submit login to facebook');
    // FB.login();
  }
  openDialog(link) {
    //alert('velez');
    //alert(link);
  }
}
