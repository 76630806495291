import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/core/auth.service';
//import { PollsService } from '../polls.service';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { map } from 'rxjs/internal/operators/map';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { FirestoreService } from 'src/app/services/firestore.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-page-admin',
  templateUrl: './page-admin.component.html',
  styleUrls: ['./page-admin.component.scss']
})
export class PageAdminComponent implements OnInit {
  pages: Observable<{}>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  user: any;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  pagesItems: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private afs: AngularFirestore,
    //private pollsService: PollsService,
    public authService: AuthService,
    public dialog: MatDialog,
    private db: FirestoreService,
    
  ) { }

  ngOnInit() {
    this.authService.user$.subscribe(user => this.user = user);
    //this.polls$ = this.pollsService
    this.pages = this.getPageList(this.startAt);

     const y = this.afs.collection('pages', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      y.subscribe(items => {
        //this.spinnerService.hide();
        this.pagesItems = [];
        items.forEach(item => {
          this.pagesItems.push({
            id: item.payload.doc.id,
            data: item.payload.doc.data()
          });
        });
      });
  }
  // Listado para buscar en el admin
  getPageList(start: BehaviorSubject<string>): Observable<{}> {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        return this.afs
          .collection('pages', ref =>
            ref
              .orderBy('order', 'asc')
              //.limit(10)
              //.startAt(startText)
              //.endAt(endText)
          )
          .snapshotChanges().pipe(
            debounceTime(400),
            distinctUntilChanged(),
            map(changes => {
              return changes.map(c => {
                console.log(c);
                const data = c.payload.doc.data();
                const id = c.payload.doc.id;
                return { id, data };
              });
            }),
            //leftJoin(this.afs, 'pollId', 'polls')
          );
      })
    );
  }
  search(searchText) {
    this.startAt.next(searchText);
  }

  public deletePage(pageId) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Seguro deseas borrar esta página?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.afs.collection('pages').doc(pageId).delete().then(() => {
          console.log('Documento eliminado!');
        }, (error) => {
          console.error(error);
        });
      }
      this.dialogRef = null;
    });
  }


  public toggleStatus(id, status) {
    this.afs.collection('pages').doc(id)
      .update({ status: !status });
  }


  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);
    moveItemInArray(this.pagesItems, event.previousIndex, event.currentIndex);
    // moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    //moveItemInArray(this.galleries, event.previousIndex, event.currentIndex);

    /* console.log("PreviousIndex" + event.previousIndex);
     console.log("CurrentIndex" + event.currentIndex);
     console.log("Poll ID" + this.PollModel.id);
     console.log("Question ID" + this.pollQuestions[event.currentIndex].id);
    */

   

  

    this.pagesItems.forEach((item, index) => {
      if (item !== undefined &&  index !== undefined) {
        console.log("List items", item); // 1, 2, 3
        //console.log(item); // 1, 2, 3
        console.log("List index", index); // 1, 2, 3
        const data = {
          order: index,
          updatedOn: new Date()
        };
        this.afs.collection('pages').doc(item.id).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
         // this.router.navigate(["/pages/admin/"]);
        }, (error) => {
          console.log(error);
        });
      }
    });
    
    console.log('Haz realizado cambios en el orden del listado');
    

  }
}
