export interface Roles {
  subscriber?: boolean;
  editor?: boolean;
  admin?: boolean;
}
export class User {
  uid: string;
  displayName?: string;
  email?: string;
  location?: {
    lat: number;
    lon: number;
  };
  phoneNumber?: string;
  createdOn: Date;
  roles: Roles;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  sex?: string;
  birthday?: any;
  state?: string;
  terms?:boolean;
}

export class UserDetails {
  displayName: string;
  lastName: string;
  sex: string;
  birthday: Date;
  state: string;
  constructor(data: any) {
    data = data || {};
    this.displayName = data.displayName;
   
    if (data.lastName) {
      this.lastName = data.lastName;
    }
    if (data.sex) {
      this.sex = data.sex;
    }
    if (data.birthday) {
      this.birthday = data.birthday;
    }
    if (data.state) {
      this.state = data.state;
    }
  }
}
