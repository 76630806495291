import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-whell',
  templateUrl: './whell.component.html',
  styleUrls: ['./whell.component.scss']
})
export class WhellComponent implements OnInit {
  constructor() { }
  interfaceLock = false;
  currentImage: string;
  imageOverSrc: any;
  imageSrc: any;
  largerImage: any;
  imageCaption: any;
  imageLink: any;
  imageTitle: any;
  speechBubble: any;
  captionObject: any;



  //angleOffset: Math.PI,

  ngOnInit() {

    /*// tslint:disable-next-line: prefer-const
    const items:ElementRef[] = document.getElementById('circular-menu').getElementsByClassName('circle');
    console.log("ITEMS",items);
    for (var i = 0, l = items.length; i < l; i++) {
      console.log('aaaaaaaaaaaaa', i);
    //  items[i].getElementsByTagNameNS['a'].style.left = (50 - 35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)).toFixed(4) + "%";
    //  items[i].getElementsByTagNameNS['a'].style.top = (50 + 35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)).toFixed(4) + "%";
    }*/

//document.getElementsByClassName('circle').toggle('open');

  }
 
/*
  // on selecting/clicking an item on the wheel
  onSelect(selected) {
    this.showLargeImage(selected, 'noanim');
  }
  // on beginning to spin/animate the wheel
  onNextBegin(m) {
    this.interfaceLock = true;
  }
  onPrevBegin(m) {
    this.interfaceLock = true;
  }
  // once an image has moved under the 'pick'
  onImageFocus(item) {
    this.showLargeImage(item, 'none');
  }
  // once an image/image set is no longer under the 'pick'
  onImageBlur(item) {

  }
  // once the animation has completed
  onAnimationComplete(menu) {
    //document.getElementsByClassName('.centerImage').attr('src', this.currentImage);
    //document.getElementsByClassName('.centerImage').load(function () {
    this.interfaceLock = false;
  };

  onShow(menuitems) {
    menuitems.each(function (i) {
      this.delay(i * 100).fadeIn(500);
    });
  }
  showLargeImage(i, mode) {
    this.interfaceLock = true;
    var thisImage = i.find('img');
    var thisImageOver = i.find('.over img');
    var thisLink = i.find('a');
    this.imageSrc = thisImage.attr('src');
    this.imageOverSrc = thisImageOver.attr('src');
    this.largerImage = this.imageSrc.replace('_bigger', '');
    this.imageCaption = thisImage.attr('alt');
    this.imageLink = thisLink.attr('href');
    this.imageTitle = thisLink.attr('title');
    this.speechBubble = ('.roundrr_speech');
    var captionObject = document.getElementById('#galeria-caption');
    var centeredImage = document.getElementsByClassName('.centerImage');
    var centerObject = document.getElementsByClassName('.galeria-central');
    this.speechBubble.fadeIn();
    this.currentImage = this.largerImage;
    this.captionObject.fadeIn();
    //captionObject.textContent(this.imageTitle);
    //center area: utilize the slimbox API for lightbox triggering
    //  centerObject.bind('click', function () {
    //    window.open(imageLink);
    //    // showLightbox();
    // });
     
    // hover case for central image
    // centeredImage.hover(function () {
    //    // $(this).css('opacity','0.5');
    //    centeredImage.attr('src', imageSrc);
    //  }, function () {
    //      //$(this).css('opacity','1');
    //      centeredImage.attr('src', imageOverSrc);
    //    });
    //   if (mode == 'noanim') {
    //     centeredImage.attr('src', imageOverSrc);
    //     centeredImage.load(function () {
    //       interfaceLock = true;
    //     });
    //   }
   
  }


  /*Show lightbox for current image*/
  showLightbox() {
    //jQuery.slimbox(largerImage, imageCaption, { overlayOpacity:0});
  }

  /*Spin the menu to the left*/
  spinMenuLeft() {
    if (!(this.interfaceLock)) {
      //   document.getElementById("#galeria-contenedor").("prev");
    }
  }

  /*Spin the menu to the right*/
  spinMenuRight() {
    if (!(this.interfaceLock)) {
      // jQuery("#galeria-contenedor").roundrrwheel("next");
    }
  }
  /*
  window.keydown(event ,{
    var keycode : event.keyCode
    switch (keycode) {
      case 39:
        spinMenuLeft();
        break;
      case 37:
        spinMenuRight();
        break;
      case 32:
        if (largerImage.length) {
          showLightbox();
        }
        break;
    }
  });
  };
  */
}
