import { NgModule } from '@angular/core';
import { NoAccessComponent } from './no-access/no-access.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthGuardAdmin, AuthGuardData } from './../core/auth.guard';
import { PageComponent } from './page/page.component';
import { PageAdminComponent } from './page-admin/page-admin.component';
import { PageEditComponent } from './page-edit/page-edit.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ItemViewComponent } from './item-view/item-view.component';
import { PageSocialLoginComponent } from './page-social-login/page-social-login.component';

const routes: Routes = [
  {
    path: 'pages',
    data: { preload: true , animation: 'isRight', title: 'page' },     
    children: [

      {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full',
        data: { preload: true , animation: 'isRight', title: 'page' }
      },
     
      {
        path: 'page/:id',
        component: PageComponent,
        data: { preload: true , animation: 'isLeft', title: 'admin', type: 'page' }

      },
      {
        path: 'admin',
        component: PageAdminComponent,
        canActivate: [AuthGuardAdmin],
        data: { preload: false , animation: 'isLeft', title: 'admin', type: 'admin' }
      },
      {
        path: 'create',
        component: PageEditComponent,
        canActivate: [AuthGuardAdmin],
        data: { preload: false , animation: 'isLeft', title: 'admin', type: 'admin' }
      },
      {
        path: 'edit/:id',
        component: PageEditComponent,
        canActivate: [AuthGuardAdmin],
        data: { preload: false , animation: 'isLeft', title: 'admin', type: 'admin' }
      },
      {
        path: 'socialLogin',
        component: PageSocialLoginComponent,
        canActivate: [AuthGuardAdmin],
        data: { preload: false , animation: 'isLeft', title: 'admin', type: 'admin' }
      },
      
     
     /* {
        path: '404', 
        component: PageNotFoundComponent
      },
      {
        path: '**', 
        redirectTo: '/404'
      }*/
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
