import { Component, OnInit, Input, HostListener, OnChanges } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GalleryDialogComponent } from '../gallery-dialog/gallery-dialog.component';
import { filter, timeInterval, timestamp } from 'rxjs/operators';
import { trigger, transition, query, style, stagger, animate, animateChild, state } from '@angular/animations';
//import { NgxMasonryOptions } from 'ngx-masonry';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-pint',
  templateUrl: './pint.component.html',
  styleUrls: ['./pint.component.scss'],
  animations: [
    trigger('pints', [
      transition(':enter', [
        query('@itemPint', stagger(100, animateChild()), { optional: true })
      ])
    ]),
    trigger('itemPint', [
      transition(':enter', [
        style({ transform: 'scale(1)', opacity: 0.1 }),  // Empieza pequeño
        animate('500ms ease-out', style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ transform: 'scale(1)', opacity: 0.1 }))
      ])
    ]),
  ],
  host: {
    '(window:resize)': 'onResize($event)',
    '(window:load)': 'onLoad($event)',

  },
})
export class PintComponent implements OnInit {

  public box: Observable<number>;
  public sizeDisplay: string = 'XSmall' || 'Small' || 'Medium' || 'Large' || 'XLarge';
  
  @Input() itemsArray: [];
  @Input() cols: any;
  @Input() columns: any;
  @Input() swipe: boolean;
  dialogGalleryRef: MatDialogRef<GalleryDialogComponent>;
  masonryEvents = ['load', 'resize'];
 
  rowHeight: any;
  rowWidth: any;
  options: any;
  watcher: any;
  activeMediaQuery: string;
  
  constructor(
    private mediaObserver: MediaObserver,
    public dialog: MatDialog,
    private viewportRuler: ViewportRuler,
    public breakpointObserver: BreakpointObserver
  ) {
    this.mediaQuery();
  }
  ngOnDestroy(): void {
    //  this.mediaObserver.asObservable().unsubscribe()
  }
  public mediaQuery() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          if (state.breakpoints[Breakpoints.XSmall]) {
            this.sizeDisplay = 'XSmall';
            this.columns = (this.cols > 3) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Small]) {
            this.sizeDisplay = 'Small';
            this.columns = (this.cols > 2) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Medium]) {
            this.sizeDisplay = 'Medium';
            this.columns = (this.cols > 2) ? this.cols : (this.cols === 2) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Large]) {
            this.sizeDisplay = 'Large';
            this.columns = this.cols;
          } else if (state.breakpoints[Breakpoints.XLarge]) {
            this.sizeDisplay = 'XLarge';
            this.columns = this.cols;
          }
          if (this.swipe) {
            this.rowWidth = 100;
          } else {
            this.rowWidth = 100 / this.columns;
          }
        }
      });
  }

  ngOnInit() {
    console.log("PINT INIT", this.cols)
    this.mediaQuery();
  }


  openGalleryDialog(pageId: string, galleryId: string = null): void {
    //  console.log('pageId', pageId);
    // console.log('galleryId', galleryId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.enterAnimationDuration = '400ms';
    dialogConfig.exitAnimationDuration = '400ms';
    dialogConfig.width = '90%';
    dialogConfig.maxWidth = '1000px';
    dialogConfig.maxHeight = '80vh';

    this.dialogGalleryRef = this.dialog.open(GalleryDialogComponent, dialogConfig);
    // items: Gallery[];
    const items: any = this.itemsArray.filter(
      item => item['id'] === galleryId);
    this.dialogGalleryRef.componentInstance.itemsArray = items;
    this.dialogGalleryRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogGalleryRef = null;
    });
  }
}
