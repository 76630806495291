import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { EventsRoutingModule } from './events-routing.module';
import { EventComponent } from './event/event.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventsAdminComponent } from './events-admin/events-admin.component';
import { EventsComponent } from './events/events.component';
import { ProgramDialogComponent } from './program-dialog/program-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EventsRoutingModule
    ],
    declarations: [
        EventComponent,
        EventEditComponent,
        EventsAdminComponent,
        EventsComponent,
        ProgramDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsModule { }
