import { Component, OnInit, HostBinding, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { progressiveImage } from "../../../../assets/js/progressiveImage";
//declare const progressiveImage: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '200px',
        transform: 'translateX(-100%)',
        opacity: 1,
        backgroundColor: 'yellow',
        left: '0px'
      })),
      state('closed', style({
        height: '100px',
        transform: 'translateX(100%)',
        opacity: 0.5,
        backgroundColor: 'green',
        right: '0px'
      })),
      transition('*=>open', animate('300ms')),
      transition('*=>closed', animate('2000ms'))

    ]),
    trigger('sceneAnimations', [
      transition('* => *', [
        query('.nube', [
          style({
            transform: 'translate3d(0,0,0)'
          }),
          animate('300ms', style({
            transform: 'translate3d(300%, 0, 0)'
          })),
          animate('300ms', style({
            transform: 'translate3d(300%, 100%, 0)'
          })),
          animate('300ms', style({
            transform: 'translate3d(0, 100%, 0)'
          })),
          animate('300ms', style({
            transform: 'translate3d(0, 0, 0)'
          }))
        ])
      ])
    ]),
    trigger('pageAnimations', [
      transition('* => *', [
        query('.nube, .nube2, .nube3', [
          // style({opacity: 0, transform: 'translateX(-100%)'}),
          // stagger(300, [
          animate('5s cubic-bezier(0.35, 0, 0.25, 1)',
            style({ opacity: 1, transform: 'translateX(100%)' }))
          // ])
        ])
      ])
    ]),
  ]
})
export class MainComponent implements OnInit {
  @HostBinding('@pageAnimations')
  @HostBinding('@sceneAnimations')
  @Input() page: any;
  @Input() blocks: any;
  @Input() listItems: any;
  @ViewChild('siteHead', { static: true }) header: ElementRef;
  @ViewChild('ajuste', { static: true }) ajuste: ElementRef;
  
  sticky: number;
  state: string;
  isOpen = true;
  times = Infinity;
  counter = 0;

  constructor(private http: HttpClient) { }


  ngOnInit() {
    // const $win = $(window);
    let windowHeight, windowWidth;
    let fullHeight, scrollHeight;
    let fondoImgWidth = 1920, fondoImgHeight = 1080;

    calculateDimensions();
    let currentPosition = -1, targetPosition = 0;
    // const videoContainer = <HTMLElement>document.getElementById('conte-fondo');
    // const video: HTMLDivElement = <HTMLDivElement>document.getElementById('conte-fondo');

    // const videoContainer = $('#video-container');
    // const video = $('.video-container > img')[0];
     const videoContainer = <HTMLCanvasElement>document.getElementById('video-container');
    const video: HTMLImageElement = <HTMLImageElement>document.getElementById('video-image');
    const loadingBar = <HTMLElement>document.getElementById('loading-bar');

    // handling resize and scroll events

    function calculateDimensions() {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
      fullHeight = document.getElementById('main').offsetHeight;
      // fullHeight = $('#main').height();
      scrollHeight = fullHeight - windowHeight;
      console.log('Dimensiones', 'WindowWidth' + windowWidth + ' - WindowHeight' + windowHeight + ' - FullHeight' + fullHeight + ' - ScrollHeight' + scrollHeight);

    }
    window.setTimeout(calculateDimensions, 2000);
    function handleResize() {
      calculateDimensions();
      resizeBackgroundImage();
      handleScroll();

    }

    function handleScroll() {
      targetPosition = document.documentElement.scrollTop / scrollHeight;
      
    }
    function handleScrollTo(value) {
      targetPosition = value;
    }
    function animloopTo() {
      if (Math.floor(currentPosition * 5000) !== Math.floor(targetPosition * 5000)) {
        currentPosition += (targetPosition - currentPosition) / 5;
        render(currentPosition);
      } else {
      }
      requestAnimationFrame(animloop);
    }
    // window.requestAnimationFrame(animloop);
    // main render loop
    (function () {
      return window.requestAnimationFrame || 
        function (/* function */ callback, /* DOMElement */ element) {
          window.setTimeout(callback, 1000 / 60);
        };
    })();


    function animloop() {
      // console.log('currentPosition', currentPosition);
      // console.log('targetPosition', targetPosition);
      if (Math.floor(currentPosition * 5000) !== Math.floor(targetPosition * 5000)) {
        currentPosition += (targetPosition - currentPosition) / 5;
        render(currentPosition);
      }
      requestAnimationFrame(animloop);
    }


    // rendering
    function render(position) {
      //  console.log('render', position);
      // position the elements
      let minY = -windowHeight, maxY = windowHeight;
      renderVideo(position);
    }



    function resizeBackgroundImage() {
      // get image container size
      const scale = Math.max(windowHeight / fondoImgHeight, windowWidth / fondoImgWidth);
      const width = scale * fondoImgWidth, height = scale * fondoImgHeight;
      const left = (windowWidth - width) / 2, top = (windowHeight - height) / 2;
      // videoContainer
      //   .width(width).height(height)
      //   .css('position', 'fixed')
      //   .css('left', left + 'px')
      //   .css('top', top + 'px');
      videoContainer.style.height = height + 'px';
      videoContainer.style.width = width + 'px';
      videoContainer.style.left = left + 'px';
      videoContainer.style.top = top + 'px';
      videoContainer.style.position = 'fixed';
    }

    // video handling 
    let imageSeqLoader = new progressiveImage("assets/video/video3/video3_{index}.jpg", 249, {
      indexSize: 4,
      initialStep: 16,
      onProgress: handleLoadProgress,
      onComplete: handleLoadComplete,
      stopAt: 1
    });
    let loadCounterForIE = 0; // there seems to be a problem with ie calling the callback several times
    console.log('currentPosition', currentPosition);
    imageSeqLoader.loadPosition(currentPosition, function () {
      loadCounterForIE++;
      if (loadCounterForIE === 1) {
        renderVideo(currentPosition);
        imageSeqLoader.load();

      }
    });


    let currentSrc, currentIndex;
    function renderVideo(position) {
      const index = Math.round(currentPosition * (imageSeqLoader.length - 1));
      // console.log('render', index);

      const img = imageSeqLoader.getNearest(index);
      let nearestIndex = imageSeqLoader.nearestIndex;
      // console.log('nearestIndex',nearestIndex);
      if (nearestIndex < 0) {
        nearestIndex = 0;
      }
      // const $img = $(img);
      let src;
      if (!!img) {
        src = img.src;
        if (src !== currentSrc) {
          video.src = src;
          currentSrc = src;
        }
      }
    }

    
    function handleLoadProgress() {
      let progress = imageSeqLoader.getLoadProgress() * 100;
      loadingBar.style.width = progress + '%';
      loadingBar.style.opacity = '1';

    }

    function handleLoadComplete() {
      loadingBar.style.width = '100%';
      loadingBar.style.opacity = '0';
    }

    window.onresize = (e) => {
      handleResize()
    }
    window.onscroll = (e) => {
      handleScroll()
    }
    // $win.resize(handleResize);
    // $win.scroll(handleScroll);
    handleResize();
    animloop();

    // this.events = this.getEvents();
    // this.posts = this.getPosts();


    // (window as any).fbAsyncInit = function() {
    //   FB.init({
    //     appId      : '650190605481638',
    //     cookie     : true,
    //     xfbml      : true,
    //     version    : 'v3.3'
    //   });
    //   FB.AppEvents.logPageView();
    // };

    // (function(d, s, id){
    //    var js, fjs = d.getElementsByTagName(s)[0];
    //    if (d.getElementById(id)) {return;}
    //    js = d.createElement(s); js.id = id;
    //    js.src = "https://connect.facebook.net/en_US/sdk.js";
    //    fjs.parentNode.insertBefore(js, fjs);
    //  }(document, 'script', 'facebook-jssdk'));



    /*
        const frameNumber = 0;
        const playbackConst = 128
        const setHeight = document.getElementById('set-height');
        const playbackPhoneConst = 1000;
        const setPhoneHeight = document.getElementById('set-phoneHeight');
        const mainVideo: HTMLVideoElement = <HTMLVideoElement>document.getElementById('v0');
        mainVideo.volume = 0;
        const phoneVideo: HTMLVideoElement = <HTMLVideoElement>document.getElementById('v1');
        phoneVideo.volume = 0;
    
    
    
    
        mainVideo.addEventListener('loadedmetadata', function () {
          setHeight.style.height = Math.floor(mainVideo.duration) * playbackConst - 380 + 'px';
    
        });
    
        phoneVideo.addEventListener('loadedmetadata', function () {
          setPhoneHeight.style.height = Math.floor(phoneVideo.duration) * playbackPhoneConst - 1080 + 'px';
        });
    
        function scrollPlay() {
          // tslint:disable-next-line: no-shadowed-variable
          const frameNumber = window.pageYOffset / playbackConst;
          mainVideo.currentTime = frameNumber;
          const framePhoneNumber = window.pageYOffset / playbackPhoneConst;
          phoneVideo.currentTime = framePhoneNumber;
          window.requestAnimationFrame(scrollPlay);
        }
    */
    // window.requestAnimationFrame(scrollPlay);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  onDone($event) {
    // call this function at the end of the previous animation.
    // run it as many time as defined
    if (this.counter < this.times) {
      this.state = this.state === 'active' ? 'inactive' : 'active';
      this.counter++;
    }
  }
  submitLogin() {
    console.log('submit login to facebook');
    // FB.login();
  }
  openDialog(link) {
    // alert('velez');
    // alert(link);
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    
  }

  // ===========================================================================
  // TRY THIS
  // ===========================================================================



  /*
    @HostListener('window:scroll', [])
    onWindowScroll() {
  
      var ajuste = document.getElementById('ajuste');
  
      var header = document.getElementById('siteHead');
      var sticky = this.header.nativeElement.offsetHeight;
      //console.log('window.pageYOffset', window.pageYOffset);
      //console.log('sticky', sticky);
      //console.log('scroll diff', window.innerHeight - sticky);
  
      if (window.pageYOffset < 500) {
        header.classList.add('sticky');
  
        ajuste.classList.remove('top-padding');
      } else {
        header.classList.remove('sticky');
        //this.ajuste.nativeElement.css("marginTop", window.pageYOffset + sticky);
  
        ajuste.classList.add('top-padding');
  
  
  
      }
    }
    */


  /*
      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number < 50) {
        console.log("scroll position", number);
        console.log("DES", document.getElementById('section1').offsetTop);
        //document.getElementById('section1').getElementsByTagName('img')[0].style.transform = "translate3d(0px, 0px, 0px)";
        //    console.log('You are 300px from the top to bottom');
      } else if (number > 1900) {
        console.log("scroll position", number);
        console.log("DES", document.getElementById('section1').offsetTop);
        
        //document.getElementById('section1').getElementsByTagName('img')[0].style.transform = 'translate3d(0%, -' + ((number - document.getElementById('section1').offsetTop) / 100) + '%, 0) scale(' + (100 + (number - document.getElementById('section1').offsetTop) / 5) / 100 + ')';
        //Blur suggestion from @janwagner: https://codepen.io/janwagner/ in comments
        //"-webkit-filter": "blur(" + (scroll/200) + "px)",
        //filter: "blur(" + (scroll/200) + "px)"
  
        console.log('You are 400px from the top to bottom');
      }*/



  /*
  var imageSrc = "";
  var currentImage = "";
  var imageCaption = "";
  var interfaceLock = false;
  
  var largerImage = "";
  var speechBubble = "";
  
  
  
  
  
  window.resize(function () {
    showGaleria();
  });
  
  */





}
