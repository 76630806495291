// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.preload {
  margin: 0px auto;
  position: absolute;
  height: 50px;
  width: 50px;
  margin: 50%;
}

#trama {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(/assets/img/trama.png);
  z-index: 0;
  top: 0px;
  opacity: 0.3;
  background-size: 40%;
}

app-background {
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhY2tncm91bmQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGtCQUFBO0VBQ0EsUUFBQTtBQUNGOztBQUNBO0VBQ0UsZ0JBQUE7RUFDQSxrQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0VBQ0EsV0FBQTtBQUVGOztBQUFBO0VBQ0UsZUFBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0VBQ0EsNENBQUE7RUFDQSxVQUFBO0VBQ0EsUUFBQTtFQUNBLFlBQUE7RUFDQSxvQkFBQTtBQUdGOztBQUFBO0VBQ0UsY0FBQTtBQUdGIiwiZmlsZSI6ImJhY2tncm91bmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudmlkZW8ge1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRvcDogMHB4O1xufVxuLnByZWxvYWQge1xuICBtYXJnaW46IDBweCBhdXRvO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIGhlaWdodDogNTBweDtcbiAgd2lkdGg6IDUwcHg7XG4gIG1hcmdpbjogNTAlO1xufVxuI3RyYW1hIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBiYWNrZ3JvdW5kLWltYWdlOiB1cmwoL2Fzc2V0cy9pbWcvdHJhbWEucG5nKTtcbiAgei1pbmRleDogMDtcbiAgdG9wOiAwcHg7XG4gIG9wYWNpdHk6IDAuMztcbiAgYmFja2dyb3VuZC1zaXplOiA0MCU7XG59XG5cbmFwcC1iYWNrZ3JvdW5kIHtcbiAgZGlzcGxheTogYmxvY2s7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/background/background.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;AACF;;AACA;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AAEF;;AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,4CAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,oBAAA;AAGF;;AAAA;EACE,cAAA;AAGF;AACA,gkCAAgkC","sourcesContent":[".video {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0px;\n}\n.preload {\n  margin: 0px auto;\n  position: absolute;\n  height: 50px;\n  width: 50px;\n  margin: 50%;\n}\n#trama {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  background-image: url(/assets/img/trama.png);\n  z-index: 0;\n  top: 0px;\n  opacity: 0.3;\n  background-size: 40%;\n}\n\napp-background {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
