import { Component, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { MediasService } from '../medias.service';
import { Media } from '../media';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent {
  
  mediaForm: UntypedFormGroup;
  public field: string;
  public selectedChildMedia: EventEmitter<Media> = new EventEmitter()
  public confirmMessage: string;
  
  
  //Upload Form
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  uploadState: Observable<string>;
  downloadURL: Observable<string>;
  image: string = null;

  constructor(
    public dialogRef: MatDialogRef<MediaDialogComponent>,
    private firestorage: AngularFireStorage,
    private afs: AngularFirestore,
    private mediaService: MediasService,
  ) {
    this.mediaForm = new UntypedFormGroup({
      image: new UntypedFormControl(),
      field: new UntypedFormControl()
    });

  }

  openInputFile() {
    // your can use ElementRef for this later
    document.getElementById("fileInput").click();
    return false;
  }
  upload(event) {
    const randomId = Math.random().toString(36).substring(2);
    console.log(event.target.files);
    const file = event.target.files[0];
    const filenameFull =  file.name;
    const filename =  file.name.split('.').slice(0, -1).join('.');
    const filePath = ('images/media/' + randomId + '-' + filenameFull).toString();
    this.ref = this.firestorage.ref(filePath);
    this.task = this.firestorage.upload(filePath, file);

    //this.uploadProgress = this.task.percentageChanges();
    this.uploadProgress = this.task.snapshotChanges()
      .pipe(map(s => (s.bytesTransferred / s.totalBytes) * 100));
    this.uploadState = this.task.snapshotChanges().pipe(map(s => s.state));
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.ref.getDownloadURL().subscribe(url => {
          console.log(url); // <-- do what ever you want with the url..
          this.image = url;
          this.downloadURL = this.ref.getDownloadURL(); // <-- Here the downloadURL is available.
          const data: Media = {
            //field: this.field,

            title: null,
            url: this.image,
            value: null,
            type: 'image',
            createdOn: new Date(),
            updatedOn: new Date()

          }
          this.mediaService.createMedia(data);
        });


      })


    ).subscribe();



  }
  selectedChangedHandler(media:Media) {
    //this.image = media.id;
    console.log("Selected Dialog Media",media);
    this.selectedChildMedia.emit(media);
  }

  
}





