// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  white-space: pre-wrap;
}

.item {
  position: relative;
  z-index: 100;
}

.item-data .mat-h2 {
  font-size: 36px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIml0ZW0tdmlldy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUFTLHFCQUFBO0FBRVQ7O0FBQ0E7RUFDSSxrQkFBQTtFQUNBLFlBQUE7QUFFSjs7QUFBQTtFQUNJLGVBQUE7QUFHSiIsImZpbGUiOiJpdGVtLXZpZXcuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGVudHt3aGl0ZS1zcGFjZTogcHJlLXdyYXB9XG5cblxuLml0ZW0ge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICB6LWluZGV4OiAxMDA7XG59XG4uaXRlbS1kYXRhIC5tYXQtaDJ7XG4gICAgZm9udC1zaXplOjM2cHg7XG59XG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/item-view/item-view.component.scss"],"names":[],"mappings":"AAAA;EAAS,qBAAA;AAET;;AACA;EACI,kBAAA;EACA,YAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;AACA,ogBAAogB","sourcesContent":[".content{white-space: pre-wrap}\n\n\n.item {\n    position: relative;\n    z-index: 100;\n}\n.item-data .mat-h2{\n    font-size:36px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
