import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/compat/database";


//import * as moment from "moment";
import { User, UserDetails } from "../core/user";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";


@Injectable()
export class UserService {
  selectedUser: User = new User();
  user: AngularFirestoreCollection<User>;
  userdetail: AngularFirestoreCollection<UserDetails>;

  location = {
    lat: null,
    lon: null
  };

  constructor(private firestore: AngularFirestore) {
    this.getUsers();
  }

  getUsers() {
    this.user = this.firestore.collection("users");
    return this.user;
  }

  createUser(data: User) {
    data.location = this.location;
    data.createdOn =new Date();
    this.user.add(data);
  }
/*
  updateUser(data: User) {
    this.users.update(user.$key, data);
  }*/

  setLocation(lat, lon) {
    this.location.lat = lat;
    this.location.lon = lon;
  }


  public getUserDetail(userId:string){
    console.log({userId});
    // tslint:disable-next-line:max-line-length
    // this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    return this.firestore.collection('users').doc(userId).collection('details').doc(userId).snapshotChanges();
    // console.log(userParticipationRef);
  }
  public createUserDetail(userId: string, UserDetailForm) {
    // tslint:disable-next-line:max-line-length
    // this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    const responsePath = `${userId}`;
    return this.firestore.collection('users').doc(userId).collection('details').doc(responsePath).set(UserDetailForm);
  };

  public updateUserDetail(userId:string,UserDetailForm){
    // this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    const responsePath = `${userId}`;
    return this.firestore.collection('users').doc(userId).collection('details').doc(responsePath).set(UserDetailForm);
  };
    
  public getUserAwards(userId:string){
    // this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    return this.firestore.collection('users').doc(userId).collection('awards').valueChanges();
    //console.log(userParticipationRef);    
  }
  public getUserParticipations(userId:string){
    //this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    return this.firestore.collection('users').doc(userId).collection('participations').valueChanges();
    //console.log(userParticipationRef);    
  }

  /*
  public DeleteUserScore(userId:string){
    //this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    return this.firestore.collection('users').doc(userId).collection('awards').snapshotChanges();
    //console.log(userParticipationRef);    
  }

  public DeleteUserAwards(userId:string){
    //this.firestore.collection('participations', ref => ref.where('userId', '==', userId).where('triviaId', '==', triviaId)).snapshotChanges();
    return this.firestore.collection('users').doc(userId).collection('awards').snapshotChanges();
    //console.log(userParticipationRef);    
  }
*/


    //const response: TriviaParticipation = { userId, triviaId,date, attempt,score };
    //const responsePath = `${userId}_${response.triviaId}`;
    //this.firestore.collection('points').doc(responsePath).set(response);
    }



  export class UserDetail {
    displayName    = 'Nombre'
    lastName  = 'Apellido'
    sex    = 'M'
    birthday    = '12/12/2012'
    state = ''
  }