import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Page } from '../page';
import { fadeAnimation } from 'src/app/shared/animations/fadeIntRoute';
import { trigger, state, style, transition, animate, query } from '@angular/animations';
class Item {
  title: string;
  subtitle: string;
  excerpt: string;
  content: string;
  media: string;
  slug: string;
  link: string;
  target = false;
  showbutton = false;
  order: number;
  status: boolean;
  private: boolean;
  items: any;
  points: any;
  type: string;
  config: any;
}
@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      // Cuando el componente entra
      transition(':enter', [
        style({ opacity: 0 }),  // Estado inicial (invisible)
        animate('500ms ease-out', style({ opacity: 1 }))  // Estado final (visible)
      ]),
      // Cuando el componente sale
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0 }))  // Desvanecerse al salir
      ])
    ])
  ]
  /*animations: [
    trigger('fadeAnimation', [
      //transition(':enter, :leave', [
      //  query('@*', animateChild(), { optional: true })
      //]),
      state('in', style({ 'opacity': '1' })),
      state('out',style({ 'opacity': '1' })),
      transition('* => in', animate('3000ms ease-in')),
      transition('* => out', [
        query('.itemCard', [
          style({ transform: 'scale(1)', opacity: 1, height: '*' }),
          animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({
              transform: 'scale(0.5)', opacity: 0,
              height: '0px', margin: '0px'
            })
          )
        ], {optional: true}),
        query('.background', [
         // style({  opacity: 1}),
          animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
           // style({ opacity: 1})
          )
        ], {optional: true}),
      
       // query('@*', animateChild(), { optional: true }),
        animate('1000ms ease-out')
      ])
    ]),
  ]*/
})


export class ItemViewComponent implements OnInit {
  pageId: string;
  id: string;
  itemModel: {};
  item: Item;
  page:Page;
  state = 'in';
  enableAnimation: any;
  counter: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    //this.setForm();
    let sub = this.activatedRoute.params.subscribe(params => {
      const pageId = params["pageId"];
      const id = params["id"];
      this.pageId = pageId;
      this.id = id;
      //console.log("Page ID:", pageId);
      //console.log("ID:", id);


      if (pageId && id) {
        const a = this.afs.collection('pages').doc(pageId).snapshotChanges();
        a.subscribe(
          item => {
            const pageData = item.payload.data() as Page;
            item["id"] = id;
            this.page = pageData;
          });
        


        const x = this.afs.collection('pages').doc(pageId).collection('items').doc(id).snapshotChanges();
        x.subscribe(item => {
          const itemdata = item.payload.data() as Item;
          item["id"] = id;
          this.item = itemdata;
          //this.getItemByID(pageId, id);
          console.log("Item", this.item)
        });
      }
    });
  }
  togglePage() {
    if (this.counter < 2) {
      this.state = this.state === 'in' ? 'out' : 'in';
     
      console.log("ANIMATION PAGE STATE", this.state);
      setTimeout('1000');
      this.counter++;
    }



  }
  onDone($event) {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        //this.page = null;
        //this.item = null;
       console.log("Cargando la pagina nueva");
       // this.getPageBySlug(this.slug);


      }
    }
    this.togglePage();
  }

}


