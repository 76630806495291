// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs .mat-h2, .tabs .mat-h3, .tabs .mat-h4 {
  margin: 0px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhYnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxXQUFBO0FBQ0oiLCJmaWxlIjoidGFicy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50YWJzIC5tYXQtaDIsLnRhYnMgLm1hdC1oMywudGFicyAubWF0LWg0e1xuICAgIG1hcmdpbjowcHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/tabs/tabs.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACA,oUAAoU","sourcesContent":[".tabs .mat-h2,.tabs .mat-h3,.tabs .mat-h4{\n    margin:0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
