import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { User } from './user'; // Assuming User model is defined separately
import firebase from 'firebase/compat/app';
@Injectable({ providedIn: 'root' })
export class AuthService {
  redirectUrl: string = '';
  user$: Observable<User | null>;
  userData: firebase.User = null; // If needed for specific Firebase Auth features
  location = {
    lat: null,
    lon: null
  };
  postRef: any;
 
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }


  isLoggedIn() {
    return this.user$.pipe(map(user => !!user)); // Use Observable for consistency
  }


  createUserAndRetrieveDataWithEmailAndPassword(emailID: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(
      emailID,
      password
    ).then((credential) => {
      this.updateUserData(credential.user)
    })

  }


  ///// Login/Signup //////
  signInRegular(email, password) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    return this.afAuth.signInAndRetrieveDataWithCredential(credential);
  }
  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }
  signInWithFacebook() {
    //this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }
  signInAnonymously() {
    return this.afAuth.signInAnonymously()
    .then((user) => {
       console.log(user);
      this.updateUserData(user.user)
      
    })
    .catch(error => console.log(error));
  }

  // googleLogin() {
  //   const provider = new firebase.auth.GoogleAuthProvider()
  //   return this.oAuthLogin(provider);
  // }
 
  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      })
  }

  signOut() {
    this.afAuth.signOut().then(res => this.router.navigate(["/"]));
  }
  logout() {
    this.afAuth.signOut().then(res => this.router.navigate(["/"]));
  }
  private updateUserData(user) { // Use firebase.User directly here
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      //password: user.password,
      createdOn: new Date(),
      location: this.location,
      avatar: user.photoURL,
      phoneNumber: user.phoneNumber,
      roles: {
        subscriber: true
      }
    }
    return userRef.set(data, { merge: true })
  }


  resetPassword(email: string) {
    var auth = firebase.auth();
    firebase.auth().languageCode = 'es';
    return auth.sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error))
  }




  ///// Role-based Authorization //////
  // determines if user has field the basic data
  hasData() {
    return this.afAuth.currentUser.then(user => {
      if (user) {
        const documentReference = this.afs.collection('users').doc(user.uid);
        return documentReference.ref.get().then(documentSnapshot => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data() as User; // Cast to User type
            return data.birthday ? "hasData" : "no"; // Concise ternary operator
          } else {
            return "no";
          }
        });
      } else {
        return "no"; // No user, return 'no'
      }
    });
  }
  user: User;

  ngOnInit() {
    this.user$.subscribe(user => this.user = user)
  }
  
  editPost() {
    if(this.canEdit(this.user)) {
      this.postRef.update({ title: 'Edited Title!'})
    } 
    else {
      console.error('you are not allowed to do that!')
    }
  
  }

  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'subscriber']
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(user, allowed)
  }

  canDelete(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }
  isEditor(user: User): boolean {
    const allowed = ['editor']
    return this.checkAuthorization(user, allowed)
  }
  isAdmin(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }
  



  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true
      }
    }
    return false
  }




}
