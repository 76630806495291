import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MediasService } from 'src/app/medias/medias.service';
import { GlobalVariable } from 'src/app/shared/globals';
import { Media } from 'src/app/medias/media';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})

export class MediaComponent implements OnInit, OnChanges {
  @Input() id: any;
  @Input() mediaClass: string;
  @Input() selectedMedia: string;
  @Input() type = 'image';
  preload: boolean;
  tmpImg: HTMLImageElement; // will be used to load the actual image before showing it
  media = 'assets/img/transparentImg.png'; // Just a random loading gif found on google.
  class = 'img-responsive';

  constructor(
    private mediasService: MediasService,

    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    // console.log("IS ID VALUE", this.id)
    if (this.mediaClass) {
      this.class = this.mediaClass;
    }
  }
  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    // console.log("Media ID",this.id);
    // console.log("Media Class", this.mediaClass);
    if (this.type === 'image') {
      if (this.mediaClass) {
        this.class = 'imagen' + this.mediaClass;
      }

      if (this.id !== '') {
        this.mediasService.getMedia(this.id).subscribe(data => {
          const mediaData = data.payload.data() as Media;
          // console.log(mediaData)
          if (mediaData) {
            if (mediaData.url == null || mediaData.url === '') {
              this.generateImage(GlobalVariable.defaultMediaUrl);
            } else {
              this.generateImage(mediaData.url);
              //  this.media = mediaData.url;
            }
          }
        });
      } else {
        this.media = GlobalVariable.defaultMediaUrl;
      }
    } else {
      if (this.type === 'video') {
        this.media = this.id;
      }
      if (this.type === 'audio') {
        this.media = 'https://open.spotify.com/embed/album/' + `${this.id}`;
      }
    }
  }
  generateImage(data: any) {
    this.preload = true;
    const loaded = () => { // wait for image to load then replace it with loadingGIF
      this.media = data;
      this.preload = false;

    };

    // background loading logic
    if (this.tmpImg) {
      this.tmpImg.onload = null; // remove the previous onload event, if registered
    }
    this.tmpImg = new Image();
    this.tmpImg.onload = loaded;  // register the onload event
    this.tmpImg.src = data;
  }
}
