import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterByHighlight'
})
export class FilterByHighlightPipe implements PipeTransform {
  transform(items: any): any {
      return items.filter(item => item["highlight"]==true);
      

    
  }

}
