import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-dialog',
  templateUrl: './program-dialog.component.html',
  styleUrls: ['./program-dialog.component.scss']
})
export class ProgramDialogComponent implements OnInit {
  public id: string;
  public programId: string;

  constructor() { }

  ngOnInit() {
  }

}
