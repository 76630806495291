import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  contactForm: UntypedFormGroup;
  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
    public contactDialogRef: MatDialogRef<ContactDialogComponent>
  ) { }

  ngOnInit() {
    this.setForm();
  }
  setForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.email]],
      phone: ['', []],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }


  onSubmit() {
    //console.log(`Image: ${this.media}`);
    //console.log(`Image: ${ JSON.stringify(f.value)}`);
    console.log('Form:', this.contactForm);
    if (this.contactForm.valid) {
        console.log('Creando un nuevo documento!');
        var idBefore = this.afs.createId();
        const data = {
          id: idBefore,
          // config: this.config,
          name: this.contactForm.get('name').value,
          email: this.contactForm.get('email').value,
          phone: this.contactForm.get('phone').value,
          subject: this.contactForm.get('subject').value,
          message: this.contactForm.get('message').value,
          createdOn: new Date(),
          updatedOn: new Date(),
          // level: this.pollForm.get('level').value,
        }
        this.afs.collection('messages').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.snackBar.open("Gracias por escribirme", "El formulario ha sido enviado", {
            duration: 2000,
          });
          this.contactDialogRef.close();
        }, (error) => {
          console.log(error);
        });
    } else {
      this.snackBar.open("Error al enviar el formulario", "Por favor completa todos los campos", {
        duration: 2500,
      });
      console.log("El Formulario no es valido");
    }
  }

}
