import { NgForm, EmailValidator, UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Component, OnInit, Inject } from "@angular/core";
//import { ToastyService, ToastOptions, ToastyConfig } from "ng2-toasty";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../core/auth.service";
import { User } from "../../core/user";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AngularFireAuth } from '@angular/fire/compat/auth';
//import { User } from 'firebase/compat/auth'; // If needed for type safety
import { AngularFirestoreDocument, AngularFirestore } from "@angular/fire/compat/firestore";
import { GlobalVariable } from "src/app/shared/globals";

export interface DialogData {
  animal: string;
  name: string;
}

declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [EmailValidator]
})
export class LoginComponent implements OnInit {
  user: User;
  userLoginData = {
    emailId: "",
    loginPassword: ""
  };
  returnUrl: string;
  errorInUserLogin = false;
  errorInUserCreate = false;
  errorMessage: any;
  createUser;

  title: string;
  states = GlobalVariable.states;
  sexs = GlobalVariable.sexs;

  userForm: UntypedFormGroup;
  //@ViewChild('f') form;


   emailIdFormControl = new UntypedFormControl('', [Validators.required]);
   loginPasswordFormControl = new UntypedFormControl('', [Validators.required]); 
  // uid = new FormControl('', [Validators.required]);
  // firstName = new FormControl('', []);
  // lastName = new FormControl('', []);
  // birthday = new FormControl('', [Validators.required]);
  // sex = new FormControl('', [Validators.required]);
  // state = new FormControl('', [Validators.required]);


  constructor(
    public authService: AuthService,
    //private toastyService: ToastyService,
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,

  ) {

    //private toastyConfig: ToastyConfig  
    //this.toastyConfig.position = "top-right";
    //this.toastyConfig.theme = "material";
    this.createUser = new User();
  }

  ngOnInit() {
    this.setForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
  }

  setForm() {
    this.userForm = this.formBuilder.group({
      firstName: ['', []],
      lastName: ['', []],
      sex: ["F", [Validators.required]],
      birthday: ['', [Validators.required]],
      state: ['', [Validators.required]],
      terms: [, [Validators.requiredTrue]]
    });

  }

  async signInWithEmail(userForm: NgForm) {
    try {
      const res = await this.authService.signInRegular(
        userForm.value["emailId"],
        userForm.value["loginPassword"]
      );
  
      if (res.user) {
        const user = res.user;
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
        const docSnapshot = await userRef.get().toPromise(); // Use toPromise()
  
        if (docSnapshot.exists) {
          // Usuario con datos:
          console.log("Usuario con datos:", docSnapshot.data());
          this.afterSignIn(this.returnUrl);
        } else {
          // Usuario sin datos, redirigir a perfil:
          console.log("Usuario sin datos:", user);
          this.router.navigate(["/profile-setup"], { relativeTo: this.route });
        }
      }
    } catch (err) {
      console.log("Error al iniciar sesión:", err);
      this.errorInUserLogin = true;
      this.errorMessage = err;
    }
  }

  signInWithGoogle() {
    this.authService
      .signInWithGoogle()
      .then(res => {
        //const returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
        console.log("On Google Sign In");
        console.log(this.authService.user$);

        this.afterSignIn(this.returnUrl)
        //  this.router.navigate(["/"]);
      })
      .catch(err => console.log(err));
  }


  signInWithFacebook() {
    this.authService
      .signInWithFacebook()
      .then(res => {
        //const returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
        console.log("On Facebook Sign In");
        console.log(this.authService.user$);
        this.afterSignIn(this.returnUrl);
        //  this.router.navigate(["/"]);
      })
      .catch(err => console.log(err));
  }

  signInAnonymously() {
    this.authService.signInAnonymously()
      .then(() => {
        // const returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
        console.log("On Anonymously Sign In");
        // console.log(returnUrl);
        this.afterSignIn(this.returnUrl)
      });
  }

  private afterSignIn(returnUrl: string): void {
    console.log("After Sign In");
    console.log(this.returnUrl);
    this.authService.hasData().then(resultado => {
      console.log("RESULTADO");
      console.log(resultado);
      if (resultado == "hasData") {
        console.log("HAS DATA");
        this.router.navigate([this.returnUrl], {relativeTo: this.route, skipLocationChange: true});
        return true;
      } else {
        console.log("NO HAS DATA")
        this.authService.user$.subscribe(user => {
          if (user) {
            this.user = user;
            console.log("USER",user)
            this.userForm = this.formBuilder.group({
              "firstName": user.firstName,
              "lastName": user.lastName,
              "sex": user.sex,
              "birthday": user.birthday,
              "state": user.state,
              "terms": user.terms
            });
            // console.log(user.birthday);
            // console.log(this.userForm);

          }
        });
      }
    });
    //const promise = new promise(this.authService.hasDataFill()



    // Do after login stuff here, such router redirects, toast messages, etc.
    // this.router.navigate([returnUrl]);
  }
  //EN USO (NO BORRAR)
  onSubmitUserData() {
    console.log('Editando el documento Login');
    // Sets user data to firestore on login
    console.log("User Form",this.userForm)
    if (this.userForm.valid) {
      console.log("El Form es valido")
    
     // const user = this.afAuth.currentUser;
      console.log(this.userForm);
      this.afAuth.currentUser.then(user => {
     
        console.log(user);
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

      const data: any = {
        //displayName: user.displayName,
        //password: user.password,
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        sex: this.userForm.value.sex,
        birthday: this.userForm.value.birthday,
        state: this.userForm.value.state,
        terms: this.userForm.value.terms
      }
      console.log('Documento guardado exitósamente');
      userRef.set(data, { merge: true })
      this.router.navigate([this.returnUrl || "/"], {relativeTo: this.route, skipLocationChange: true});
    });
    }
  }

  openRegisterDialog(): void {
    const dialogRef = this.dialog.open(RegisterDialog, {
      width: 'auto',
      minWidth: '400px',
      minHeight: '400px',
      panelClass: 'custom-dialog-box',
      data: { title: this.title },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.title = result;
    });
  }

  openResetDialog(): void {
    const dialogRef = this.dialog.open(ResetDialog, {
      width: 'auto',
      minWidth: '400px',
      minHeight: '400px',
      panelClass: 'custom-dialog-box',
      data: { title: this.title },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.title = result;
    });

  }


}

@Component({
  selector: './register-dialog',
  templateUrl: './register-dialog.html',
})


export class RegisterDialog {
  createUser = {
    emailId: "",
    loginPassword: ""
  };
  errorInUserLogin = false;
  errorInUserCreate = false;
  errorMessage: any;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RegisterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute, ) { }



  onNoClick(): void {
    this.dialogRef.close();
  }
  //creacion de variable contact
  //ContactModel = new ContactForm();
  private emailResponse;
  private truefalse: boolean = false;



  ngOnInit() {
  }

  addUser(userForm: NgForm) {

    //userForm.value["isAdmin"] = false;
    this.authService
      .createUserAndRetrieveDataWithEmailAndPassword(
        userForm.value["emailId"],
        userForm.value["loginPassword"]
      )
      .then(res => {
        console.log("created User", res);
        this.dialogRef.close();
        this.snackBar.open("Gracias por registrarte", "Usuario Registrado", {
          duration: 2000,
        });
        setTimeout((router: Router) => {
          //$("#createUserForm").modal("hide");
          this.router.navigate(["/"], {relativeTo: this.route, skipLocationChange: true});
        }, 3000);
      })
      .catch(err => {
        this.snackBar.open("Lo sentimos, algo salio mal", "Usuario no registrado", {
          duration: 2000,
        });
        this.errorInUserCreate = true;
        this.errorMessage = err;
      });

  }


  onSubmit(f: NgForm) {
    //this.getSentServices(this.ContactModel, f);
  }
  //metodo de services
  /*getSentServices(body:ContactForm, f: NgForm){
      this.sendServices.getResponseEmail(body).subscribe(
          data => {
              if(data){
                  this.snackBar.open("Gracias por el mensaje", "Correcto", {
                      duration: 2000,
                    });
                    f.reset();
              }
              else{
                  this.snackBar.open(":(", "Error", {
                      duration: 2000,
                  });
              }
          },
          err => { this.snackBar.open("Algo fallo :/, correo: biframes@gmail.com", "ups", {
              duration: 5000,
            }); }
        );
  }*/
}


@Component({
  selector: './resetpass-dialog',
  templateUrl: './resetpass-dialog.html',
})

export class ResetDialog {
  createUser = {
    email: ""
  };
  errorInUserLogin = false;
  errorInUserCreate = false;
  errorMessage: any;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ResetDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute, ) { }



  onNoClick(): void {
    this.dialogRef.close();
  }
  //creacion de variable contact
  //ContactModel = new ContactForm();
  private emailResponse;
  private truefalse: boolean = false;



  ngOnInit() {
  }
  resetPassword(f: NgForm) {
    this.authService.resetPassword(f.value["email"]).then(res => {
      console.log("Recuperar contraseña", res);
      this.dialogRef.close();
      this.snackBar.open("Su solicitud ha sido enviada", "Recuperando contraseña", {
        duration: 2000,
      });
    })
      .catch(err => {
        this.snackBar.open("Lo sentimos, algo salio mal", "La contraseña no ha podido ser recuperada. Intentalo nuevamente mas tarde", {
          duration: 2000,
        });
        this.errorInUserCreate = true;
        this.errorMessage = err;
      });

  }




  onSubmit(f: NgForm) {
    //this.getSentServices(this.ContactModel, f);
  }
  //metodo de services
  /*getSentServices(body:ContactForm, f: NgForm){
      this.sendServices.getResponseEmail(body).subscribe(
          data => {
              if(data){
                  this.snackBar.open("Gracias por el mensaje", "Correcto", {
                      duration: 2000,
                    });
                    f.reset();
              }
              else{
                  this.snackBar.open(":(", "Error", {
                      duration: 2000,
                  });    
              }         
          },
          err => { this.snackBar.open("Algo fallo :/, correo: biframes@gmail.com", "ups", {
              duration: 5000,
            }); }
        ); 
  }*/
}