
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { GlobalVariable, Globals } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, debounceTime, take } from 'rxjs/operators';
import { MediaDialogComponent } from 'src/app/medias/media-dialog/media-dialog.component';
import { SlugPipe } from 'src/app/shared/pipes/slugPipe';

class Config {
  container: boolean;
  showdata: boolean;
  type: string;
  cols: string;
  backgroung: any;
  seo: any;
}


class Item {
  title: string;
  subtitle: string;
  excerpt: string;
  content: string;
  media: any;
  slug: string;
  link: string;
  target = false;
  showbutton = false;
  order: number;
  status: boolean;
  private: boolean;
  items: any;
  points: any;
  type: string;
  config: any;
}
@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.scss']
})
export class ItemDialogComponent implements OnInit {
  itemForm: UntypedFormGroup;
  itemModel: Item = new Item();

  pageId: string;
  itemId: string;
  items: any;
  item: any;

  points: any;
  point: any;

  types = GlobalVariable.components;
  itemTypes = GlobalVariable.itemTemplates;
  panelOpenState: boolean;
  //nextSelectedTemplate: string;
  //selectedTemplate: string;
  //activeTemplate: string;
  public config: any = {
    'showdata': true,
    'container': true,
    'background': {
      type: 'image',
      media: null,
      position: 'fixed',
      color: 'transparent',

    },
    'type': 'card',
    'cols': '3',
    'size': 'medium',
    'width': '100',
    'align': 'left',
    'swipe': false,
    'dark': false
    /*'seo':{
      title:"",
      content:"",
      noindex:false,
      media:""
    },*/
    //    'pageData': true
  };


  public templates = GlobalVariable.templates;
  public pageModel;
  defaultMediaUrl = GlobalVariable.defaultMediaUrl;
  currentStatus = 1;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogMediaRef: MatDialogRef<MediaDialogComponent>;
  selectedType: any;
  previousSelectedType: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRefItem: MatDialogRef<ItemDialogComponent>
  ) { }


  ngOnInit() {
    this.setForm();
    console.log('pageId', this.pageId);
    console.log('ItemID', this.itemId);
    if (this.itemId !== '') {
      this.afs.collection('pages').doc(this.pageId).valueChanges().subscribe((x) => {
        this.pageModel = x;
      });
      if (this.pageId && this.itemId) {
        this.getItemByID(this.pageId, this.itemId);
        this.currentStatus = 2;
    
      } else {
        this.currentStatus = 1;
        this.setItems();
      }
    } else {
      this.setForm();
      this.currentStatus = 1;
    }

  }

  get formDataItems() { return <UntypedFormArray>this.itemForm.get('items'); }
  get formDataPoints() { return <UntypedFormArray>this.itemForm.get('points'); }

  setForm() {
    this.itemForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      subtitle: ['', []],

      excerpt: ['', [Validators.required]],
      content: ['', []],
      media: this.formBuilder.group({
        type: [null, []],
        value: [null, []],
        style: [null, []],
      }),
      type: ['list', [Validators.required]],
      slug: ['', []],
      link: [null, []],
      target: ['false', []],
      showbutton: [true, []],
      order: ['', []],

      showdata: [true, []],
      showcontainer: [true, []],

      status: [true, []],
      private: [false, []],

      items: this.formBuilder.array([]),
      points: this.formBuilder.array([]),

      config: this.formBuilder.group({
        background: this.formBuilder.group({
          type: [null, []],
          media: [null, []],
          style: ['responsive', []],
          color: [null, []],
        }),
        showdata: [false, []],
        container: [true, []],
        type: ['list', []],
        cols: ['3', []],
        size: ['small', []],
        width: ['100', []],
        align: ['left', []],
        swipe: [false, []],
        dark: [false, []]
      }),
      options: this.formBuilder.array([]) // create empty form array   
    });
  }

  getItemByID(pageId: string, itemId: string) {
    const x = this.afs.collection('pages').doc(pageId).collection('items').doc(itemId).snapshotChanges();
    x.subscribe(item => {
      const data = item.payload.data() as Item;
      this.itemModel = data as Item;
      console.log('ITEMS', this.itemModel.items);
      this.items = this.itemModel.items;
      this.points = this.itemModel.points;
      this.config = this.itemModel.config;
      this.itemForm = this.formBuilder.group({
        title: [this.itemModel.title, [Validators.required]],
        subtitle: [this.itemModel.subtitle, []],
        excerpt: [this.itemModel.excerpt, [Validators.required]],
        content: [this.itemModel.content, []],
        media: this.formBuilder.group({
          type: [this.itemModel.media.type, []],
          value: [this.itemModel.media.value, []],
          style: [this.itemModel.media.style, []],
        }),
        type: [this.itemModel.type, [Validators.required]],
        slug: [this.itemModel.slug, []],
        link: [this.itemModel.link, []],
        target: [this.itemModel.target, []],
        showbutton: [this.itemModel.showbutton, []],
        order: [this.itemModel.order, []],
        status: [this.itemModel.status, []],
        private: [this.itemModel.private, [Validators.required]],
        items: this.formBuilder.array([]),
        points: this.formBuilder.array([]),
        config: this.formBuilder.group({
          background: this.formBuilder.group({
            type: [this.itemModel.config.background.type, []],
            media: [this.itemModel.config.background.media, []],
            style: [this.itemModel.config.background.media, []],
            color: [this.itemModel.config.background.color, []],
          }),
          showdata: [this.itemModel.config.showdata, []],
          type: [this.itemModel.config.type, []],
          cols: [this.itemModel.config.cols, []],
          size: [this.itemModel.config.size, []],
          width: [this.itemModel.config.width, []],
          align: [this.itemModel.config.align, []],
          swipe: [this.itemModel.config.swipe, []],
          dark: [this.itemModel.config.dark, []],
          container: [this.itemModel.config.container, []],

        })
      });
      this.setItems();
      this.setPoints();
    });
  }


  setItems() {
  const control = <UntypedFormArray>this.itemForm.controls.items;
    console.log('CONTROL', control);
    this.items.forEach(x => {
      control.push(this.formBuilder.group({
        title: x.title,
        subtitle: x.subtitle,
        content: x.content,
        media: this.formBuilder.group({
          type: x.media.type,
          value: x.media.value,
          style: x.media.style,
        }),
        link: x.link,
        target: x.target,
        showbutton: x.showbutton,
        subItems: this.setSubItems(x)
      }));
    });
  }
  setSubItems(x) {
    const arr = new UntypedFormArray([]);
    if (x.subItems) {
      x.subItems.forEach(y => {
        arr.push(this.formBuilder.group({
          subItem: y.subItem
        }));
      });
    }
    return arr;
  }

  setPoints() {
    console.log('POINTS', this.itemModel.points);
    console.log('SETPOINTS', this.itemForm.controls.points);
    let control = <UntypedFormArray>this.itemForm.controls.points;
    console.log('CONTROL', control);
    this.points.forEach(x => {
      control.push(this.formBuilder.group({
        key: x.key,
        value: x.value,
        link: x.link,
        target: x.target,
        showbutton: x.showbutton,
      }));
    });
  }

  setConfig() {
    console.log('ITEMS', this.itemModel.config);
    console.log('SETITEMS', this.itemForm.controls.config);
    const control = <UntypedFormArray>this.itemForm.controls.config;
    control.push(this.formBuilder.group({
      title: this.config.seo.title,
      content: this.config.seo.content,
      media: this.config.seo.media,
      noindex: this.config.seo.noindex
    })
    );
  }
  addNewItem() {
    let control = <UntypedFormArray>this.itemForm.controls.items;
    control.push(
      this.formBuilder.group({
        title: [''],
        subtitle: [''],
        content: [''],
        media: this.formBuilder.group({
          type: null,
          value: null,
          style: 'responsive',
        }),
        link: [''],
        showbutton: [''],
        subItems: this.formBuilder.array([])
      })
    )
  }
  deleteItem(index) {
    const control = <UntypedFormArray>this.itemForm.controls.items;
    control.removeAt(index);
  }

  addNewSubItem(control) {
    control.push(
      this.formBuilder.group({
        subItem: ['']
      }));
  }

  deleteSubItem(control, index) {
    control.removeAt(index);
  }



  addNewPoint() {
    const control = <UntypedFormArray>this.itemForm.controls.points;
    control.push(
      this.formBuilder.group({
        key: [''],
        value: [''],
        link: [''],
        target: [''],
        showbutton: [''],
      })
    );
  }
  deletePoint(index) {
    let control = <UntypedFormArray>this.itemForm.controls.points;
    control.removeAt(index);
  }

  changeSectionType(event: MatRadioChange): void {
    console.log('changeQuestionType');

    if (this.items) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = '¿Estas seguro en cambiar el tipo de seccion?';
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedType = event.value;
          this.previousSelectedType = this.selectedType;
          // do confirmation actions

        } else {
          this.selectedType = this.previousSelectedType;
          console.log(this.selectedType);
          return false;
        }
        this.dialogRef = null;
      });
    }
  }

  onSubmit() {
    // console.log(`Image: ${this.media}`);
    // console.log(`Image: ${ JSON.stringify(f.value)}`);
    console.log('Form:', this.itemForm);
    if (this.itemForm.valid) {
      if (!this.itemId) {
        console.log('Creando un nuevo documento!');
        const idBefore = this.afs.createId();
        const data = {
          pageId: this.pageId,
          id: idBefore,
          // config: this.config,
          title: this.itemForm.get('title').value,
          subtitle: this.itemForm.get('subtitle').value,
          content: this.itemForm.get('content').value,
          excerpt: this.itemForm.get('excerpt').value,
          media: this.itemForm.get('media').value,
          type: this.itemForm.get('type').value,
          // tags: this.pollForm.get('tags').value,
          slug: (this.itemForm.get('slug').value != "") ? this.slug(this.itemForm.get('slug').value) : this.slug(this.itemForm.get('title').value),
          link: `/pages/item/${this.pageId}/${idBefore}`,
          target: false,
          showbutton: true,
          order:this.itemForm.get('order').value,
          status: this.itemForm.get('status').value,
          private: this.itemForm.get('private').value,
          items: this.itemForm.get('items').value,
          points: this.itemForm.get('points').value,
          config: this.itemForm.get('config').value,
          createdOn: new Date(),
          updatedOn: new Date(),
          // level: this.pollForm.get('level').value,
        };
        this.afs.collection('pages').doc(this.pageId).collection('items').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefItem.close();
        }, (error) => {
          console.log(error);
        });

      } else {
        console.log('Editando el documento');
        const data = {
          // config: this.config,
          title: this.itemForm.get('title').value,
          subtitle: this.itemForm.get('subtitle').value,
          excerpt: this.itemForm.get('excerpt').value,
          content: this.itemForm.get('content').value,
          media: this.itemForm.get('media').value,
          type: this.itemForm.get('type').value,
          // tags: this.pollForm.get('tags').value,
          slug: (this.itemForm.get('slug').value !== "") ? this.slug(this.itemForm.get('slug').value) : this.slug(this.itemForm.get('title').value),
          link: `/pages/item/${this.pageId}/${this.itemId}`,
          target: false,
          showbutton: true,
          order: this.itemForm.get('order').value,
          status: this.itemForm.get('status').value,
          private: this.itemForm.get('private').value,
          items: this.itemForm.get('items').value,
          points: this.itemForm.get('points').value,
          config: this.itemForm.get('config').value,

          updatedOn: new Date(),
        };
        this.afs.collection('pages').doc(this.pageId).collection('items').doc(this.itemId).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefItem.close();
        }, (error) => {
          console.log(error);
        });
      }
    } else {
      console.log('El Formulario no es valido');
    }
  }

  openMediaDialog(e, field, group = null, subgroup = null, subsubgroup = null): void {
    var selectedMediaId = null;
    var selectedMediaUrl = null;
    this.dialogMediaRef = this.dialog.open(MediaDialogComponent, {
      disableClose: false
    });
    this.dialogMediaRef.componentInstance.field = field;
    this.dialogMediaRef.componentInstance.confirmMessage = "";
    const sub = this.dialogMediaRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
      selectedMediaId = data.id;
      selectedMediaUrl = data.url;
    });
    this.dialogMediaRef.afterClosed().subscribe(data => {
      if (data) {
        //  debugger
        if (selectedMediaId) {
          if (e.target.parentNode.previousSibling.firstChild.firstChild) {
            var m = e.target.parentNode.previousSibling.firstChild.firstChild;
            var n = e.target.parentNode.nextSibling;
            e.target.nextSibling.disabled = false;
          }
          m.src = selectedMediaUrl;
          n.value = selectedMediaId;

          if (group != null) {
            // if(subgroup != null){
            this.getFormGroup(group, subgroup).controls[field].setValue(selectedMediaId);
          } else {
            this.itemForm.controls[field].setValue(selectedMediaId);
          }

          //n.valueChanges();
          // n.controls[field].setValue(selectedMediaId);
          //setValue = ;
        }
        sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogMediaRef = null;
    });
  }


  getMediaValue(field, group = null, subgroup = null, subsubgroup = null) {
    // console.log('Group', group);
    // console.log('Subgroup', subgroup);
    // console.log('Field', field);

    //console.log(this.getItemFormGroup(index).controls[field].value)
    if (this.getFormGroup(group, subgroup, subsubgroup).controls[field].value !== '' || this.getFormGroup(group, subgroup, subsubgroup).controls[field].value) {
      return this.getFormGroup(group, subgroup, subsubgroup).controls[field].value;
    } else {
      return GlobalVariable.defaultMediaUrl;
    }
  }
  getFormGroup(group, subgroup = null, subsubgroup = null): UntypedFormGroup {
    if (subsubgroup) {
      const groupA = this.itemForm.get(group) as UntypedFormArray;
      const subGroup = groupA.controls[subgroup] as UntypedFormArray;
      const formGroup = subGroup.controls[subsubgroup] as UntypedFormGroup;
      return formGroup;

    } else {


      if (subgroup) {
        const groupA = this.itemForm.get(group) as UntypedFormArray;
        const formGroup = groupA.controls[subgroup] as UntypedFormGroup;
        return formGroup;
      } else {
        const formGroup = this.itemForm.get(group) as UntypedFormGroup;
        return formGroup;
      }
    }

  }

  cleanMediaField(e, field, group = null, subgroup = null): void {
console.log("e", e);
console.log("field",field);
console.log("group", group);
console.log('subgroup',subgroup);


    if (e.target.parentNode.previousSibling) {
      console.log("M",e.target.parentNode.previousSibling.firstChild.getElementsByTagName('img')[0]);
      let m = e.target.parentNode.previousSibling.firstChild.getElementsByTagName('img')[0];
      let n = e.target.parentNode.nextSibling;
      e.target.disabled = true;
    m.src = GlobalVariable.defaultMediaUrl;
    n.value = '';
  }
    if (subgroup != null) {
      this.getFormGroup(group, subgroup).controls[field].reset('');
    } else {
      this.getFormGroup(group).controls[field].reset('');

    }
    // this.media = "";
  }

  slug(input: string) {
    var slugPipe = new SlugPipe();
    return slugPipe.transform(input);
  }
  //Unique Key
  static slug(afs: AngularFirestore, slug: string = null) {
    return (control: AbstractControl) => {
      const slug = control.value;
      return afs.collection('pages', ref => ref.where('slug', '==', slug))
        .valueChanges().pipe(
          debounceTime(500),
          take(1),
          map(arr => {
            if (arr.length) {
              if (slug) {
                if (arr[0]['slug'] === slug) {
                  return null;
                } else {
                  return { codeAvailable: false };
                }
              } else {
                return { codeAvailable: false }
              }
            } else {
              return null;
            }
          }
          )
        );
    }
  }

}

