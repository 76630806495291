import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/core/auth.service';


@Component({
  selector: 'app-events-tabs',
  templateUrl: './events-tabs.component.html',
  styleUrls: ['./events-tabs.component.scss']
})
export class EventsTabsComponent implements OnInit {
  years = ['2019','2018','2017'];
  eventos: any[];
  constructor(
    public dialog: MatDialog, 
    private http: HttpClient,  
    private afs: AngularFirestore,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.getEvents();
  }
  getEvents(){
    //this.authService.user$.subscribe(user => this.user = user);
        //this.polls$ = this.pollsService
        //this.pages = this.getPageList(this.startAt);
        let date: Date = new Date();
  
        let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        let fakeDate =  date.getFullYear() + '-' +  ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
        const y = this.afs.collection('events', ref => ref.where('start', '<', fakeDate).orderBy('start', 'desc')).snapshotChanges();
   
     //    const y = this.afs.collection('events', ref => ref.orderBy('year', 'asc')).snapshotChanges();
         console.log("años",y)
          y.subscribe(items => {
             //this.spinnerService.hide();
            this.eventos = [];
            const a = items.reverse();
            a.forEach(item => {
              
              this.eventos.push(
                item.payload.doc.data()
              );
            });
            this.eventos.reverse();
           
          });
        }
}
