import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactDialogComponent } from '../components/contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ngOnInit(): void {
   }

  public contactDialogRef: MatDialogRef<ContactDialogComponent>;
  constructor( public dialog: MatDialog) { }


  openContactDialog(): void {
    this.contactDialogRef = this.dialog.open(ContactDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box'
    });
    // this.contactDialogRef.componentInstance.pageId = parentId;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.contactDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.contactDialogRef = null;
    });
  }

}
