// Core Dependences
import { NgModule, NO_ERRORS_SCHEMA, Injector } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CoreModule } from './core/core.module';



// Configuration and Services
import { environment } from '../environments/environment';



import { AuthGuard, AuthGuardAdmin, AuthGuardData } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';

import { AuthService } from './core/auth.service';
import { UserService } from './services/user.service';
import { FirestoreService } from './services/firestore.service';



import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';


import { FacebookModule } from 'ngx-facebook';
// External Modules



// Customs Modules
import { AppRoutes } from './app-routing.module';

// import { MaterialModule } from './material';
import { SharedModule } from './shared/shared.module';
import { Globals } from './shared/globals';

import { EventsModule } from './events/events.module';
import { PagesModule } from './pages/pages.module';
import { UsersModule } from './users/users.module';

import { AppComponent } from './app.component';

import { IndexModule } from './index/index.module';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LayoutModule } from '@angular/cdk/layout';

import { LoginComponent, RegisterDialog, ResetDialog } from './components/login/login.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MediaDialogComponent } from './medias/media-dialog/media-dialog.component';
import { MediasModule } from './medias/medias.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
// import { FacebookModule } from 'ngx-facebook';
// import { MediaComponent } from './components/media/media.component';
// import { ContactComponent, ContactDialog } from './components/contact/contact.component';




// Components

@NgModule({
    imports: [
        BrowserAnimationsModule,
        // NoopAnimationsModule,
        BrowserModule,
        CoreModule,
        SharedModule,
        LayoutModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule,
        HttpClientModule,
        IndexModule,
        EventsModule,
        PagesModule,
        MediasModule,
        UsersModule,
        MatGridListModule,
        MatDialogModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        FacebookModule.forRoot(),
        RouterModule.forRoot(AppRoutes, {}),
        // FacebookModule.forRoot()
    ],
    providers: [
        AngularFirestore,
        FirestoreService,
        AuthService,
        AuthGuard,
        AuthGuardData,
        AuthGuardAdmin,
        AdminGuard,
        UserService,
        { provide: LocationStrategy, useClass: PathLocationStrategy }
        // Globals // so do not provide it into another components/services if you want it to be a singleton
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        ConfirmationDialogComponent,
        RegisterDialog,
        ResetDialog,
        // MediaComponent,
        // ContactComponent
        // ContactDialog
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}

