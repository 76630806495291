import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';



// Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMasonryModule } from 'ngx-masonry';
import { FilterByCategoryPipe } from './pipes/filterByCategory.pipe';
import { FilterBySubCategoryPipe } from './pipes/filterBySubCategory.pipe';
import { FilterByHighlightPipe } from './pipes/filterByHighlight.pipe';
import { DateTimeFormatPipe } from './pipes/datePipe';
import { SlugPipe } from './pipes/slugPipe';
import { SafePipe } from '../shared/pipes/safePipe';
import { GroupByPipe } from '../shared/pipes/groupBy.pipe';

import { SliderComponent } from '../components/slider/slider.component';
import { CardsComponent } from '../components/cards/cards.component';
import { MediaComponent } from '../components/media/media.component';
import { SlickComponent } from '../components/slick/slick.component';
import { ListsComponent } from '../components/lists/lists.component';
import { GridsComponent } from '../components/grids/grids.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { SplitComponent } from '../components/split/split.component';
import { PintComponent } from '../components/pint/pint.component';
import { VideoComponent } from '../components/video/video.component';
//import { EventsComponent } from '../components/events/events.component';

import { GalleryDialogComponent} from '../components/gallery-dialog/gallery-dialog.component';
import { BackgroundComponent } from '../components/background/background.component';

import { ContactDialogComponent } from '../components/contact-dialog/contact-dialog.component';


import { FacebookEventsComponent } from '../components/social/facebook-events/facebook-events.component';
import { FacebookFeedComponent } from '../components/social/facebook-feed/facebook-feed.component';
import { InstagramFeedComponent } from '../components/social/instagram-feed/instagram-feed.component';
import { InstagramDialogComponent } from '../components/social/instagram-feed/instagram-dialog/instagram-dialog.component';
import { InstagramCommercialDialogComponent } from '../components/social/instagram-commercial-feed/instagram-commercial-dialog/instagram-commercial-dialog.component';

import { FacebookEventDialogComponent } from '../components/social/facebook-events/facebook-event-dialog/facebook-event-dialog.component';

import { WhellComponent } from '../components/whell/whell.component';
import { ContentComponent } from '../components/content/content.component';
import { FacebookFeedDialogComponent } from '../components/social/facebook-feed/facebook-feed-dialog/facebook-feed-dialog.component';
import { EventsComponent } from '../components/events/events.component';
import { EventsTabsComponent } from '../components/events/events-tabs/events-tabs.component';
import { EventDialogComponent } from '../components/events/event-dialog/event-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        // HttpModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        OwlModule,
        ReactiveFormsModule,
        RouterModule,
        SlickCarouselModule,
        NgxMasonryModule,
    ],
    exports: [
        CommonModule,
        // HttpModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        OwlModule,
        ReactiveFormsModule,
        RouterModule,
        SlickCarouselModule,
        NgxMasonryModule,
        FilterByCategoryPipe,
        FilterBySubCategoryPipe,
        FilterByHighlightPipe,
        DateTimeFormatPipe,
        SlugPipe,
        GroupByPipe,
        SliderComponent,
        ContentComponent,
        CardsComponent,
        MediaComponent,
        SlickComponent,
        ListsComponent,
        GridsComponent,
        TabsComponent,
        SplitComponent,
        PintComponent,
        VideoComponent,
        EventsComponent,
        EventsTabsComponent,
        EventDialogComponent,
        WhellComponent,
        GalleryDialogComponent,
        ContactDialogComponent,
        BackgroundComponent,
        FacebookEventsComponent,
        FacebookFeedComponent,
        InstagramFeedComponent,
        InstagramDialogComponent,
        InstagramCommercialDialogComponent,
        FacebookEventDialogComponent,
        FacebookFeedDialogComponent
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        FilterByCategoryPipe,
        FilterBySubCategoryPipe,
        FilterByHighlightPipe,
        DateTimeFormatPipe,
        SlugPipe,
        SafePipe,
        GroupByPipe,
        SliderComponent,
        CardsComponent,
        ContentComponent,
        MediaComponent,
        SlickComponent,
        ListsComponent,
        GridsComponent,
        TabsComponent,
        SplitComponent,
        PintComponent,
        VideoComponent,
        EventsComponent,
        EventsTabsComponent,
        EventDialogComponent,
        GalleryDialogComponent,
        ContactDialogComponent,
        BackgroundComponent,
        WhellComponent,
        FacebookEventsComponent,
        FacebookFeedComponent,
        InstagramFeedComponent,
        InstagramDialogComponent,
        InstagramCommercialDialogComponent,
        FacebookEventDialogComponent,
        FacebookFeedDialogComponent
    ]
})
export class SharedModule {}
