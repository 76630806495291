import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { FacebookService,LoginResponse, UIParams, UIResponse, InitParams } from 'ngx-facebook';


import { FacebookFeedDialogComponent } from './facebook-feed-dialog/facebook-feed-dialog.component';
import { Url } from 'url';


export interface FeedElement {
  id: string;
  created_time?: string;
  full_picture?: string;
  picture?: string;
  likes?: any;
  shares?: any;
  story?: string;
  actions?: any;
  message?: any;
  comments?: any;
  paging?:any;
}
@Component({
  selector: 'app-facebook-feed',
  templateUrl: './facebook-feed.component.html',
  styleUrls: ['./facebook-feed.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FacebookFeedComponent implements OnInit {
  ELEMENT_DATA: any[];
  dataSource = [];
  columnsToDisplay = ['start_time', 'name', 'ticket_uri'];
  expandedElement: FeedElement;
  feeds: FeedElement[];
  paging: any;
  public dialogRef: MatDialogRef<FacebookFeedDialogComponent>;
 


  //Slider fields
  selectedIndex: number;
  transform: number;
  dataSize: number;
  type = 'slider';
  pagination: any;
  
  constructor(public dialog: MatDialog, private http: HttpClient,private fb: FacebookService) { 
    let initParams: InitParams = {
      appId: '650190605481638',
      xfbml: true,
      version: 'v2.8'
    };
 
    fb.init(initParams);
  }

  ngOnInit() {
    this.dataSource = [];
  
    this.getPost();
    this.selectedIndex = 0;
    this.transform = 100;
  }
  getPost(afterId = null) {
    console.log('Geting Post');
    let url = 'https://graph.facebook.com/' + Constants.page_id + '/feed?access_token=' + Constants.accessToken + '&fields=message,created_time,story,id,full_picture,picture,actions,likes.summary(true),comments.summary(true),shares.summary(true)&limit=10';
    if (afterId) {
     url = 'https://graph.facebook.com/' + Constants.page_id + '/feed?access_token=' + Constants.accessToken + '&fields=message,created_time,story,id,full_picture,picture,actions,likes.summary(true),comments.summary(true),shares.summary(true)&limit=10&after='+afterId;
   
    }
    // tslint:disable-next-line: max-line-length
     this.http.get(url)
    .subscribe(data => {
        console.log('DATA FEED', data);
        //evento sin class
        this.ELEMENT_DATA = data['data'];
        console.log(this.ELEMENT_DATA);
       // this.dataSource = this.ELEMENT_DATA;
        this.dataSize = data['data'].length;
        this.pagination = data['paging'];

        this.ELEMENT_DATA.sort(function (a, b) {
          var keyA = new Date(a.created_time),
            keyB = new Date(b.created_time);
          // Compare the 2 dates
          if (keyA < keyB) { return -1; }
          if (keyA > keyB) { return 1; }
          return 0;
        });
        console.log(this.ELEMENT_DATA);
        this.dataSource = this.dataSource.concat(this.ELEMENT_DATA);

      });
  }
  

  openFacebookFeedDialog(feeds:FeedElement[],index: number): void {
    this.dialogRef = this.dialog.open(FacebookFeedDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box',
    });
    console.log('openFacebookFeedDialog', index);
    this.dialogRef.componentInstance.feeds = feeds;
    this.dialogRef.componentInstance.i = index;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogRef = null;
    });
  }


  
 
  loginWithFacebook(): void {
 
    this.fb.login()
      .then((response: LoginResponse) => console.log(response))
      .catch((error: any) => console.error(error));
 
  }  
  like(url: string) {
    console.log('Share', url);
    let params: UIParams = {
      href: url,
      method: 'like'
    };
   
    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));
   
  }
  share(url: string) {
    console.log('Share', url);
    let params: UIParams = {
      href: url,
      method: 'share'
    };
   
    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));
   
  }
  /* Slider */
  // Slider Controlls
  selected(x) {
    this.downSelected(x);
    this.selectedIndex = x;
  }
  selectedPrev(x) {
    console.log('Prev Slider', x);
    this.downSelected((x - 1));
    this.selectedIndex = (x - 1);
  }
  selectedNext(x) {
    console.log('Next Slider', x);
    this.downSelected((x + 1));
    this.selectedIndex = (x + 1);
  }



  downSelected(i) {
    this.transform = 100 - (i) * 50;
    this.selectedIndex = this.selectedIndex + 1;
    if (this.selectedIndex > this.dataSource.length) {
      this.selectedIndex = 0;
    }
  }
}
