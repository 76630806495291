import { Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";

import { Page } from '../page';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/compat/storage';

import { Observable } from 'rxjs';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { map, finalize, debounceTime, take } from 'rxjs/operators';
import { GlobalVariable } from '../../shared/globals'
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AngularFireObject } from '@angular/fire/compat/database';
import { MediaDialogComponent } from 'src/app/medias/media-dialog/media-dialog.component';
import { Media } from 'src/app/medias/media';
import { MediasService } from 'src/app/medias/medias.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SlugPipe } from 'src/app/shared/pipes/slugPipe';
import { BlockDialogComponent } from '../block-dialog/block-dialog.component';
import { SectionDialogComponent } from '../section-dialog/section-dialog.component';
import { ItemDialogComponent } from '../item-dialog/item-dialog.component';
import { GalleryDialogComponent } from '../gallery-dialog/gallery-dialog.component';
import { validateHorizontalPosition } from '@angular/cdk/overlay';




@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})

export class PageEditComponent implements OnInit {
  //Creo una instancia del modelo
  page: any;
  pageModel = new Page(null);
  pageForm: UntypedFormGroup;
  blocks: any;
  sections: any[];
  items: any[];
  galleries: any[];

  documentId: any;
  panelOpenState: boolean;

  nextSelectedTemplate: string;
  selectedTemplate: string;
  activeTemplate: string;

  public config: any = {
    'showdata': true,
    'darkTemplate': false,
    'container': true,
    'background': {
      type: 'image',
      media: null,
      position: 'fixed',
      align: 'center',
      color: 'transparent',
    },
    'type': 'list',
    'cols': '3',
    'size': 'medium',
    'width': '100',
    'align': 'left',
    'swipe': false,
    'dark': false,
    'seo': {
      title: "",
      content: "",
      noindex: false,
      media: ""
    },
    //'pageData': true
  };


  types = GlobalVariable.components;
  templates = GlobalVariable.templates;

  public parent = this.afs.collection('pages').valueChanges();
  defaultMediaUrl = GlobalVariable.defaultMediaUrl;
  //media: string = null;
  currentStatus = 1;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogMediaRef: MatDialogRef<MediaDialogComponent>;
  dialogBlockRef: MatDialogRef<BlockDialogComponent>;
  dialogSectionRef: MatDialogRef<SectionDialogComponent>;
  dialogItemRef: MatDialogRef<ItemDialogComponent>;
  dialogGalleryRef: MatDialogRef<GalleryDialogComponent>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private db: FirestoreService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private mediaService: MediasService

  ) { }

  ngOnInit() {
    this.setForm();
    let sub = this.activatedRoute.params.subscribe(params => {
      const id = params["id"]; // (+) converts string 'id' to a number
      this.documentId = id;
      if (params["id"]) {
        this.getPageByID(id);
        this.currentStatus = 2;
        console.log("PageModel", this.pageModel)
      } else {
        this.currentStatus = 1;
      }
    });
  }
  setForm() {
    this.pageForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      subtitle: ['', []],
      excerpt: ['', []],
      content: ['', []],
      media: ['', []],
      template: ['Predeterminada', [Validators.required]],
     
      parent: ['', []],
      slug: ['', []],
      order: ['', []],
      config: this.formBuilder.group({
        background: this.formBuilder.group({
          type: ['', []],
          media: ['', []],
          style: ['', []],
          align: ['', []],
          color: ['', []],
          
        }),
        showdata: [true, []],
        darkTemplate: [false, []],
        seo: this.formBuilder.group({
          title: ['', []],
          content: ['', []],
          media: ['', []],
          noindex: [false, []],
        }),
        //view:this.formBuilder.group({
        container: [true, []],
        type: ['list', []],
        cols: ['3', []],
        size: ['small', []],
        width: ['100', []],
        align: ['left', []],
        swipe: [false, []],
        dark: [false, []]

        //})
      }),
      status: [true, []],
      private: [false, []]

    })
  }
  getPageByID(pageId: string) {

    const docRef = this.afs.doc(`pages/${pageId}`)
    this.page = docRef.valueChanges()




    const x = this.afs.collection('pages').doc(pageId).snapshotChanges();
    x.subscribe(pages => {
      const pagedata = pages.payload.data() as Page;
      pagedata["id"] = pageId;
      this.pageModel = pagedata;
      console.log("Data", pagedata);
      this.pageModel.media = pagedata['media'];
      this.nextSelectedTemplate = pagedata.template;
      this.selectedTemplate = pagedata.template;
      this.setActiveTemplate();

      const w = this.afs.collection('pages').doc(pageId).collection('blocks', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      w.subscribe(blocksa => {
        this.blocks = [];
        const blocks = [];
        blocksa.forEach(block => {
          const x = this.afs.collection('pages').doc(pageId).collection('blocks').doc(block.payload.doc.id).collection('sections', ref => ref.orderBy('order', 'asc')).snapshotChanges();
          x.subscribe(sections => {
            if(sections.length > 0) {  
            const items = [];
            sections.forEach(section => {
              items.push({
                id: section.payload.doc.id,
                data: section.payload.doc.data()
              })
              if (blocks.some(e => e.id === block.payload.doc.id)) {
                let blockIndex = blocks.findIndex(e => e.id == block.payload.doc.id);
               
                if (items.some(e => e.id === section.payload.doc.id)) {

                  let itemIndex = items.findIndex(item => item.id == section.payload.doc.id);
                  
                  console.log("blockIndex", blockIndex);
                  console.log("itemIndex", itemIndex);
                  console.log("block", blocks[blockIndex]);
                  console.log("section", items[itemIndex]);

                  //console.log("blocks", this.blocks);
                  //reemplazar del block section el item y ingresar item index como valor
                  if( blocks[blockIndex].sections){
                  blocks[blockIndex].sections[itemIndex] = items[itemIndex]
                  }else{
                    blocks[blockIndex].sections = [];
                    blocks[blockIndex].sections[itemIndex] = items[itemIndex]
                  
                  }
                  // items[itemIndex] = section.payload.doc.data();
                  this.blocks = blocks;
                } else {
                }

              } else {
                blocks.push({
                  id: block.payload.doc.id,
                  data: block.payload.doc.data(),
                  sections: items
                });
                this.blocks = blocks;
              }
              this.blocks = blocks;
            });
          }else{
            if (!blocks.some(e => e.id === block.payload.doc.id)) {
              blocks.push({
                id: block.payload.doc.id,
                data: block.payload.doc.data(),
              });
            };
          }
          });


    
          this.blocks = blocks;
        });
       
        this.blocks = blocks;
      });

      /*
            const x = this.afs.collection('pages').doc(pageId).collection('sections', ref => ref.orderBy('order', 'asc')).snapshotChanges()
            x.subscribe(sections => {
              this.sections = [];
              sections.forEach(section => {
                this.sections.push({
                  id: section.payload.doc.id,
                  data: section.payload.doc.data()
                })
              });
            });
      */
      const y = this.afs.collection('pages').doc(pageId).collection('items', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      y.subscribe(items => {
        //this.spinnerService.hide();
        this.items = [];
        items.forEach(item => {
          this.items.push({
            id: item.payload.doc.id,
            data: item.payload.doc.data()
          })
        });
      });

      const z = this.afs.collection('pages').doc(pageId).collection('galleries', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      z.subscribe(galleries => {
        //this.spinnerService.hide();
        this.galleries = [];
        galleries.forEach(gallery => {
          this.galleries.push({
            id: gallery.payload.doc.id,
            data: gallery.payload.doc.data()
          })
        });
      });

      //console.log("TAGS A", this.tags);
      this.pageForm = this.formBuilder.group({
        title: [this.pageModel.title, [Validators.required]],
        subtitle: [this.pageModel.subtitle, []],
        content: [this.pageModel.content, []],
        excerpt: [this.pageModel.excerpt, []],
        media: [this.pageModel.media, []],
        template: [this.pageModel.template, [Validators.required]],
         parent: [this.pageModel.parent, []],
        slug: [this.pageModel.slug, []],
        order: [this.pageModel.order, []],
        status: [this.pageModel.status, []],
        config: this.formBuilder.group({
          background: this.formBuilder.group({
            type: [this.pageModel.config.background.type, []],
            media: [this.pageModel.config.background.media, []],
            style: [this.pageModel.config.background.style, []],
            align: [this.pageModel.config.background.align, []],
            color: [this.pageModel.config.background.color, []],
          }),
          showdata: [this.pageModel.config.showdata, []],
          darkTemplate: [this.pageModel.config.darkTemplate, []],
       
          seo: this.formBuilder.group({
            title: [this.pageModel.config.seo.title, []],
            content: [this.pageModel.config.seo.content, []],
            media: [this.pageModel.config.seo.media, []],
            noindex: [this.pageModel.config.seo.noindex, []],
          }),
          //view:this.formBuilder.group({
          container: [this.pageModel.config.container, []],
          type: [this.pageModel.config.type, []],
          cols: [this.pageModel.config.cols, []],
          size: [this.pageModel.config.size, []],
          width: [this.pageModel.config.width, []],
          align: [this.pageModel.config.align, []],
          swipe: [this.pageModel.config.swipe, []],
          dark: [this.pageModel.config.dark, []],
          //})
        }),
        private: [this.pageModel.private, []],
      });      //console.log(this.pollQuestions);
    });

  }
  selectedChildChangedHandler(media: Media) {
    //this.image = media.id;
    console.log("Selected Page Media", media);
  }

  onSubmit() {
    //console.log(`Image: ${ JSON.stringify(f.value)}`);
    console.log('Form:', this.pageForm);
    if (this.pageForm.valid) {
      if (!this.documentId) {
        console.log('Creando un nuevo documento!');
        var idBefore = this.afs.createId();
        const data = {
          id: idBefore,
          title: this.pageForm.get('title').value,
          subtitle: this.pageForm.get('subtitle').value,
          content: this.pageForm.get('content').value,
          excerpt: this.pageForm.get('excerpt').value,
          media: this.pageForm.get('media').value,
          template: this.pageForm.get('template').value,
          parent: this.pageForm.get('parent').value,
          //tags: this.pollForm.get('tags').value,
          slug: (this.pageForm.get('slug').value != "") ? this.slug(this.pageForm.get('slug').value) : this.slug(this.pageForm.get('title').value),
          order: this.pageForm.get('order').value,
          status: this.pageForm.get('status').value,
          private: this.pageForm.get('private').value,
          config: this.pageForm.get('config').value,
          createdOn: new Date(),
          updatedOn: new Date(),
          // level: this.pollForm.get('level').value,
        }
        this.afs.collection('pages').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.router.navigate(["/pages/admin/"]);
        }, (error) => {
          console.log(error);
        });;

      } else {
        console.log('Editando el documento');
        const data = {
          // config: this.config,

          title: this.pageForm.get('title').value,
          subtitle: this.pageForm.get('subtitle').value,
          excerpt: this.pageForm.get('excerpt').value,
          content: this.pageForm.get('content').value,
          media: this.pageForm.get('media').value,
          template: this.pageForm.get('template').value,
          parent: this.pageForm.get('parent').value,
          //tags: this.pollForm.get('tags').value,
          slug: (this.pageForm.get('slug').value != "") ? this.slug(this.pageForm.get('slug').value) : this.slug(this.pageForm.get('title').value),
          order: this.pageForm.get('order').value,
          status: this.pageForm.get('status').value,
          private: this.pageForm.get('private').value,
          config: this.pageForm.get('config').value,

          updatedOn: new Date(),
          /**/
        }
        this.afs.collection('pages').doc(this.documentId).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
          this.router.navigate(["/pages/admin/"]);
        }, (error) => {
          console.log(error);
        });
      }
    } else {
      console.log("El Formulario no es valido");
    }
  }
  changeTemplate($event: EventEmitter<MatSelectChange>, selected): void {
    console.log(selected);
    console.log("changeTemplate");
    if (this.documentId) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = "Estas seguro en cambiar la plantilla? Al confirmar todos los items y contenidos de la página seran eliminados"
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedTemplate = this.nextSelectedTemplate;
          this.setActiveTemplate();
          // do confirmation actions
          /*  var query = this.afs.collection('pages').doc(this.documentId).collection('items').ref;
            query.get().then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                doc.ref.delete();
              });
            });
            var query = this.afs.collection('pages').doc(this.documentId).collection('sections').ref;
            query.get().then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                doc.ref.delete();
              });
            });*/
        } else {
          this.nextSelectedTemplate = this.selectedTemplate;
          return false;
        }
        this.dialogRef = null;
      });
    }
  }
  setActiveTemplate() {
    switch (this.selectedTemplate) {
      case 'List-01':
      case 'List-02':
      case 'List-03':
        this.activeTemplate = 'lists';
        break;
      case 'gallery':
        this.activeTemplate = 'galleries';
        break;
      default:
        this.activeTemplate = 'sections';
        break;
    }
  }

  public deleteBlock(pageId, id) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Estas seguro que desea eliminar este bloque?, Al eliminar el bloque todas las secciones que este contenga tambien seran elimandas"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        var query = this.afs.collection('pages').doc(pageId).collection('blocks').doc(id).collection('sections').ref.where('id', '==', id);
        query.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });
        this.afs.collection('pages').doc(pageId).collection('blocks').doc(id).delete().then(() => {
          console.log('El bloque ha sido eliminado!');
          return true;
        });
        this.dialogRef = null;
      }else{
        console.log('El usuario cancelo la operación');
      
      }
      return true;
    });
  }
  public deleteBlockSection(pageId, blockId,sectionId) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Estas seguro de eliminar esta seccion?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        var query = this.afs.collection('pages').doc(pageId).collection('blocks').doc(blockId).collection('sections').ref.where('id', '==', sectionId);
        query.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });
        this.afs.collection('pages').doc(pageId).collection('blocks').doc(blockId).collection('sections').doc(sectionId).delete().then(() => {
          console.log('Documento eliminado!');
          return true;
        });
        this.dialogRef = null;
      }
      console.log('El Bloque ha sido eliminado!');
      return true;
    });
  }
  
  public deleteItem(pageId, id) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Estas seguro de eliminar este item"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        var query = this.afs.collection('pages').doc(pageId).collection('items').ref.where('itemId', '==', id);
        query.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });
        this.afs.collection('pages').doc(pageId).collection('items').doc(id).delete().then(() => {
          console.log('El item ha sido eliminado!');
          return true;
        });
        this.dialogRef = null;
      }
      console.log('Documento !');
      return true;
    });
  }
  public deleteGallery(pageId, id) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "¿Estas seguro de eliminar este item?"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        var query = this.afs.collection('pages').doc(pageId).collection('galleries').ref.where('id', '==', id);
        query.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });
        this.afs.collection('pages').doc(pageId).collection('galleries').doc(id).delete().then(() => {
          console.log('El item ha sido eliminado!');
          return true;
        });
        this.dialogRef = null;
      }
      console.log('Documento !');
      return true;
    });
  }

  dropBlocks(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);

    console.log("DropBlock");
    this.blocks.forEach((item, index) => {
      if (item !== undefined) {
        console.log("Block item", item.data.order); // 1, 2, 3
        console.log("Block index", index); // 1, 2, 3
        console.log(item); // 1, 2, 3
        if(item.data.order != index){
        this.db.upsert('/pages/' + this.pageModel.id + '/blocks/' + item.id, { order: index }).then()
          .catch(error => console.log(error));
      }
      }
    });
  }

  dropBlockSections(event: CdkDragDrop<string[]>, blockId: string, sections: any[]) {
    console.log("DropBlockSections", blockId);
    moveItemInArray(sections, event.previousIndex, event.currentIndex);
    sections.forEach((item, index) => {
      if (item !== undefined) {
        console.log("Section item", item); // 1, 2, 3
        console.log("Section index", index); // 1, 2, 3
        if(item.data.order != index){
        this.db.upsert('/pages/' + this.pageModel.id + '/blocks/' + blockId + '/sections/' + item.id, { order: index }).then()
          .catch(error => console.log(error));
        }
      }
    })
  }
  
  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    // moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    moveItemInArray(this.galleries, event.previousIndex, event.currentIndex);

    /* console.log("PreviousIndex" + event.previousIndex);
     console.log("CurrentIndex" + event.currentIndex);
     console.log("Poll ID" + this.PollModel.id);
     console.log("Question ID" + this.pollQuestions[event.currentIndex].id);
    */


    
    this.items.forEach((item, index) => {
      if (item !== undefined) {
        console.log("List items", item); // 1, 2, 3
        //console.log(item); // 1, 2, 3
        console.log("List index", index); // 1, 2, 3
        this.db.upsert('/pages/' + this.pageModel.id + '/items/' + item.id, { order: index })
          .then()
          .catch(error => console.log(error));
      }
    });
    this.galleries.forEach((item, index) => {
      if (item !== undefined) {
        console.log("List items", item); // 1, 2, 3
        //console.log(item); // 1, 2, 3
        console.log("List index", index); // 1, 2, 3
        this.db.upsert('/pages/' + this.pageModel.id + '/galleries/' + item.id, { order: index })
          .then()
          .catch(error => console.log(error));
      }
    });
    console.log('Haz realizado cambios en el orden del listado');
    //return false;

  }

  openBlockDialog(parentId: string, id: string = null): void {
    console.log("PollId", parentId);
    console.log("questionId", id);
    this.dialogBlockRef = this.dialog.open(BlockDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      maxWidth: '1000px',
      width: '80vw'
    });
    this.dialogBlockRef.componentInstance.pageId = parentId;
    this.dialogBlockRef.componentInstance.blockId = id;
    this.dialogBlockRef.afterClosed().subscribe(data => {
      if (data) {
        //sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogBlockRef = null;
    });
  }
  openSectionBlockDialog(pageId: string, blockId: string = null, sectionId: string = null): void {
    console.log("pageId", pageId);
    console.log("blockId", blockId);
    console.log("sectionId", sectionId);
    this.dialogSectionRef = this.dialog.open(SectionDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      maxWidth: '1000px',
      width: '80vw'
    });
    this.dialogSectionRef.componentInstance.pageId = pageId;
    this.dialogSectionRef.componentInstance.blockId = blockId;
    this.dialogSectionRef.componentInstance.sectionId = sectionId;
    this.dialogSectionRef.afterClosed().subscribe(data => {
      if (data) {
        //sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogSectionRef = null;
    });
  }
  
  openItemDialog(pageId: string, itemId: string = null): void {
    console.log("pageId", pageId);
    console.log("itemId", itemId);
    this.dialogItemRef = this.dialog.open(ItemDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      maxWidth: '1000px',
      width: '80vw'
    });
    this.dialogItemRef.componentInstance.pageId = pageId;
    this.dialogItemRef.componentInstance.itemId = itemId;
    this.dialogItemRef.afterClosed().subscribe(data => {
      if (data) {
        //sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogItemRef = null;
    });
  }
  openGalleryDialog(pageId: string, id: string = null): void {
    console.log("pageId", pageId);
    console.log("itemId", id);
    this.dialogGalleryRef = this.dialog.open(GalleryDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw'
    });
    this.dialogGalleryRef.componentInstance.pageId = pageId;
    this.dialogGalleryRef.componentInstance.itemId = id;
    this.dialogGalleryRef.afterClosed().subscribe(data => {
      if (data) {
        //sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogItemRef = null;
    });
  }



  openMediaDialog(e, field, group = null, subgroup = null, subsubgroup = null): void {
    var selectedMediaId = null;
    var selectedMediaUrl = null;
    //if (this.itemId) {
    this.dialogMediaRef = this.dialog.open(MediaDialogComponent, {
      disableClose: false
    });
    this.dialogMediaRef.componentInstance.field = field;
    this.dialogMediaRef.componentInstance.confirmMessage = "";
    const sub = this.dialogMediaRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
      //console.log(data.id)
      selectedMediaId = data.id;
      selectedMediaUrl = data.url
    });
    this.dialogMediaRef.afterClosed().subscribe(data => {
      if (data) {
        if (selectedMediaId) {
          if (e.target.parentNode.previousSibling.firstChild.firstChild) {
            var m = e.target.parentNode.previousSibling.firstChild.firstChild;
            var n = e.target.parentNode.nextSibling;
            e.target.nextSibling.disabled = false;
          }
          m.src = selectedMediaUrl;
          n.value = selectedMediaId;
          if (group != null) {
            this.getFormGroup(group, subgroup, subsubgroup).controls[field].setValue(selectedMediaId);
          } else {
            this.pageForm.controls[field].setValue(selectedMediaId);
          }
      }
        sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogMediaRef = null;
    });
  }


  getMediaValue(field, group = null, subgroup = null) {
    if (this.getFormGroup(group, subgroup).controls[field].value == "" || this.getFormGroup(group, subgroup).controls[field].value) {
      return this.getFormGroup(group, subgroup).controls[field].value;
    } else {
      return GlobalVariable.defaultMediaUrl;
    }
  }
  getFormGroup(group, subgroup = null, subsubgroup = null): UntypedFormGroup {
    if (subsubgroup) {
      const groupA = this.pageForm.get(group) as UntypedFormArray;
      const subGroup = groupA.controls[subgroup] as UntypedFormArray;
      const formGroup = subGroup.controls[subsubgroup] as UntypedFormGroup;
      return formGroup;
    } else {
      if (subgroup) {
        const groupA = this.pageForm.get(group) as UntypedFormArray;
        const formGroup = groupA.controls[subgroup] as UntypedFormGroup;
        return formGroup;
      } else {
        const formGroup = this.pageForm.get(group) as UntypedFormGroup;
        return formGroup;
      }
    }

  }
  cleanMediaField(e, field, group = null, subgroup = null): void {
    if (e.target.parentNode.previousSibling) {
      var m = e.target.parentNode.previousSibling.firstChild;
      var n = e.target.parentNode.nextSibling;
      e.target.disabled = true;
    }
    m.src = GlobalVariable.defaultMediaUrl;
    n.value = ""

    if (subgroup != null) {
      this.getFormGroup(group, subgroup).controls[field].reset("");
    } else {
      this.pageForm.controls[field].reset("");
    }
  }
  slug(input: string) {
    var slugPipe = new SlugPipe();
    return slugPipe.transform(input);
  }

  //Unique Key
  static slug(afs: AngularFirestore, slug: string = null) {
    return (control: AbstractControl) => {
      const slug = control.value;
      return afs.collection('pages', ref => ref.where('slug', '==', slug))
        .valueChanges().pipe(
          debounceTime(500),
          take(1),
          map(arr => {
            if (arr.length) {
              if (slug) {
                if (arr[0]['slug'] === slug) {
                  return null;
                } else {
                  return { codeAvailable: false };
                }
              } else {
                return { codeAvailable: false }
              }
            } else {
              return null;
            }
          }
          )
        );
    }
  }

}