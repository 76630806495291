import { Component, OnInit, Input } from '@angular/core';
import { TriviasService } from '../trivias/trivias.service';
import { Trivia } from '../trivias/trivia';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs'
import { AuthService } from '../core/auth.service';
import { GlobalVariable } from '../shared/globals';
import { Poll } from '../polls/poll';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public trivias = [];
  public polls = [];
  public categories = GlobalVariable.categories;
  public subcategories = GlobalVariable.subcategories;
  public cards = [];
  public slides = [];
  public state = 'in';
  public cols = 4;
  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private triviasService: TriviasService) {

  }

  ngOnInit() {

    this.slides = [
      { media: "bYhfVvnAQc1ZkC2WxzX3", link: "/trivias/front/8wThnfRWIMrkqydRvKqs", target: "_self" },
      { media: "bYhfVvnAQc1ZkC2WxzX3", link: "/trivias/front/zVbM1wIBpmLXDFVMnDMO", target: "_self" },
      { media: "bYhfVvnAQc1ZkC2WxzX3", link: "http://asp-es.secure-zone.net/v2/index.jsp?id=9407/14541/31864&lng=es", target: "_blank" }
    ];
    this.cards = [
      { title: "PUBLICACIONES", subtitle: "Infancia, Adolescencia y Salud Mental", content: "", image: "https://firebasestorage.googleapis.com/v0/b/mptpolls.appspot.com/o/images%2FImg_Publicaciones.jpg?alt=media&token=fc8f69cc-654a-4a8e-8921-4da15892b0f5", link: 'http://mptutelar.gob.ar/category/publicaciones/otras-publicaciones', target: '_blank' },
      { title: "CAMPAÑAS", subtitle: "Difusión de derechos", content: "", image: "https://firebasestorage.googleapis.com/v0/b/mptpolls.appspot.com/o/images%2FImg_Campanas.jpg?alt=media&token=90165eed-bd08-4914-878f-a439c1d34446", link: 'http://mptutelar.gob.ar/category/comunicaci-n/novedades-del-mpt', target: '_blank' },
      { title: "ACTIVIDADES", subtitle: "Eventos y Jornadas", content: "", image: "https://firebasestorage.googleapis.com/v0/b/mptpolls.appspot.com/o/images%2FImg_Eventos.jpg?alt=media&token=1d40d133-1f97-4fbd-9d9b-42f271ece57b", link: 'http://mptutelar.gob.ar/', target: '_blank' },
      { title: "CONTACTANOS", subtitle: "Canales de acceso a la justicia", content: "", image: "https://firebasestorage.googleapis.com/v0/b/mptpolls.appspot.com/o/images%2FImg_Encontranos.jpg?alt=media&token=3c5859ee-112a-4e08-be39-87efabe9e500", link: 'http://mptutelar.gob.ar/', target: '_blank' },

    ];
    const x = this.afs.collection('trivias');
    x.snapshotChanges().subscribe(triviasa => {
      //this.spinnerService.hide();
      this.trivias = [];
      triviasa.forEach(element => {
        const data = element.payload.doc.data() as Trivia;
        data.triviaId = element.payload.doc.id;
        this.trivias.push(data as Trivia);
      });
    });

    const y = this.afs.collection('polls');
    y.snapshotChanges().subscribe(polls => {
      //this.spinnerService.hide();
      this.polls = [];
      polls.forEach(element => {
        const data = element.payload.doc.data() as Poll;
        //data.pollId = element.payload.doc.id;
        this.polls.push(data as Poll);
      });
    });
    this.state = 'in';
     
  }

}
