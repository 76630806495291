// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  margin-top: 10px;
}

img {
  margin-bottom: 10px;
}

.mat-h2.title {
  white-space: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 5px;
}

.mat-h3.subtitle {
  margin-bottom: 0px;
  white-space: normal;
  font-size: 13px;
  line-height: 18px;
}

a.cardButton {
  text-align: left;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: inherit;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhcmRzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQUE7QUFDRjs7QUFDQTtFQUNBLG1CQUFBO0FBRUE7O0FBQUE7RUFDRSxtQkFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLGtCQUFBO0VBQ0EsZUFBQTtBQUdGOztBQURBO0VBQ0Usa0JBQUE7RUFDQSxtQkFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtBQUlGOztBQUNBO0VBQ0UsZ0JBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtFQUNBLHFCQUFBO0VBQ0EsY0FBQTtBQUVGIiwiZmlsZSI6ImNhcmRzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNhcmQtaGVhZGVyIHtcbiAgbWFyZ2luLXRvcDogMTBweDtcbn1cbmltZyB7XG5tYXJnaW4tYm90dG9tOiAxMHB4O1xufVxuLm1hdC1oMi50aXRsZXtcbiAgd2hpdGUtc3BhY2U6IG5vcm1hbDtcbiAgZm9udC1zaXplOiAxNnB4O1xuICBsaW5lLWhlaWdodDogMjRweDtcbiAgbWFyZ2luLWJvdHRvbTogNHB4O1xuICBtYXJnaW4tdG9wOiA1cHg7XG59XG4ubWF0LWgzLnN1YnRpdGxle1xuICBtYXJnaW4tYm90dG9tOiAwcHg7XG4gIHdoaXRlLXNwYWNlOiBub3JtYWw7XG4gIGZvbnQtc2l6ZTogMTNweDtcbiAgbGluZS1oZWlnaHQ6IDE4cHg7XG4gIFxuIFxufVxuXG5hLmNhcmRCdXR0b257XG4gIHRleHQtYWxpZ246IGxlZnQ7XG4gIHBhZGRpbmc6IDBweDtcbiAgYm9yZGVyOiAwcHg7XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgY29sb3I6IGluaGVyaXQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/cards/cards.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACA,mBAAA;AAEA;;AAAA;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAGF;;AADA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAIF;;AACA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,cAAA;AAEF;AACA,4kCAA4kC","sourcesContent":[".card-header {\n  margin-top: 10px;\n}\nimg {\nmargin-bottom: 10px;\n}\n.mat-h2.title{\n  white-space: normal;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 4px;\n  margin-top: 5px;\n}\n.mat-h3.subtitle{\n  margin-bottom: 0px;\n  white-space: normal;\n  font-size: 13px;\n  line-height: 18px;\n  \n \n}\n\na.cardButton{\n  text-align: left;\n  padding: 0px;\n  border: 0px;\n  text-decoration: none;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
