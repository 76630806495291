import { Component, OnInit,Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//componentes de form
import {FormControl, Validators, NgForm} from '@angular/forms';
//modelo creado
import {ContactForm} from '../../models/contact-form';
//services en angular
import {SendEmailService} from '../../services/send-email.service';
//libreria rxjs
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
//material desing de angular
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
// Esta linea fue agregada automaticamente pueden borrarlo
//import { NgForm } from '@angular/forms/src/directives/ng_form';
export interface DialogData {
  title: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  title: string;
  

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(ContactDialog, {
      width: 'auto',
      minWidth:'400px',
      minHeight:'400px',
      data: {title: this.title},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.title = result;
    });
  }
  ngOnInit() {}

}

@Component({
  selector: './contact-dialog',
  templateUrl: './contact-dialog.html',
})
export class ContactDialog {
  onNoClick(): void {
    this.dialogRef.close();
  }
  //creacion de variable contact
  ContactModel = new ContactForm();
  private emailResponse;
  private truefalse:boolean = false;
 
  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar,public dialogRef: MatDialogRef<ContactDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }
 



  onSubmit(f: NgForm){
    this.getSentServices(this.ContactModel, f);
  }
  //metodo de services
  getSentServices(body:ContactForm, f: NgForm){
      this.sendServices.getResponseEmail(body).subscribe(
          data => {
              if(data){
                  this.snackBar.open("Gracias por el mensaje", "Correcto", {
                      duration: 2000,
                    });
                    f.reset();
              }
              else{
                  this.snackBar.open(":(", "Error", {
                      duration: 2000,
                  });    
              }         
          },
          err => { this.snackBar.open("Algo fallo :/, correo: biframes@gmail.com", "ups", {
              duration: 5000,
            }); }
        ); 
  }
}