import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { PagesRoutingModule } from './pages-routing.module';
import { NoAccessComponent } from './no-access/no-access.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageComponent } from './page/page.component';
import { PageEditComponent } from './page-edit/page-edit.component';
import { PageAdminComponent } from './page-admin/page-admin.component';
import { ItemViewComponent } from './item-view/item-view.component';
import { BlockDialogComponent } from './block-dialog/block-dialog.component';
import { SectionDialogComponent } from './section-dialog/section-dialog.component';
import { ItemDialogComponent } from './item-dialog/item-dialog.component';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { HomeComponent } from './templates/home/home.component';
import { CalendarComponent } from './templates/calendar/calendar.component';
import { PressComponent } from './templates/press/press.component';
import { DefaultComponent } from './templates/default/default.component';
import { MainComponent } from './templates/main/main.component';
import { MenuComponent } from '../menu/menu.component';
import { Main2Component } from './templates/main2/main2.component';
import { PageSocialLoginComponent } from './page-social-login/page-social-login.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PagesRoutingModule
    ],
    declarations: [
        NoAccessComponent,
        PageNotFoundComponent,
        PageComponent,
        PageEditComponent,
        PageAdminComponent,
        ItemViewComponent,
        BlockDialogComponent,
        SectionDialogComponent,
        GalleryDialogComponent,
        ItemDialogComponent,
        HomeComponent,
        CalendarComponent,
        PressComponent,
        DefaultComponent,
        MainComponent,
        MenuComponent,
        Main2Component,
        PageSocialLoginComponent,
    ]
})
export class PagesModule { }
