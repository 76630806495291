import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  DocumentReference,
  CollectionReference
} from '@angular/fire/compat/firestore';
import { Observable, from, of } from 'rxjs';
import { map, tap, take, switchMap, mergeMap, expand, takeWhile, catchError } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User, UserDetails } from '../core/user';


type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  user$: Observable<User | null>;
  
  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    // Observar el estado de autenticación y actualizar la variable 'user'
  this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  /// **************
  /// Get a Reference
  /// **************

  /**
     * Obtiene una referencia a una colección o documento de Firestore.
     * @param ref La referencia a la colección o documento. Puede ser una cadena o una referencia ya construida.
     * @param queryFn Función de consulta opcional para filtrar la colección.
     * @returns La colección o documento de Firestore.
     */
  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /**
   * Obtiene una referencia a un documento de Firestore.
   * @param ref La referencia al documento. Puede ser una cadena o una referencia ya construida.
   * @returns El documento de Firestore.
   */
  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  /**
    * Obtiene los datos de un documento de Firestore.
    * @param ref La referencia al documento.
    * @returns Un observable que emite el objeto de datos del documento.
    */
  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
          return doc.payload.data() as T;
        }),
        //catchError(this.handleError) // Manejo de errores
      );
  }

  /**
   * Obtiene los datos de una colección de Firestore.
   * @param ref La referencia a la colección.
   * @param queryFn Función de consulta opcional para filtrar la colección.
   * @returns Un observable que emite un array con los objetos de datos de la colección.
   */
  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs: DocumentChangeAction<T>[]) => {
          return docs.map((a: DocumentChangeAction<T>) => a.payload.doc.data()) as T[];
        }),
        //catchError(this.handleError) // Manejo de errores
      );
  }


  /// with Ids
  /**
   * Obtiene los datos de una colección de Firestore, incluyendo los IDs de los documentos.
   * @param ref La referencia a la colección.
   * @param queryFn Función de consulta opcional para filtrar la colección.
   * @returns Un observable que emite un array con objetos que contienen el ID y los datos de cada documento.
   */
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            const data: Object = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
        //catchError(this.handleError) // Manejo de errores
      );
  }

  /// **************
  /// Write Data
  /// **************

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /**
   * Crea un nuevo documento en Firestore.
   * @param ref La referencia a la colección donde se creará el documento.
   * @param data Los datos del nuevo documento.
   * @returns Una promesa que se resuelve cuando el documento se crea correctamente.
   */
  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedOn: timestamp,
      createdOn: timestamp,
    });
  }

  /**
   * Actualiza los datos de un documento existente en Firestore.
   * @param ref La referencia al documento.
   * @param data Los datos actualizados del documento.
   * @returns Una promesa que se resuelve cuando el documento se actualiza correctamente.
   */
  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedOn: this.timestamp,
    });
  }

  /**
   * Elimina un documento de Firestore.
   * @param ref La referencia al documento.
   * @returns Una promesa que se resuelve cuando el documento se elimina correctamente.
   */
  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  /**
   * Agrega un nuevo documento a una colección de Firestore.
   * @param ref La referencia a la colección.
   * @param data Los datos del nuevo documento.
   * @returns Una promesa que se resuelve con la referencia al documento creado.
   */
  add<T>(ref: CollectionPredicate<T>, data): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedOn: timestamp,
      createdOn: timestamp,
    });
  }

  /**
   * Crea un objeto GeoPoint de Firestore a partir de latitud y longitud.
   * @param lat La latitud.
   * @param lng La longitud.
   * @returns El objeto GeoPoint.
   */
  geopoint(lat: number, lng: number): firebase.firestore.GeoPoint {
    return new firebase.firestore.GeoPoint(lat, lng);
  }

  /// If doc exists update, otherwise set
  /**
   * Actualiza un documento existente o crea uno nuevo en Firestore dependiendo de si ya existe.
   * @param ref La referencia al documento.
   * @param data Los datos del documento.
   * @returns Una promesa que se resuelve cuando la operación se completa.
   */
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

  /// **************
  /// Inspect Data
  /// **************

  /**
     * Muestra información sobre un documento de Firestore en la consola.
     * @param ref La referencia al documento.
     */
  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((d: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>>) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        }),
      )
      .subscribe();
  }

  /**
   * Muestra información sobre una colección de Firestore en la consola.
   * @param ref La referencia a la colección.
   */
  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        }),
      )
      .subscribe();
  }

  /// **************
  /// Create and read doc references
  /// **************
  
  /* Crea</1\> una referencia entre dos documentos de Firestore\.
   @param host El documento que contiene la referencia\.
  * @param key La clave de la propiedad en el documento 'host' que contendrá la referencia\.
  * @param doc El documento al que se hace referencia\.
  * @returns Una promesa que se resuelve cuando la referencia se crea correctamente\.
  */
  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>) {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }
  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>) {
    return this.doc$(ref).pipe(
      map((doc: T) => {
        for (const k of Object.keys(doc)) {
          if (doc[k] instanceof firebase.firestore.DocumentReference) {
            doc[k] = this.doc(doc[k].path);
          }
        }
        return doc;
      }),
    );
  }

 /// **************
  /// Atomic batch example
  /// **************

  /// Just an example, you will need to customize this method.
  /**
   * Ejemplo de cómo realizar una operación atómica en Firestore utilizando un lote.
   */
  atomic() {
    const batch = firebase.firestore().batch();
    /// add your operations here

    const itemDoc = firebase.firestore().doc('items/myCoolItem');
    const userDoc = firebase.firestore().doc('users/userId');

    const currentTime = this.timestamp;

    batch.update(itemDoc, { timestamp: currentTime });
    batch.update(userDoc, { timestamp: currentTime });

    /// commit operations
    return batch.commit();
  }

  /**
   * Elimina una colección de Firestore en lotes.
   * @param path La ruta a la colección.
   * @param batchSize El tamaño de cada lote.
   * @returns Un observable que se completa cuando la colección se elimina.
   */
  deleteCollection(path: string, batchSize: number): Observable<any> {
    const source = this.deleteBatch(path, batchSize);

    // expand will call deleteBatch recursively until the collection is deleted
    return source.pipe(
      expand(val => this.deleteBatch(path, batchSize)),
      takeWhile(val => val > 0),
    );
  }

  // Detetes documents as batched transaction
  private deleteBatch(path: string, batchSize: number): Observable<any> {
    const colRef = this.afs.collection(path, ref => ref.orderBy('__name__').limit(batchSize));

    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap((snapshot: DocumentChangeAction<any>[]) => {
        // Delete documents in a batch
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.payload.doc.ref);
        });

        return from(batch.commit()).pipe(map(() => snapshot.length));
      }),
    );
  }
}




import { combineLatest, pipe, defer } from 'rxjs';


export const leftJoin = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = ref => ref.where(field, '==', doc[field]).limit(limit);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap(final => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};

export const leftJoinDocument = (afs: AngularFirestore, field, collection) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          cache.clear();

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            // Skip if doc field does not exist or is already in cache
            if (!doc[field] || cache.get(doc[field])) {
              continue;
            }

            // Push doc read to Array
            reads$.push(
              afs
                .collection(collection)
                .doc(doc[field])
                .valueChanges()
            );
            cache.set(doc[field], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v[field]);
            return { ...v, [field]: joins[joinIdx] || null };
          });
        }),
        tap(final =>
          console.log(
            `Queried ${(final as any).length}, Joined ${cache.size} docs`
          )
        )
      );
    });
};


export const docJoin = (
  afs: AngularFirestore,
  paths: { [key: string]: string }
) => {
  return source =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap(data => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map(k => {
            const fullPath = `${paths[k]} /${parent[k]}`;
            return afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map(arr => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
};