// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-card {
  max-width: 400px;
}

.example-header-image img {
  background-size: cover;
  width: 100%;
  margin: auto;
  border-radius: 100%;
}

.success {
  color: #05630a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItYWNjb3VudC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGdCQUFBO0FBQ0o7O0FBQ0U7RUFDRSxzQkFBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0VBQ0EsbUJBQUE7QUFFSjs7QUFDQTtFQUNFLGNBQUE7QUFFRiIsImZpbGUiOiJ1c2VyLWFjY291bnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZXhhbXBsZS1jYXJkIHtcbiAgICBtYXgtd2lkdGg6IDQwMHB4O1xuICB9XG4gIC5leGFtcGxlLWhlYWRlci1pbWFnZSBpbWd7XG4gICAgYmFja2dyb3VuZC1zaXplOiBjb3ZlcjtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBtYXJnaW46IGF1dG87XG4gICAgYm9yZGVyLXJhZGl1czogMTAwJTtcbn1cblxuLnN1Y2Nlc3N7XG4gIGNvbG9yOiAjMDU2MzBhO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/users/user-account/user-account.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AACE;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAEJ;;AACA;EACE,cAAA;AAEF;AACA,4nBAA4nB","sourcesContent":[".example-card {\n    max-width: 400px;\n  }\n  .example-header-image img{\n    background-size: cover;\n    width: 100%;\n    margin: auto;\n    border-radius: 100%;\n}\n\n.success{\n  color: #05630a;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
