import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-index-edit',
  templateUrl: './index-edit.component.html',
  styleUrls: ['./index-edit.component.scss']
})
export class IndexEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.setForm();
  }
  
  setForm() {

  }
}
