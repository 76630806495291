import { NgModule, InjectionToken, Injectable } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';


import { PreloaderComponent } from './components/preloader/preloader.component';
import { IndexComponent } from './index/index.component';
import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { PageComponent } from './pages/page/page.component';
import { ItemViewComponent } from './pages/item-view/item-view.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export const AppRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    pathMatch: 'full',
    data: { preload: true, animation: 'isRight', title: 'Home' }
  },
 
  {
    path: 'index',
    loadChildren: () => import('./index/index.module').then(m => m.IndexModule)   
  },
  {
    path: 'trivias',
    loadChildren: () => import('./trivias/trivias.module').then(m => m.TriviasModule),
    data: { preload: false, animation: 'isRight', title: 'Trivias' }
  },
  {
    path: 'polls',
    loadChildren: () => import('./polls/polls.module').then(m => m.PollsModule),
    data: { preload: false, animation: 'isLeft', title: 'Polls' }
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    data: { preload: false, animation: 'isLeft', title: 'Events' }
  },
  {
    path: 'rooms',
    loadChildren: () => import('./rooms/rooms.module').then(m => m.RoomsModule),
    data: { preload: false, title: 'Rooms' }
  },
  /*{
    path: "pages",
    loadChildren: "./pages/pages.module#PagesModule",
    data: { preload: false , animation: 'isRight' }

  },*/
  {
    path: 'login',
    component: LoginComponent,
    data: { preload: true, animation: 'isRight', title: 'Login' }
  },
  {
    path: 'loginData',
    component: LoginComponent,
    data: { preload: true, animation: 'isRight', title: 'LoginData' }
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    data: { preload: true, animation: 'isRight', title: 'User' }
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: PageNotFoundComponent,
  },
  {
    path: ':slug',
    component: PageComponent,
    pathMatch: 'full',
    data: { preload: true, animation: 'isLeft', title: 'Page' }
  },
  {
    path: 'pages/item/:pageId/:id',
    component: ItemViewComponent,
    data: { preload: true , animation: 'isLeft', type: 'Page' }

  },
  {
    path: 'no-access',
    component: NoAccessComponent
  },

  {
    path: '**',
    component: PageNotFoundComponent
  }

];




@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
      return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): boolean {
      return false;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
      return false;        
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
      return false;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.component === curr.component;

  }
  
}


@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloaderComponent })],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    
    
    PreloaderComponent,
   {

      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.params[0];
        window.open(externalUrl, '_self');
      },
    },
  ],
})

export class AppRoutingModule { }
