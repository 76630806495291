import { Component, OnInit } from '@angular/core';
import * as Instafeed from 'instafeed.js';
import { ContactDialogComponent } from '../../contact-dialog/contact-dialog.component';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/globals';
import { InstagramDialogComponent } from './instagram-dialog/instagram-dialog.component';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

export interface evento {
  id: string;
  caption?: any;
  media_type: string;
  media_url: string;
  thumbnail_url: string;
  like_count?: string;
  comments_count?: string;
  comment?: any;
  children: any;
  timestamp: string;
  username: any;
}


@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss']
})
export class InstagramFeedComponent implements OnInit {
  //ELEMENT_DATA: EventElement[];
  //dataSource = this.ELEMENT_DATA;

  public dialogRef: MatDialogRef<InstagramDialogComponent>;
  ELEMENT_DATA: evento[];
  events: evento[];
  pagination: any;
  userProfile: any;
  activeMediaQuery: string;
  rowWidth: number;
  constructor(public dialog: MatDialog, private http: HttpClient, private mediaObserver: MediaObserver) {}

  ngOnInit() {
    this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      changes.forEach(change => {
        this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
        let columns: number = 5;
        switch (change.mqAlias) {
          case 'xs':
            columns = 3;
            break;
          case 'sm':
            columns = 4;
            break;
          case 'md':
            columns = 4;
            break;
          case 'lg':
            columns = 4;
            break;
          case 'xg':
            columns = 6;
            break;
          default:
            // columns = 4;
            // break;
        }
        this.rowWidth = 100 / columns;
      });
    });


    this.events = [];
    this.userProfile = {
      name: 'Veronica Salinas',
      profile_picture_url: 'https://instagram.faep7-1.fna.fbcdn.net/v/t51.2885-19/s150x150/101690632_1659267587545192_5027663977685975040_n.jpg?_nc_ht=instagram.faep7-1.fna.fbcdn.net&_nc_ohc=kedl1lDZG-EAX-hsCCU&oh=65bc250cdf5a69da07ae56658dc1398e&oe=5F39C86F'
    }

    /* //Commercial example
      //let urlprofile = 'https://graph.facebook.com/v7.0/8058452878?fields=name,profile_picture_url&access_token=' + Constants.instagramAccessToken;
    this.http.get(urlprofile)
    .subscribe(userdata => {
      //console.log('DATA EVENTS', data);
      //evento sin class
      this.userProfile = userdata;
      console.log("The Profile",this.userProfile);
     });
     */



    this.getInstaFeed();

  }


  public getInstaFeed(after = null) {
    //Completo let url = 'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,like_count,comments_count,children{media_type,media_url,caption,comments_count,like_count},comments{like_count,text,timestamp,username,replies{like_count,text,username}},timestamp,username&access_token=' + Constants.instagramAccessToken + '&count=18';
    let url = 'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,children{media_type,media_url,caption,comments_count,like_count},timestamp,username&access_token=' + Constants.instagramAccessToken + '&count=18';
    //intagram-commercial-feed es una copia de este archivo que funcionaba con una cuenta commercial. Permite ver commentarios y otras cosas que cuando se trata de una cuenta personal no se pueden ver.
    //Commercial
    //let url = 'https://graph.facebook.com/v7.0/17841400359496203/media/?fields=id,caption,media_type,media_url,thumbnail_url,like_count,comments_count,children{media_type,media_url,caption,comments_count,like_count},comments{like_count,text,timestamp,username,replies{like_count,text,username}},timestamp,username&access_token=' + Constants.instagramAccessToken + '&limit=18';
    if (after) {
      //Completo url = 'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,like_count,comments_count,children{media_type,media_url,caption,comments_count,like_count},comments{like_count,text,timestamp,username,replies{like_count,text,username}},timestamp,username&access_token=' + Constants.instagramAccessToken + '&after=' + after + '&count=18';  
      url = 'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,children{media_type,media_url,caption,comments_count,like_count},timestamp,username&access_token=' + Constants.instagramAccessToken + '&after=' + after + '&count=18';
      //Commercial
      //  url = 'https://graph.facebook.com/v7.0/17841400359496203/media/?fields=id,caption,media_type,media_url,thumbnail_url,like_count,comments_count,children{media_type,media_url,caption,comments_count,like_count},comments{like_count,text,timestamp,username,replies{like_count,text,username}},timestamp,username&access_token=' + Constants.instagramAccessToken + '&after=' + after + '&limit=18';
    }
    //console.log('DATA EVENTS', url);
    this.http.get(url)
      .subscribe(data => {
        //console.log('DATA EVENTS', data);
        //evento sin class
        this.ELEMENT_DATA = data['data'];
        this.pagination = data['paging'];


        this.ELEMENT_DATA.sort(function (a, b) {
          var keyA = new Date(a.timestamp),
            keyB = new Date(b.timestamp);
          // Compare the 2 dates
          if (keyA < keyB) { return -1; }
          if (keyA > keyB) { return 1; }
          return 0;
        });
        console.log(this.ELEMENT_DATA);
        this.events = this.events.concat(this.ELEMENT_DATA);
        // this.dataSource = this.ELEMENT_DATA;
        // this.dataSize = data['data'].length;

        // //evento con class
        // this.ELEMENT_DATA = [];
        // data['data'].array.forEach(element => {
        //   element['start'] = ''
        // });



        //    this.paging = data['paging'];
      });
    // console.log(this.postsResponse);
  }



  openInstagramDialog(events: evento[], profile: any, index: number): void {
    this.dialogRef = this.dialog.open(InstagramDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box'
    });
    this.dialogRef.componentInstance.events = events;
    this.dialogRef.componentInstance.profile = profile;
    this.dialogRef.componentInstance.i = index;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogRef = null;
    });
  }
}
