
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { GlobalVariable, Globals } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, debounceTime, take } from 'rxjs/operators';
import { MediaDialogComponent } from 'src/app/medias/media-dialog/media-dialog.component';
import { SlugPipe } from 'src/app/shared/pipes/slugPipe';

class Config {
  showdata: boolean;
  sectioncols: string;
  type: string;
  cols: string;
  backgroung: any;
  seo: any;
}


class Section {
  title: string;
  subtitle: string;
  content: string;
  media: any;
  order: number;
  status: boolean;
  private: boolean;
  items: any;
  config: any;
}

@Component({
  selector: 'app-section-dialog',
  templateUrl: './section-dialog.component.html',
  styleUrls: ['./section-dialog.component.scss']
})
export class SectionDialogComponent implements OnInit {
  sectionForm: UntypedFormGroup;
  sectionModel: Section = new Section();

  pageId: string;
  blockId: string;
  sectionId: string;

  sections: any[];
  section: any;
  items: any;

  types = GlobalVariable.components;
  panelOpenState: boolean;
  // nextSelectedTemplate: string;
  // selectedTemplate: string;
  // activeTemplate: string;
  public media: any = {
    type: null,
    value: null,
    style: 'responsive',
}
  public config: any = {
    'showdata': true,
    'colsdata':'1',
    'background': {
      type: null,
      media: null,
      position: 'responsive',
      color: 'transparent'
    },
    'type': 'card',
    'cols': '3',
    'size': 'medium',
    'width': '100',
    'align': 'left',
    'swipe': false,
    'dark': false
  };


  public pageModel;
  defaultMediaUrl = GlobalVariable.defaultMediaUrl;
  currentStatus = 1;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogMediaRef: MatDialogRef<MediaDialogComponent>;
  selectedType: any;
  previousSelectedType: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRefSection: MatDialogRef<SectionDialogComponent>

  ) { }


  ngOnInit() {
    this.setForm();
    if (this.sectionId !== '') {
      this.afs.collection('pages').doc(this.pageId).valueChanges().subscribe((x) => {
        this.pageModel = x;
      });
      if (this.pageId && this.blockId && this.sectionId) {
        this.getItemByID(this.pageId, this.blockId, this.sectionId);
        this.currentStatus = 2;
        // console.log("PageModel", this.PageModel)
      } else {
        this.currentStatus = 1;
        this.setItems();
      }
    } else {
      this.setForm();
      this.currentStatus = 1;
    }

  }

  get formData() { return <UntypedFormArray>this.sectionForm.get('items'); }

  setForm() {
    this.sectionForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      subtitle: ['', []],
      content: ['', []],
      media: this.formBuilder.group({
        type: [null, []],
        value: [null, []],
        style: [null, []],
      }),
      order: ['', []],
      status: [true, []],
      private: [false, []],
      items: this.formBuilder.array([]),
      config: this.formBuilder.group({
        background: this.formBuilder.group({
          type: [null, []],
          media: [null, []],
          style: ['responsive', []],
          color: [null, []],
        }),
        showdata: [false, []],
        colsdata: ['1', []],
        type: ['card', []],
        cols: ['3', []],
        size: ['small', []],
        width: ['100', []],
        align: ['left', []],
        swipe: ['false', []],
        dark: ['false', []],
      }),
      options: this.formBuilder.array([]) // create empty form array   
    })
  }

  getItemByID(pageId: string, blockId: string, sectionId: string) {
    // this.item 
    
    const x = this.afs.collection('pages').doc(pageId).collection('blocks').doc(blockId).collection('sections').doc(sectionId).snapshotChanges();
    x.subscribe(section => {
      const data = section.payload.data() as Section;
      this.sectionModel = data as Section;
      console.log('ITEMS', this.sectionModel);
      this.items = this.sectionModel.items;
      this.config = this.sectionModel.config;
      this.sectionForm = this.formBuilder.group({
        title: [this.sectionModel.title, [Validators.required]],
        subtitle: [this.sectionModel.subtitle, []],
        content: [this.sectionModel.content, []],
        media: this.formBuilder.group({
          type: [this.sectionModel.media.type, []],
          value: [this.sectionModel.media.value, []],
          style: [this.sectionModel.media.style, []],
        }),
        order: [this.sectionModel.order, []],
        status: [this.sectionModel.status, []],
        private: [this.sectionModel.private, []],
        items: this.formBuilder.array([]),
        config: this.formBuilder.group({
          background: this.formBuilder.group({
            type: [this.sectionModel.config.background.type, []],
            media: [this.sectionModel.config.background.media, []],
            style: [this.sectionModel.config.background.style, []],
            color: [this.sectionModel.config.background.color, []],
          }),
          showdata: [this.sectionModel.config.showdata, []],
          colsdata: [this.sectionModel.config.colsdata, []],
          cols: [this.sectionModel.config.cols, []],
          type: [this.sectionModel.config.type, []],
          size: [this.sectionModel.config.size, []],
          width: [this.sectionModel.config.width, []],
          align: [this.sectionModel.config.align, []],
          swipe: [this.sectionModel.config.swipe, []],
          dark: [this.sectionModel.config.dark, []],
        })
      });
      this.setItems();
    });
   
  }


  setItems() {
    const control = <UntypedFormArray>this.sectionForm.controls.items;
   // if (this.items > 0) {
      this.items.forEach(x => {
        control.push(this.formBuilder.group({
          title: x.title,
          subtitle: x.subtitle,
          content: x.content,
          media: this.formBuilder.group({
            type: x.media.type,
            value: x.media.value,
            style: x.media.style,
          }),
          link: x.link,
          target: x.target,
          showbutton: x.showbutton,
          subItems: this.setSubItems(x)
        }));
      });
  }
  setSubItems(x) {
    const arr = new UntypedFormArray([]);
    if (x.subItems) {
      x.subItems.forEach(y => {
        arr.push(this.formBuilder.group({
          key: y.key,
          value: y.value,

        }));
      });
    }
    return arr;
  }
  setConfig() {
    console.log('ITEMS', this.sectionModel.config);
    console.log('SETITEMS', this.sectionForm.controls.config);
    const control = <UntypedFormArray>this.sectionForm.controls.config;
    control.push(this.formBuilder.group({
      title: this.config.seo.title,
      content: this.config.seo.content,
      media: this.config.seo.media,
      noindex: this.config.seo.noindex
    })
    );
  }
  addNewItem() {
    const control = <UntypedFormArray>this.sectionForm.controls.items;
    control.push(
      this.formBuilder.group({
        title: [''],
        subtitle: [''],
        content: [''],
        media: this.formBuilder.group({
          type: null,
          value: null,
          style: null,
        }),
        link: [''],
       
        showbutton: [''],
        target: [''],
        subItems: this.formBuilder.array([])
      })
    )
  }
  deleteItem(index) {
    const control = <UntypedFormArray>this.sectionForm.controls.items;
    control.removeAt(index)
  }

  addNewSubItem(control) {
    control.push(
      this.formBuilder.group({
        key: null,
        value: null,
      }));
  }

  deleteSubItem(control, index) {
    control.removeAt(index);
  }




  changeSectionType(event: MatRadioChange): void {
    console.log('changeQuestionType');

    if (this.sections) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = '¿Estas seguro en cambiar el tipo de seccion?';
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedType = event.value;
          this.previousSelectedType = this.selectedType;
          // do confirmation actions

        } else {
          this.selectedType = this.previousSelectedType;
          console.log(this.selectedType);
          return false;
        }
        this.dialogRef = null;
      });
    }
  }

  onSubmit() {
    // console.log(`Image: ${this.media}`);
    // console.log(`Image: ${ JSON.stringify(f.value)}`);
    console.log('Form:', this.sectionForm);
    if (this.sectionForm.valid) {
      if (this.sectionId === '') {
        console.log('Creando un nuevo documento!');
        const idBefore = this.afs.createId();
        const data = {
          pageId: this.pageId,
          id: idBefore,
          // config: this.config,
          title: this.sectionForm.get('title').value,
          subtitle: this.sectionForm.get('subtitle').value,
          content: this.sectionForm.get('content').value,
          media: this.sectionForm.get('media').value,
          // tags: this.pollForm.get('tags').value,
          order: this.sectionForm.get('order').value,
          status: this.sectionForm.get('status').value,
          private: this.sectionForm.get('private').value,
          items: this.sectionForm.get('items').value,
          config: this.sectionForm.get('config').value,
          createdOn: new Date(),
          updatedOn: new Date(),
          // level: this.pollForm.get('level').value,
        };
        this.afs.collection('pages').doc(this.pageId).collection('blocks').doc(this.blockId).collection('sections').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefSection.close();

        }, (error) => {
          console.log(error);
        });

      } else {
        console.log('Editando el documento');
        const data = {
          // config: this.config,
          title: this.sectionForm.get('title').value,
          subtitle: this.sectionForm.get('subtitle').value,
          content: this.sectionForm.get('content').value,
          media: this.sectionForm.get('media').value,
          // tags: this.pollForm.get('tags').value,
          order: this.sectionForm.get('order').value,
          status: this.sectionForm.get('status').value,
          private: this.sectionForm.get('private').value,
          items: this.sectionForm.get('items').value,
          config: this.sectionForm.get('config').value,
          updatedOn: new Date(),
        };
        this.afs.collection('pages').doc(this.pageId).collection('blocks').doc(this.blockId).collection('sections').doc(this.sectionId).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefSection.close();

        }, (error) => {
          console.log(error);
        });
      }
    } else {
      console.log("El Formulario no es valido");
    }
  }

  openMediaDialog(e, field, group = null, subgroup = null, subsubgroup = null): void {
    var selectedMediaId = null;
    var selectedMediaUrl = null;
    //if (this.itemId) {
    this.dialogMediaRef = this.dialog.open(MediaDialogComponent, {
      disableClose: false
    });
    this.dialogMediaRef.componentInstance.field = field;
    this.dialogMediaRef.componentInstance.confirmMessage = "";
    const sub = this.dialogMediaRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
      //console.log(data.id)
      selectedMediaId = data.id;
      selectedMediaUrl = data.url
    });
    this.dialogMediaRef.afterClosed().subscribe(data => {
      if (data) {
        //  debugger
        if (selectedMediaId) {
          //this.media = selectedMediaId;
          if (e.target.parentNode.previousSibling.firstChild.firstChild) {
            var m = e.target.parentNode.previousSibling.firstChild.firstChild;
            var n = e.target.parentNode.nextSibling;
            e.target.nextSibling.disabled = false;
          }
          //n.setValue(selectedMediaId);
          m.src = selectedMediaUrl;
          n.value = selectedMediaId;

          if (group != null) {
            // if(subgroup != null){
            this.getFormGroup(group, subgroup, subsubgroup).controls[field].setValue(selectedMediaId);

            //}else{
            //this.getFormGroup(group).controls[field].setValue(selectedMediaId);

            //}

          } else {
            this.sectionForm.controls[field].setValue(selectedMediaId);
          }

          //n.valueChanges();
          // n.controls[field].setValue(selectedMediaId);
          //setValue = ;
        }
        sub.unsubscribe();
        // var query = this.afs.collection('pages').doc(this.pageId).collection('items').ref.where('pageId', '==', this.pageId);
        // query.get().then(function (querySnapshot) {
        //   querySnapshot.forEach(function (doc) {
        //     doc.ref.delete();
        //   });
        // });
      } else {
        //this.nextSelectedTemplate = this.selectedTemplate;
        return false;
      }
      this.dialogMediaRef = null;
    });
    //}
  }


  getMediaValue(field, group = null, subgroup = null, subsubgroup = null) {
    // console.log('Group', group);
    // console.log('Subgroup', subgroup);
    // console.log('Field', field);

    //console.log(this.getItemFormGroup(index).controls[field].value)
    if (this.getFormGroup(group, subgroup, subsubgroup).controls[field].value !== '' || this.getFormGroup(group, subgroup, subsubgroup).controls[field].value) {
      return this.getFormGroup(group, subgroup, subsubgroup).controls[field].value;
    } else {
      return GlobalVariable.defaultMediaUrl;
    }
  }
  getFormGroup(group, subgroup = null, subsubgroup = null): UntypedFormGroup {
    if (subsubgroup) {
      const groupA = this.sectionForm.get(group) as UntypedFormArray;
      const subGroup = groupA.controls[subgroup] as UntypedFormArray;
      const formGroup = subGroup.controls[subsubgroup] as UntypedFormGroup;
      return formGroup;

    } else {


      if (subgroup) {
        const groupA = this.sectionForm.get(group) as UntypedFormArray;
        const formGroup = groupA.controls[subgroup] as UntypedFormGroup;
        return formGroup;
      } else {
        const formGroup = this.sectionForm.get(group) as UntypedFormGroup;
        return formGroup;
      }
    }

  }

  cleanMediaField(e, field, group = null, subgroup = null, subsubgroup = null): void {
    if (e.target.parentNode.previousSibling) {
       console.log("e", e);
       console.log("field", field);
       console.log("group", group);
       console.log('subgroup', subgroup);
       console.log("M",e.target.parentNode.previousSibling.firstChild.getElementsByTagName('img')[0]);
       console.log('nextSibling',e.target.parentNode.nextSibling);
      var m = e.target.parentNode.previousSibling.firstChild.getElementsByTagName('img')[0];
      var n = e.target.parentNode.nextSibling;
      e.target.disabled = true;
      m.src = GlobalVariable.defaultMediaUrl;
    }
    m.src = GlobalVariable.defaultMediaUrl;
    n.value = ""

    if (subgroup != null) {
      this.getFormGroup(group, subgroup, subsubgroup).controls[field].reset("");
    } else {
      this.sectionForm.controls[field].reset("");

    }
  }

  slug(input: string) {
    var slugPipe = new SlugPipe();
    return slugPipe.transform(input);
  }
  // Unique Key
  static slug(afs: AngularFirestore, slug: string = null) {
    return (control: AbstractControl) => {
      const slug = control.value;
      return afs.collection('pages', ref => ref.where('slug', '==', slug))
        .valueChanges().pipe(
          debounceTime(500),
          take(1),
          map(arr => {
            if (arr.length) {
              if (slug) {
                if (arr[0]['slug'] === slug) {
                  return null;
                } else {
                  return { codeAvailable: false };
                }
              } else {
                return { codeAvailable: false }
              }
            } else {
              return null;
            }
          }
          )
        );
    }
  }

}


