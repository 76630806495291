import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { MaterialModule } from "../shared/material.module";
import { UsersRoutingModule } from './users-routing.module';
import { UserAccountComponent } from './user-account/user-account.component';
import { UsersComponent } from './users.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LayoutModule } from '@angular/cdk/layout';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { DataTableComponent } from './data-table/data-table.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    UsersRoutingModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  declarations: [UserAccountComponent, UsersComponent, UserDashboardComponent, DataTableComponent]
})
export class UsersModule { }
