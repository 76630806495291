// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-button:focus {
  border: 2px solid #000;
}

::ng-deep mat-grid-tile.mat-grid-tile.selected {
  z-index: 100;
  border: 2px solid blueviolet;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lZGlhcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHNCQUFBO0FBQ0o7O0FBQ0E7RUFDSSxZQUFBO0VBQ0EsNEJBQUE7RUFDQSw4Q0FBQTtBQUVKIiwiZmlsZSI6Im1lZGlhcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tZWRpYS1idXR0b246Zm9jdXN7XG4gICAgYm9yZGVyOjJweCBzb2xpZCAjMDAwOyAgIFxufVxuOjpuZy1kZWVwIG1hdC1ncmlkLXRpbGUubWF0LWdyaWQtdGlsZS5zZWxlY3RlZCB7XG4gICAgei1pbmRleDogMTAwO1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIGJsdWV2aW9sZXQ7XG4gICAgYm94LXNoYWRvdzogMHB4IDBweCA1cHggNXB4IHJnYmEoMCwwLDAsMC41KTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/medias/medias/medias.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,YAAA;EACA,4BAAA;EACA,8CAAA;AAEJ;AACA,4kBAA4kB","sourcesContent":[".media-button:focus{\n    border:2px solid #000;   \n}\n::ng-deep mat-grid-tile.mat-grid-tile.selected {\n    z-index: 100;\n    border: 2px solid blueviolet;\n    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.5);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
