import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/startWith';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import { trigger, transition, query, stagger, animateChild, style, animate, state } from '@angular/animations';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { NavigationService } from 'src/app/services/navigation.service';
declare var $: any;

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  animations: [
     trigger('cardAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(20px)' }))
      ])
    ]),
    trigger('itemCard', [
      
    ]),
    trigger('cards', [
      
    ]),
  ],
 
  /*animations: [
    trigger('cards', [

      transition(':enter', [
        query(':leave', [
          style({ left: -100 }),
          stagger(200, [
            animate('0.5s', style({ left: 30 }))
          ])
        ], { optional: true }),
        query(':enter', [
          stagger(200, [
            animate('0.5s', style({ left: 100 }))
          ])
        ], { optional: true }),

        query('.itemCard', stagger(300, animateChild()), { optional: true }),

      ]),
    ]),
    trigger('itemCard', [
      transition(':enter', [
        style({ transform: 'scale(0.9)', opacity: 0.3 }),  // initial
        animate('0.5s 1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),


      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('0.5s 1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.9)',
            opacity: 0,
            margin: '0px'
          }))
      ])

    ]),
  ],*/
})
export class CardsComponent implements  OnInit, OnDestroy {
  animate = true;


  public box: Observable<number>;
  public sizeDisplay: string = 'XSmall' || 'Small' || 'Medium' || 'Large' || 'XLarge';
  
  @Input() itemsArray: [];
  @Input() cols: any;
  @Input() columns: any;
  @Input() swipe: boolean;


  rowHeight: any;
  rowWidth: any;
  options: any;


  watcher: any;
  activeMediaQuery: string;
  constructor(
    private mediaObserver: MediaObserver,
    private viewportRuler: ViewportRuler,
    private navigationService: NavigationService,
    public breakpointObserver: BreakpointObserver
    
  ) {
    this.mediaQuery();
  }



  Breakpoints = Breakpoints;
  currentBreakpoint:string = '';
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  ngOnInit() {
    console.log("CARD INIT", this.cols);
    const previousUrl = this.navigationService.getPreviousUrl();
    const currentUrl = this.navigationService.currentUrl.value;

    // Activa la animación solo si el componente no está en ambas rutas
    //this.animate = ![previousUrl, currentUrl].includes('/forestillinger');


    this.breakpoint$.subscribe(() =>
      this.mediaQuery()
    );
  }

  ngOnDestroy(): void {
    //  this.mediaObserver.asObservable().unsubscribe()
  }
  public mediaQuery() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          if (state.breakpoints[Breakpoints.XSmall]) {
            this.sizeDisplay = 'XSmall';
            this.columns = (this.cols > 3) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Small]) {
            this.sizeDisplay = 'Small';
            this.columns = (this.cols > 2) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Medium]) {
            this.sizeDisplay = 'Medium';
            this.columns = (this.cols > 2) ? this.cols : (this.cols === 2) ? 2 : 1;
          } else if (state.breakpoints[Breakpoints.Large]) {
            this.sizeDisplay = 'Large';
            this.columns = this.cols;
          } else if (state.breakpoints[Breakpoints.XLarge]) {
            this.sizeDisplay = 'XLarge';
            this.columns = this.cols;
          }
          if (this.swipe) {
            this.rowWidth = 100;
          } else {
            this.rowWidth = 100 / this.columns;
          }
        }
      });
  }

 
  /**
    * The number of colums in the mat-grid-list directive.
    */



  heightToCols(cols: number): number {
    const windowWidth = this.viewportRuler.getViewportSize().width;
    const cardWidth = windowWidth / cols;
    const cardAspectRatio = 1.5; // Ajustar según la relación de aspecto real de las tarjetas
    return cardWidth * cardAspectRatio;
  }
}
