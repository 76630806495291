import { Component, OnDestroy, OnInit, Sanitizer, SimpleChanges } from '@angular/core';

import { ActivatedRoute, RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators'
import { Page } from '../page';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MediasService } from 'src/app/medias/medias.service';
import { Media } from 'src/app/medias/media';
import { GlobalVariable } from 'src/app/shared/globals';
import { trigger, state, style, transition, animate, query, animateChild } from '@angular/animations';
import { fader } from '../../shared/animations/route-animations';
import { fadeAnimation } from '../../shared/animations/fadeIntRoute';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';




@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  animations: [
    trigger('fadeAnimation', []) // Transition removed
  ]
  /*animations: [
    trigger('fadeAnimation', [
      //transition(':enter, :leave', [
      //  query('@*', animateChild(), { optional: true })
      //]),
      // state('in', style({ 'opacity': '1' })),
      //state('out',style({ 'opacity': '0' })),
      transition('* => in', [
        query('.app-menu', [
          style({ transform: 'scale(1)', opacity: 1, height: '*' }),
          animate('0.5s 5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({
              transform: 'scale(0.5)',
              opacity: 1,
              margin: '0px'
            })
          )
        ], { optional: true }),


        // 
        //         query('.section', [
        //           style({ opacity: 0 }),
        //           animate('0.5s 0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        //             style({ opacity: 1 })
        //           )
        //         ], { optional: true }),
        
        //         query('.background', [
        //           style({ opacity: 0 }),
        //           animate('1s 0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        //             style({ opacity: 1 })
        //           )
        //         ], { optional: true }),


        animate('1000ms ease-in')]),



      transition('* => out', [
        query('.itemCard', [
          style({ transform: 'scale(1)', opacity: 1, height: '*' }),
          animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({
              transform: 'scale(0.5)',
              opacity: 0,
              margin: '0px'
            })
          )
        ], { optional: true }),

        // query('.section', [
        //   style({ opacity: 1 }),
        //   animate('0.5s 0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        //     style({ opacity: 1 })
        //   )
        // ], { optional: true }),

        query('.background', [
          style({ opacity: 1 }),
          animate('1s 0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({ opacity: 0 })
          )
        ], { optional: true }),


        // query('@*', animateChild(), { optional: true }),
        animate('500ms ease-out')
      ])
    ]),
  ]*/
})
export class PageComponent implements OnInit, OnDestroy {
  page: Page;
  blocks: any;
  listItems: any;
  galleries: any;
  background: SafeStyle;
  state = 'in';
  counter = 0;
  slug: any;
  enableAnimation: any;

  public sizeDisplay: string = 'phone' || 'web';
  loadingGalleries = true;
  // Subject para gestionar el ciclo de vida de las suscripciones
  private unsubscribe$ = new Subject<void>();

  constructor(
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private mediasService: MediasService,
    public breakpointObserver: BreakpointObserver

  ) {
    this.mediaQuery();
    // override the route reuse strategy
    /*this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });*/
  }
  public mediaQuery() {

    this.breakpointObserver
      .observe(Breakpoints.Small)
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //AQUI SERA TRUE SOLO SI ESTA EN RESOLUCION DE CELULAR
          this.sizeDisplay = 'phone';
        }
      });
    this.breakpointObserver
      .observe(Breakpoints.Web)
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //AQUI SERA TRUE SOLO SI ES RESOLUCION PARA WEB
          this.sizeDisplay = 'web';
        }
      });
  }


  ngOnInit() {
    console.log("PAGE INIT");
    const sub = this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // (+) converts string 'id' to a number
      this.slug = params['slug'];
      // (+) converts string 'id' to a number
      if (params['slug']) {
        console.log("PAGE SLUG");
        this.enableAnimation = true;
        this.counter = 0;
        this.togglePage();
        /* setTimeout(() => {
           this.togglePage();
         }, 3000);
          */
      } else {
        if (params['id']) {
          this.getPageById(id);
        } else {
          this.getPageById(GlobalVariable.homePage);
        }
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  togglePage() {
    if (this.counter < 2) {
      this.state = this.state === 'in' ? 'out' : 'in';
      console.log("ANIMATION PAGE STATE", this.state);
      this.counter++;
    }



  }
  onDone($event) {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        this.page = null;
        this.blocks = null;
        this.listItems = null;
        this.galleries = null;
        console.log("Cargando la pagina nueva");
        this.getPageBySlug(this.slug);


      }
    }
    this.togglePage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("PAGE CHANGE");

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }

  getPageById(pageId: string) {
    setTimeout(() => {
      const x = this.afs.collection('pages').doc(pageId).snapshotChanges();
      x.subscribe(pages => {
        const pagedata = pages.payload.data() as Page;
        pagedata['id'] = pageId;
        this.page = pagedata;
        console.log('Data', pagedata);

      });
      const w = this.afs.collection('pages').doc(pageId).collection('blocks', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      w.subscribe(blocksa => {
        this.blocks = [];
        const blocks = [];
        blocksa.forEach(block => {
          const x = this.afs.collection('pages').doc(pageId).collection('blocks').doc(block.payload.doc.id).collection('sections', ref => ref.orderBy('order', 'asc')).snapshotChanges();
          x.subscribe(sections => {
            if (sections.length > 0) {
              const items = [];
              sections.forEach(section => {
                items.push({
                  id: section.payload.doc.id,
                  data: section.payload.doc.data()
                })
                if (blocks.some(e => e.id === block.payload.doc.id)) {
                  const blockIndex = blocks.findIndex(e => e.id === block.payload.doc.id);
                  if (items.some(e => e.id === section.payload.doc.id)) {
                    const itemIndex = items.findIndex(item => item.id === section.payload.doc.id);
                    // console.log('blockIndex', blockIndex);
                    // console.log('itemIndex', itemIndex);
                    // console.log('block', blocks[blockIndex]);
                    // console.log('section', items[itemIndex]);
                    // console.log("blocks", this.blocks);
                    // reemplazar del block section el item y ingresar item index como valor
                    if (blocks[blockIndex].sections) {
                      blocks[blockIndex].sections[itemIndex] = items[itemIndex]
                    } else {
                      blocks[blockIndex].sections = [];
                      blocks[blockIndex].sections[itemIndex] = items[itemIndex]
                    }
                    // items[itemIndex] = section.payload.doc.data();
                    this.blocks = blocks;
                  } else {
                  }
                } else {
                  blocks.push({
                    id: block.payload.doc.id,
                    data: block.payload.doc.data(),
                    sections: items
                  });
                  this.blocks = blocks;
                }
                this.blocks = blocks;
              });
            } else {
              if (!blocks.some(e => e.id === block.payload.doc.id)) {
                blocks.push({
                  id: block.payload.doc.id,
                  data: block.payload.doc.data(),
                });
              }
            }
          });
          this.blocks = blocks;
        });
        this.blocks = blocks;
      });


      this.listItems = this.afs.collection('pages').doc(pageId).collection('items', ref => ref.orderBy('order', 'asc')).valueChanges();
      // this.sections = this.afs.collection('pages').doc(pageId).collection('sections', ref => ref.orderBy('order', 'asc')).valueChanges()
      this.loadingGalleries = true; // Activa el estado de carga
      console.log("LOADING GALLERIES TRUE 1")
      // this.galleries = this.afs.collection('pages').doc(this.page.id).collection('galleries', ref => ref.orderBy('order', 'asc')).valueChanges()
      //   .subscribe((galleries: any) => {
      //     this.galleries = galleries;
      //     this.loadingGalleries = false; // Desactiva el estado de carga cuando se completó la carga de datos
      //   });
      //  this.galleries = this.afs.collection('pages').doc(this.page.id).collection(
      //    'galleries', ref => ref.orderBy('order', 'asc')).valueChanges();



      this.galleries = this.afs.collection('pages').doc(pageId).collection('galleries', ref => ref.orderBy('order', 'asc')).valueChanges();
    }, 3000);


  }

  getPageBySlug2(slug: string) {
    console.log("Slug", slug);

    const pageObservable = this.afs.collection('pages', ref => ref.where('slug', '==', slug)).snapshotChanges();
    const blocksObservable = this.afs.collection('pages').doc(this.page.id).collection('blocks', ref => ref.orderBy('order', 'asc')).snapshotChanges();
    const itemsObservable = this.afs.collection('pages').doc(this.page.id).collection('items', ref => ref.orderBy('order', 'asc')).valueChanges();
    const galleriesObservable = this.afs.collection('pages').doc(this.page.id).collection('galleries', ref => ref.orderBy('order', 'asc')).valueChanges();

    combineLatest([
      pageObservable.pipe(map(snapshots => snapshots[0])), // Get the first element
      blocksObservable,
      itemsObservable,
      galleriesObservable
    ])
      .subscribe(([pageSnapshot, blocks, items, galleries]) => {
        // Access the document data from the first element of pageSnapshot
        const pageData = pageSnapshot.payload.doc.data() as Page;
        this.page = pageData;

        // Process blocks and sections (assuming you have a separate function)
        this.blocks = this.processBlocksAndSections(blocks);

        this.listItems = items;
        this.galleries = galleries;

        this.loadingGalleries = false; // Desactiva el estado de carga
        console.log("LOADING GALLERIES TRUE 2");
      });
  }


  private processBlocksAndSections(blocksSnapshot: any[]): any[] {
    const blocks = [];
    blocksSnapshot.forEach(block => {
      const blockData = block.payload.doc.data();
      const sections = [];

      // Obtener las secciones del bloque
      blockData.sections.forEach(section => {
        sections.push({
          id: section.id,
          data: section.data
        });
      });

      blocks.push({
        id: block.payload.doc.id,
        data: blockData,
        sections: sections
      });
    });
    return blocks;
  }





  getPageBySlug(slug: string) {
    console.log("Slug", slug);

    this.afs.collection('pages', ref => ref.where('slug', '==', slug)).get()
      .subscribe(querySnapshot => {
        if (!querySnapshot.empty) {
          const pageDoc = querySnapshot.docs[0];
          const pageData = pageDoc.data() as Page;
          this.page = pageData;
          const w = this.afs.collection('pages').doc(this.page.id).collection('blocks', ref => ref.orderBy('order', 'asc')).snapshotChanges();
          w.subscribe(blocksa => {
            this.blocks = [];
            const blocks = [];
            blocksa.forEach(block => {
              // tslint:disable-next-line: max-line-length
              const x = this.afs.collection('pages').doc(this.page.id).collection('blocks').doc(block.payload.doc.id).collection('sections', ref => ref.orderBy('order', 'asc')).snapshotChanges();
              x.subscribe(sections => {
                if (sections.length > 0) {
                  const items = [];
                  sections.forEach(section => {
                    items.push({
                      id: section.payload.doc.id,
                      data: section.payload.doc.data()
                    })
                    if (blocks.some(e => e.id === block.payload.doc.id)) {
                      const blockIndex = blocks.findIndex(e => e.id === block.payload.doc.id);
                      if (items.some(e => e.id === section.payload.doc.id)) {
                        const itemIndex = items.findIndex(item => item.id === section.payload.doc.id);
                        // console.log('blockIndex', blockIndex);
                        // console.log('itemIndex', itemIndex);
                        // console.log('block', blocks[blockIndex]);
                        // console.log('section', items[itemIndex]);
                        // console.log("blocks", this.blocks);
                        // reemplazar del block section el item y ingresar item index como valor
                        if (blocks[blockIndex].sections) {
                          blocks[blockIndex].sections[itemIndex] = items[itemIndex];
                        } else {
                          blocks[blockIndex].sections = [];
                          blocks[blockIndex].sections[itemIndex] = items[itemIndex];
                        }
                        // items[itemIndex] = section.payload.doc.data();
                        this.blocks = blocks;
                      } else {
                      }
                    } else {
                      blocks.push({
                        id: block.payload.doc.id,
                        data: block.payload.doc.data(),
                        sections: items
                      });
                      this.blocks = blocks;
                    }
                    this.blocks = blocks;
                  });
                } else {
                  if (!blocks.some(e => e.id === block.payload.doc.id)) {
                    blocks.push({
                      id: block.payload.doc.id,
                      data: block.payload.doc.data(),
                    });
                  };
                }
              });
              this.blocks = blocks;
            });
            this.blocks = blocks;
          });

          this.listItems = this.afs.collection('pages').doc(this.page.id).collection(
            'items', ref => ref.orderBy('order', 'asc')).valueChanges();
          // this.sections = this.afs.collection('pages').doc(this.page.id).collection('sections', ref => ref.orderBy('order', 'asc')).valueChanges()

          this.loadingGalleries = true; // Activa el estado de carga
          console.log("LOADING GALLERIES TRUE 2")

          // this.galleries = this.afs.collection('pages').doc(this.page.id).collection('galleries', ref => ref.orderBy('order', 'asc')).valueChanges()
          //   .subscribe((galleries: any) => {
          //     this.galleries = galleries;
          //     this.loadingGalleries = false; // Desactiva el estado de carga cuando se completó la carga de datos
          //   });
          this.galleries = this.afs.collection('pages').doc(this.page.id).collection(
            'galleries', ref => ref.orderBy('order', 'asc')).valueChanges();



          return false;
        } else {
          // Manejar caso en el que no se encuentra ningún documento con el slug dado
          console.log('No se encontró ningún documento con el slug proporcionado.');
        }

      });

}

createImageFromBlob(image: Blob) {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    this.background = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
  }
}

getImageFromService(yourImageUrl) {
  // this.isImageLoading = true;
  /* this.mediasService.getImage(yourImageUrl).subscribe(data => {
     this.createImageFromBlob(data);
     //this.isImageLoading = false;
   }, error => {
     //this.isImageLoading = false;
     console.log(error);
   });
   */
}

getMediaUrl(id: string) {
  this.mediasService.getMedia(id).subscribe(data => {
    const media = data.payload.data() as Media;
    // console.log("getMedia", media);
    if (media) {
      if (media.url == null || media.url === '') {
        return null;
      } else {
        if (media.type === 'image') {
        }
        return this.sanitizer.bypassSecurityTrustStyle(`${media.url}`);
      }
    } else {
      return null;
    }
  });
}


}
