// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-card {
  color: #616162;
}

.mat-card .mat-display-1 {
  color: #616162;
  margin-bottom: 30px;
}

/* 2. Then use it like this in you component scss */
/*mat-icon {
    @include md-icon-size(64px);
  }
*/
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbnRhY3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0FBQ0o7O0FBQ0E7RUFDSSxjQUFBO0VBQ0EsbUJBQUE7QUFFSjs7QUFDQSxtREFBQTtBQUNBOzs7Q0FBQSIsImZpbGUiOiJjb250YWN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hdC1jYXJke1xuICAgIGNvbG9yOiM2MTYxNjI7XG59XG4ubWF0LWNhcmQgLm1hdC1kaXNwbGF5LTF7XG4gICAgY29sb3I6ICM2MTYxNjI7XG4gICAgbWFyZ2luLWJvdHRvbTogMzBweDtcbn1cblxuLyogMi4gVGhlbiB1c2UgaXQgbGlrZSB0aGlzIGluIHlvdSBjb21wb25lbnQgc2NzcyAqL1xuLyptYXQtaWNvbiB7XG4gICAgQGluY2x1ZGUgbWQtaWNvbi1zaXplKDY0cHgpO1xuICB9XG4qLyJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/contact/contact.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AACA;EACI,cAAA;EACA,mBAAA;AAEJ;;AACA,mDAAA;AACA;;;CAAA;AAKA,gnBAAgnB","sourcesContent":[".mat-card{\n    color:#616162;\n}\n.mat-card .mat-display-1{\n    color: #616162;\n    margin-bottom: 30px;\n}\n\n/* 2. Then use it like this in you component scss */\n/*mat-icon {\n    @include md-icon-size(64px);\n  }\n*/"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
