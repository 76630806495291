import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/core/auth.service';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { leftJoin } from 'src/app/services/firestore.service';
import { User } from 'src/app/core/user';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DatePipe, Time } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

export interface Evento {
  id: string;
  title?: string;
  subtitle?: string;
  location?: any;
  content?: Text;
  excerpt?: Text;
  media?: string;
  start?: Date;
  startTime?: Time;
  year?: Date;
  end?: Date;
  endTime?: Time;
  tickets: string;
  status: boolean;
  tags: any;
  createdOn: Date;
  updatedOn: Date; // program?: any;
}
@Component({
  selector: 'app-events-admin',
  templateUrl: './events-admin.component.html',
  styleUrls: ['./events-admin.component.scss']
})

export class EventsAdminComponent implements OnInit {
  events$: Observable<{}>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  user: any;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  displayedColumns: Array<string> = ['media', 'title', 'subtitle', 'location', 'fecha', 'horario', 'actions'];
  dataSource: MatTableDataSource<any>;
  eventsa = [];
  //  dataSource= new MatTableDataSource();
  @ViewChild('sort') sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    let sub = this.activatedRoute.params.subscribe(params => {
      const id = params["id"]; // (+) converts string 'id' to a number
      if (params["id"]) { //poll -> Id
        this.getEvents();
      }
    });
    this.getEvents();
    this.authService.user$.subscribe(user => this.user = user);
    // this.events$ = this.getEventsList(this.startAt);
  }

  public getEvents() {
    this.afs.collection('events').ref.orderBy('start', 'desc').get().then((snapshot) => {
      snapshot.forEach((Data: any) => {
        var evento = Data.data() as Evento;
        console.log("Evento", evento);
        const eventRow = [];
        // participation["id"] = participationData.id;
        //var datePipe = new DatePipe('en-US');
        //const datePart = datePipe.transform(event.date.toDate(), 'dd/MM/yyyy')
        //const dateBirthday = datePipe.transform(user.birthday.toDate(), 'dd/MM/yyyy')
        //reportRow['uid'] = participation.uid;
        eventRow['id'] = evento.id
        eventRow['title'] = evento.title
        eventRow['subtitle'] = evento.subtitle;
        eventRow['excerpt'] = evento.excerpt;
        eventRow['start'] = evento.start;
        eventRow['end'] = evento.end;
        eventRow['startTime'] = evento.startTime;
        eventRow['endTime'] = evento.endTime;

        eventRow['location'] = evento.location;
        eventRow['media'] = evento.media;
        eventRow['status'] = evento.status;
        // eventRow['state'] = event.state;
        // eventRow['date'] = datePart;
        //reportRow['score'] = participation.score;
        // eventRow['attempt'] = participation.attempt;
        //reportRow['ellapsedTime'] = participation.ellapsedTime;
        this.eventsa.push(eventRow);
        //eventRow['id'] = participation.componentId + "_" + participation.uid + "_" + participation.attempt;
        /* if (this.displayedColumns.indexOf(evento.title) > -1) {
         } else {
           console.log("displayedColumns", evento.title)
           this.displayedColumns.push(evento.title)
         
                   
       
       
       }*/









        //  this.events.push(eventRow);

      });


      const mapped1 = Object.create(this.eventsa);
      console.log("mapped1", mapped1);
      this.dataSource = new MatTableDataSource(mapped1);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  search(searchText) {
    this.startAt.next(searchText);
  }
  
  // Listado para buscar en el admin
  getEventsList(start: BehaviorSubject<string>): Observable<{}> {
    return start.pipe(
        switchMap(startText => {
      const endText = startText + '\uf8ff';
      return this.afs
        .collection('events', ref =>
          ref.orderBy("start")
            .limit(300)
            .startAt(startText)
            .endAt(endText)
        )
        .snapshotChanges().pipe(
          debounceTime(200),
          distinctUntilChanged(),
          map(changes => {
            return changes.map(c => {
              //console.log(c);
              const data = c.payload.doc.data();
              const id = c.payload.doc.id;
              return { id, data };
            });
          }),
          leftJoin(this.afs, 'id', 'events')
        );
    })
    );
  };

  public deleteEvent(row: any) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = "Seguro deseas borrar esta encuesta?<br />Todos los registros y estadisticas relacionadas con esta encuesta seran tambien eliminados"
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.afs.collection('events').doc(row.id).delete();
        this.eventsa = this.eventsa.filter(obj => obj !== row);
        const mapped1 = Object.create(this.eventsa);
        console.log("mapped1", mapped1);
        this.dataSource = new MatTableDataSource(mapped1);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.dialogRef = null;

    });
  }





  public toggleStatus(id, status) {
    this.afs.doc('/events/' + id)
      .update({ status: !status });
  }
  public toggleHighlight(id, highlight) {
    this.afs.doc('/events/' + id)
      .update({ highlight: !highlight });
  }
}







/* Events Admin no paggin
import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/core/auth.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { leftJoin } from 'src/app/services/firestore.service';
import { User } from 'src/app/core/user';

@Component({
  selector: 'app-events-admin',
  templateUrl: './events-admin.component.html',
  styleUrls: ['./events-admin.component.scss']
})
export class EventsAdminComponent implements OnInit {
  events$: Observable<{}>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  user: any;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.authService.user$.subscribe(user => this.user = user);
    this.events$ = this.getEventsList(this.startAt);
  }
  search(searchText) {
    this.startAt.next(searchText);
  }

    // Listado para buscar en el admin
    getEventsList(start: BehaviorSubject<string>): Observable<{}> {
      return start
        .switchMap(startText => {
          const endText = startText + '\uf8ff';
          return this.afs
            .collection('events', ref =>
              ref.orderBy("start")
                 .limit(300)
                .startAt(startText)
               .endAt(endText)
            )
            .snapshotChanges().pipe(
              debounceTime(200),
              distinctUntilChanged(),
              map(changes => {
                return changes.map(c => {
                  //console.log(c);
                  const data = c.payload.doc.data();
                  const id = c.payload.doc.id;
                  return { id, ...data };
                });
              }),
              leftJoin(this.afs, 'id', 'events')
            );
        });
    }


    public deleteEvent(id) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage = "Seguro deseas borrar esta encuesta?<br />Todos los registros y estadisticas relacionadas con esta encuesta seran tambien eliminados"
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // do confirmation actions
          this.afs.collection('events').doc(id).delete();
        }
        this.dialogRef = null;
      });
    }
    public toggleStatus(id, status) {
      this.afs.doc('/events/' + id)
        .update({ status: !status });
    }
}
*/
