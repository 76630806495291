// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep mat-grid-tile.mat-grid-tile.selected {
  z-index: 100;
  border: 2px solid blueviolet;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.img-slider {
  width: 100%;
  height: auto;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
  display: block;
  margin: 10px auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lZGlhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksWUFBQTtFQUNBLDRCQUFBO0VBQ0EsOENBQUE7QUFDSjs7QUFHQTtFQUNJLFdBQUE7RUFDQSxZQUFBO0VBQ0Esa0NBQUE7RUFDQSxpQ0FBQTtFQUNBLGNBQUE7RUFDQSxpQkFBQTtBQUFKIiwiZmlsZSI6Im1lZGlhLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOjpuZy1kZWVwIG1hdC1ncmlkLXRpbGUubWF0LWdyaWQtdGlsZS5zZWxlY3RlZCB7XG4gICAgei1pbmRleDogMTAwO1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIGJsdWV2aW9sZXQ7XG4gICAgYm94LXNoYWRvdzogMHB4IDBweCA1cHggNXB4IHJnYmEoMCwwLDAsMC41KTtcbn1cblxuXG4uaW1nLXNsaWRlciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiBhdXRvO1xuICAgIG1heC1oZWlnaHQ6IC13ZWJraXQtZmlsbC1hdmFpbGFibGU7XG4gICAgbWF4LXdpZHRoOiAtd2Via2l0LWZpbGwtYXZhaWxhYmxlO1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIG1hcmdpbjogMTBweCBhdXRvO1xuICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/media/media.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,4BAAA;EACA,8CAAA;AACJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,kCAAA;EACA,iCAAA;EACA,cAAA;EACA,iBAAA;AAAJ;AACA,4zBAA4zB","sourcesContent":["::ng-deep mat-grid-tile.mat-grid-tile.selected {\n    z-index: 100;\n    border: 2px solid blueviolet;\n    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.5);\n}\n\n\n.img-slider {\n    width: 100%;\n    height: auto;\n    max-height: -webkit-fill-available;\n    max-width: -webkit-fill-available;\n    display: block;\n    margin: 10px auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
