import { Component, OnInit, Input } from '@angular/core';
import { FeedElement } from '../facebook-feed.component';

import { FacebookService, UIParams, UIResponse, InitParams } from 'ngx-facebook';


@Component({
  selector: 'app-facebook-feed-dialog',
  templateUrl: './facebook-feed-dialog.component.html',
  styleUrls: ['./facebook-feed-dialog.component.scss']
})
export class FacebookFeedDialogComponent implements OnInit {
  @Input() feeds: FeedElement[];
  @Input() i: number;
  feed: FeedElement;
  index: number;
  selectedIndex: number;
  constructor(private fb: FacebookService) { 
    let initParams: InitParams = {
      appId: '650190605481638',
      xfbml: true,
      version: 'v2.8'
    };
 
    fb.init(initParams);
  }
  ngOnInit() {
    this.getPost();
  }

  getPost() {
    console.log('getFeed', this.i);
    this.index = this.i;
    this.feed = this.feeds[this.index];
  }


  changeSlide(index: number) {
    this.i = index;
    this.index = index;
    this.downSelected(this.i);
    this.getPost();
  }



  downSelected(i) {
    console.log('Tamaño', this.feeds.length);
    console.log('I', i);
    // this.transform = 100 - (i) * 50;
    if (i > this.feeds.length - 1) {
      //this.selectedIndex = 0;
      this.i = 0;
    }
    if (i < 0) {
      //this.selectedIndex = 0;
      this.i = this.feeds.length - 1;
    }

  }

//Facebook Actions

  share(url: string) {
    console.log('Share', url);
    let params: UIParams = {
      href: url,
      method: 'share'
    };
   
    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));
   
  }

}
