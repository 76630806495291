import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  @Input() page: any;
  @Input() blocks: any;
  @Input() listItems: any;
  @Input() galleries: any;
  constructor() { }

  ngOnInit() {
  }

}
