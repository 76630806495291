import { Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { User, UserDetails } from '../../core/user';
import { AuthService } from '../../core/auth.service';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from '../../services/user.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { getLocaleDateFormat, FormatWidth } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { USE_VALUE } from "@angular/core/src/di/injector";
import { Options } from 'selenium-webdriver/opera';
import { GlobalVariable } from 'src/app/shared/globals';
import { TimeInterval, timeInterval } from 'rxjs/internal/operators/timeInterval';
registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],

})
export class UserAccountComponent implements OnInit {
  loggedUser: any;
  userDetail: UserDetails;
  userDetailStatus = 0;
  setDate: string;
  startDate: string;
  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2020, 0, 1);
  participations = [];
  states = GlobalVariable.states;
  sexs = GlobalVariable.sexs;

  public userFormMessage: any = {
    'class': '',
    'text': ''
  };
  userForm: UntypedFormGroup;
  awards: any = [];
  // loggedUserId: any;
  // public user$: Observable<firebase.User>;
  user: User;
  // user: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public auth: AuthService,
    private afs: AngularFirestore,
    private formBuilder: UntypedFormBuilder

  ) {
    console.log('DATE@');
    // this.userDetail = new UserDetails('');


  }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    if (this.user) {
      this.userForm = this.formBuilder.group({
        firstName: [this.user.firstName],
        lastName: [this.user.lastName],
        sex: [this.user.sex],
        birthday: [this.user.birthday,[]],
        state: [this.user.state]
      });
    }
    this.setForm();
    this.auth.user$.subscribe(user => {
      if (user) {
        this.user = user;
        console.log('USER', user);
        this.userForm = this.formBuilder.group({
          firstName: [user.firstName],
          lastName: [user.lastName],
          sex: [user.sex],
          birthday: [user.birthday,[]],
          state: [user.state]
        });
        // console.log(user.birthday);
        // console.log(this.userForm);
        this.userService.getUserAwards(user.uid).subscribe(snapshot => {
          this.awards = snapshot;
          console.log(this.awards);
        });
      } else {
        this.setForm();
      }
    });
  }

  setForm() {
    this.userForm = this.formBuilder.group({
      firstName: ['', []],
      lastName: ['', []],
      sex: ['', [Validators.required]],
      birthday: [],
      state: ['', [Validators.required]]
    });
  }



  /*getUserDetailByID(id: string) {
    const x = this.userService.getUserDetail(id);
    x.subscribe(item => {
      //this.spinnerService.hide();
      const data = item.payload.data() as UserDetails;
      this.userDetail = data;
      console.log("data Birth");
      console.log(data.birthday);
      // let dateField = stringify(data.birthday);
      //const timestamp = typeof data.birthday === 'number' ? data.birthday : Date.parse(data.birthday);
      //const date =isNaN(timestamp) ? null : new Date(timestamp);
      //const date = new FormControl(new Date(data.birthday*1000)); // convert timestamp to date
      //const serializedDate = new FormControl((new Date(data.birthday)).toISOString());
      console.log(data.birthday);
      var datePipe = new DatePipe('es');
      this.setDate = datePipe.transform(data.birthday.toDateString, 'dd/MM/yyyy');
      console.log("Fecha");
      console.log(this.setDate);
     });
  };*/



  onSubmitModel(user = this.user) {
    console.log('Editando el documento Submit Model');
    // Sets user data to firestore on login
    console.log(this.userForm);
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data: any = {
      displayName: user.displayName,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      sex: this.userForm.value.sex,
      birthday: this.userForm.value.birthday,
      state: this.userForm.value.state
    };
    this.userFormMessage['class'] = 'success';
    this.userFormMessage['text'] = 'Los datos han sido actualizados';
    console.log('Documento guardado exitósamente');
    setTimeout(() => {
      this.userFormMessage['class'] = '';
      this.userFormMessage['text'] = '';
    }, 2000);
    return userRef.set(data, { merge: true });
  }

  onSubmit(f: NgForm, user = this.user) {
    console.log('Editando el documento Submit');
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      createdOn: user.createdOn,
      location: user.location,
      avatar: user.avatar,
      phoneNumber: user.phoneNumber,
      roles: user.roles,
      lastName: f.value.lastName,
      sex: f.value.sex,
      birthday: f.value.birthday,
      state: f.value.state
    };
    this.userFormMessage.class = 'success';
    this.userFormMessage.text = 'Los datos han sido actualizados';
    console.log('Documento guardado exitósamente');
    return userRef.set(data, { merge: true });

  }
}
