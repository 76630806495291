import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable } from "rxjs";
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

@Injectable()
export class AuthGuard  {
  constructor(private afAuth: AngularFireAuth, private router: Router, private authService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

// AuthGuard
checkLogin(url: string): boolean {
  if (this.authService.isLoggedIn) { return true; }
  // Store the redirect URL in a local variable
  const redirectUrl = url;
  // Navigate to the login page with extras
  this.router.navigate(['/login'], { queryParams: { returnUrl: redirectUrl } });
  return false;
}
}

@Injectable()
export class AuthGuardData  {
  constructor(private afAuth: AngularFireAuth, private router: Router, private authService: AuthService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState
      .take(1)
      .map(user => !!user)
      .do(loggedIn => {
        this.authService.hasData().then(resultado => {
          console.log("RESULTADO");
          console.log(resultado);
          if (!loggedIn) {
            console.log("access denied")
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
          if (resultado == "hasData") {
             return true;
          } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          }
        });
        return true;
      })
  }
}

@Injectable()
export class AuthGuardAdmin  {
  constructor(private afAuth: AngularFireAuth, private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.afAuth.authState
      .take(1)
      .map(user => !!user)
      .do(loggedIn => {
        if (!loggedIn) {
          console.log("access denied")
          this.router.navigate(['/login']);
        }
      })
  }


}



