import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, Timestamp, BehaviorSubject } from 'rxjs';
import { Trivia } from './trivia';

import { Question } from '../models/question';
import { Option } from '../models/option';
import { Response } from '../models/response';

import { UserService } from '../services/user.service';

import 'rxjs/add/operator/map';
import { Participation } from '../models/participation';
import { Time } from '@angular/common';
import { debounceTime, take, map, distinctUntilChanged } from 'rxjs/operators';
import { leftJoin } from '../services/firestore.service';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class TriviasService {

  triviasCollection: AngularFirestoreCollection<Trivia>;
  trivias: Observable<Trivia[]>;
  triviasDocs: any;
  awardsCounter: number = 0;
  attemptCounter: number = 0;
  constructor(
    private afs: AngularFirestore,
    private user: UserService
  ) { }

  public getTrivia(triviaId: string) {
    return this.afs.collection('trivias').doc(triviaId).snapshotChanges();
  }
  //Obtiene todas las trivias
  public getTrivias() {
    this.triviasCollection = this.afs.collection('trivias', ref => {
      return ref.orderBy('title', "asc").where('status','==',true)
    })
    return this.triviasCollection.snapshotChanges().map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data() as Trivia;
            data.triviaId = a.payload.doc.id;
            return data;
          });
      });
  }
  //Obtiene las ultimas 5 trivias
  public getLastTrivias() {
    return this.afs.collection('trivias', ref =>
      ref
        .where('status', '==', true)
        .orderBy('createdOn')
        .limit(4)
      //.startAt(start)
      //.endAt(end)
    )
  }

  // Listado para buscar en el admin
  getTriviaList(start: BehaviorSubject<string>): Observable<{}> {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        return this.afs
          .collection('trivias', ref =>
            ref
              .orderBy('title')
              .limit(10)
              .startAt(startText)
              .endAt(endText)
          )
          .snapshotChanges().pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(changes => {
              return changes.map(c => {
                console.log(c);
                const data = c.payload.doc.data();
                const id = c.payload.doc.id;
                return { id, data };
              });
            }),
            leftJoin(this.afs, 'triviaId', 'trivias')
          );
      })
    );
  }

  //TODO
  public deleteTrivia(triviaId: string) {
    this.deleteTriviaParticipation(triviaId);
    this.deleteTriviaScores(triviaId);
    this.deleteTriviaAwards(triviaId);
    return this.afs.collection('trivias').doc(triviaId).delete();
  }
  public deleteTriviaParticipation(componentId: string) {
    var query = this.afs.collection('participations').ref.where('componentId', '==', componentId);
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete().then(() => {
          console.log("Las participaciones han sido eliminadas con éxito!");
        }).catch(function (error) {
          console.error("Error eliminando documento: ", error);
        });
      });
    });
  }
  public deleteTriviaScores(componentId: string) {
    var query = this.afs.collection('scores').ref.where('componentId', '==', componentId);
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete().then(() => {
          console.log("Los resultados han sido eliminadas con éxito!");
        }).catch(function (error) {
          console.error("Error eliminando documento: ", error);
        });
      });
    });
  }
  public deleteTriviaAwards(componentId: string) {
    var query = this.afs.collection('awards').ref.where('componentId', '==', componentId);
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete().then(() => {
          console.log("Las premiaciones han sido eliminadas con éxito!");
        }).catch(function (error) {
          console.error("Error eliminando documento: ", error);
        });
      });
    });
  }


  //Get Trivia Data
  public getQuestions(triviaId: string) {
    return this.afs.collection('trivias').doc(triviaId).collection('questions', ref => ref.orderBy('order', "asc"));
  }
 /* public getQuestionsRef(triviaId: string) {
    return this.afs.collection('trivias').doc(triviaId).collection('questions');
  }*/
  public getQuestion(triviaId: string, questionId: string) {
    return this.afs.collection('trivias').doc(triviaId).collection('questions').doc(questionId).snapshotChanges();
  }

  //Questions CRUD
  public createQuestion(triviaId: string, data: Question) {
    var idBefore: string = this.afs.createId();
    data['id'] = idBefore;
    return this.afs.collection('trivias').doc(triviaId).collection('questions').add(data);
  }
  public updateQuestion(triviaId: string, questionId: string, data: Option) {
    console.log("Varriable data: " + data);
    return this.afs.collection('trivias').doc(triviaId).collection('questions').doc(questionId).set(data, { merge: true });
  }


  public deleteParticipation(participationId: string) {
    this.afs.collection('participations').doc(participationId).ref.get().then(res => {
      var data = res.data() as Participation;
      console.log("ParticipacionY", res);
      console.log("Data", data);
      if (data) {
        var query = this.afs.collection('trivias').doc(data.componentId).collection('responses').ref.where('uid', '==', data.uid).where('attempt', '==', data.attempt);
        query.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete().then(() => {
              console.log("Las participaciones han sido eliminadas con éxito!");
            }).catch(function (error) {
              console.error("Error eliminando documento: ", error);
            });
          });
        });
        return this.afs.collection('participations').doc(participationId).delete();
      }
    })
  }

  //Set Response
  public setQuestionResponse(uid, id, questionId, optionId, value, isAnswer, attempt, type = "trivia") {
    const response: Response = { uid, id, questionId, optionId, value, isAnswer, attempt, type };
    const responsePath = `${response.questionId}_${uid}_${response.attempt}_`;
    return this.afs.collection('trivias').doc(id).collection('responses').doc(responsePath).set(response);
  }
  public getQuestionResponses(triviaId, questionId) {
    return this.afs.collection('trivias').doc(triviaId).collection('responses', ref => ref.where('questionId', '==', questionId)).snapshotChanges();
  }
  public getQuestionOptionResponses(triviaId, questionId, optionId) {
    return this.afs.collection('trivias').doc(triviaId).collection('responses', ref => ref.where('questionId', '==', questionId).where('optionId', '==', optionId)).snapshotChanges();
  }
  public getUserTriviaParticipations(userId, componentId) {
    console.log("Buscando todas las participaciones del usuario " + userId + " donde el tipo sea trivia y el componente Id " + componentId);
    return this.afs.collection('users').doc(userId).collection('participations', ref => ref.where('componentId', '==', componentId)).snapshotChanges();
  }
  public setTriviaStats(uid, type = "trivia", componentId, triviaName, date, attempt, score,ellapsedTime) {

    return this.afs.collection('users').doc(uid).collection('awards', ref => ref.where('componentId', '==', componentId)).ref.get().then(
      res => {
        console.log(res.size)
        this.awardsCounter = res.size
        console.log("awardsCounter" + this.awardsCounter);
        if (this.awardsCounter > 0 || score == 0) {
          console.log("Guarda la Participación");
          //this.setScores(userId, triviaId, date, attempt,score);
          this.setParticipation(uid, type, componentId, date, attempt, score,ellapsedTime);
        } else {
          console.log("Guarda la Participación, el puntaje y el premio");
          this.setParticipation(uid, type, componentId, date, attempt, score,ellapsedTime); //trivia //survey
          this.setAward(uid, type, componentId, triviaName, date, attempt, score,ellapsedTime);
          this.setScore(uid, type, componentId, triviaName, date, attempt, score,ellapsedTime);
        }
      });
  }
  public setParticipation(uid, type = "trivia", componentId, date, attempt, score,ellapsedTime) {
    const response: Participation = { uid, type, componentId, date, attempt, score,ellapsedTime };
    // Custom doc ID for relationship
    const responsePath = `${componentId}_${response.attempt}`;
    this.afs.collection('users').doc(uid).collection('participations').doc(responsePath).set(response);
    const responsePath2 = `${response.componentId}_${uid}_${response.attempt}`;
    this.afs.collection('participations').doc(responsePath2).set(response);
    return true;
  };
  public setAward(uid: string, type: string = "trivia", componentId: string, triviaName: string, date: Date, attempt: number, score: number,ellapsedTime: number) {
    const response = { 'uid': uid, 'type': type, 'componentId': componentId, 'detail': 'Participación en Trivia', 'title': triviaName, "date": date, "attempt": attempt, "score": score, "ellapsedTime": ellapsedTime }

    const responsePath = `${componentId}_${response.attempt}`;
    this.afs.collection('users').doc(uid).collection('awards').doc(responsePath).set(response);

    const responsePath2 = `${response.componentId}_${uid}_${response.attempt}`;
    return this.afs.collection('awards').doc(responsePath2).set(response);

  };
  public setScore(uid: string, type: string = "trivia", componentId: string, triviaName: string, date: Date, attempt: number, score: number,ellapsedTime: number) {
    //const response: PollParticipation = { userId, pollId, points,date, attempt,score };
    const response = { 'uid': uid, 'type': type, 'componentId': componentId, 'detail': 'Participacion en Trivia', 'title': triviaName, "date": date, "attempt": attempt, "score": score, "ellapsedTime": ellapsedTime }

    const responsePath = `${componentId}_${response.attempt}`;
    this.afs.collection('users').doc(uid).collection('scores').doc(responsePath).set(response);

    const responsePath2 = `${response.componentId}_${uid}_${response.attempt}`;
    return this.afs.collection('scores').doc(responsePath2).set(response);

  };






}