import { Component, OnInit, Input } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-grids',
  templateUrl: './grids.component.html',
  styleUrls: ['./grids.component.scss']
})
export class GridsComponent implements OnInit {

  @Input() itemsArray: [];
  @Input() cols: number;
  columns: number;
  @Input() size: number;

  rowHeight: number;
  options: any;
  watcher: any;
  activeMediaQuery: string;

  public box: Observable<number>;
  constructor(private mediaObserver: MediaObserver) {

  }

  ngOnInit() {
    console.log('INIT Grid Controller');
    this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      changes.forEach(change => {
        const mqAlias = change.mqAlias;
        this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
        switch (mqAlias) {
          case 'xs':
            this.columns = (this.cols > 3) ? 2 : 1;
            break;
          case 'sm':
            this.columns = (this.cols > 2) ? 2 : 1;
            break;
          case 'md':
            this.columns = (this.cols > 2) ? this.cols : (this.cols === 1) ? 1 : 2;
            break;
          case 'lg':
            this.columns = this.cols;
            break;
          case 'xg':
            this.columns = this.cols;
            break;
          default:
            // this.columns = this.cols;
            // break;
        }
      });
    });
    this.options = {
      dots: false,
      responsive: {
        '0': { items: (this.columns > 3) ? 2 : 1, margin: 5 },
        '430': { items: (this.columns > 3) ? 2 : 1, margin: 5 },
        '550': { items: 2, margin: 5 },
        '750': { items: (this.columns > 3) ? 3 : this.columns, margin: 5 },
        '950': { items: this.columns, margin: 5 }
      },
      autoplay: false,
      loop: false,
      autoplayTimeout: 3000,
      lazyLoad: true
    };



  }

  heightToCols(cols: number): number {
    if (window.innerWidth <= 769) {
      return window.innerWidth * 0.9;
    } else if (window.innerWidth <= 960) {
      return window.innerWidth * 0.65;
    } else if (window.innerWidth <= 1280) {
      return window.innerWidth * 0.42;
    } else {
      return window.innerWidth * 0.3;
    }
  }
}
