import { Component, OnInit, Input } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() itemsArray: [];
  @Input() cols: any;
  @Input() columns: any;
  @Input() swipe: boolean;
  @Input() dark: boolean;

  watcher: any;
  activeMediaQuery: string;
  rowHeight: any;
  rowWidth: any;
  options: any;

  constructor(private mediaObserver: MediaObserver) { }

  ngOnInit() {
    console.log('INIT Content Controller');
    this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      changes.forEach(change => {
        const mqAlias = change.mqAlias;
        this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
        switch (mqAlias) {
          case 'xs':
            this.columns = (this.cols > 3) ? 2 : 1;
            break;
          case 'sm':
            this.columns = (this.cols > 3) ? 2 : 1;
            break;
          case 'md':
            this.columns = (this.cols === 1) ? 1 : 2;
            break;
          case 'lg':
            this.columns = this.cols;
            break;
          case 'xg':
            this.columns = this.cols;
            break;
          default:
          // this.columns = this.cols;
          // break;
        }
        if (this.swipe) {
          this.rowWidth = 100;
        } else {
          this.rowWidth = 100 / this.columns;
        }
      });
    });
    this.options = {
      dots: true,
      responsive: {
        '0': { items: (this.cols > 3) ? 2 : 1, margin: 5 },
        '430': { items: (this.cols > 3) ? 2 : 1, margin: 5 },
        '550': { items: 2, margin: 5 },
        '750': { items: (this.cols > 3) ? 3 : this.cols, margin: 5 },
        '950': { items: this.cols, margin: 5 }
      },
      autoplay: false,
      loop: false,
      autoplayTimeout: 3000,
      lazyLoad: true
    };

    if (this.swipe) {
      this.rowWidth = 100;
    } else {
      this.rowWidth = 100 / this.cols;
    }
  }

}
