import { Component, OnInit, Input } from '@angular/core';
import { Constants } from 'src/app/shared/globals';
import { HttpClient } from '@angular/common/http';


export interface comments {
  text?: string;
  timestamp: any;
  username?: any;

}

@Component({
  selector: 'app-instagram-dialog',
  templateUrl: './instagram-dialog.component.html',
  styleUrls: ['./instagram-dialog.component.scss']
})
export class InstagramDialogComponent implements OnInit {
  @Input() events: any;
  @Input() profile: any;
  @Input() i: number;
  event: any;
  profileData:any;
  commentsElement: comments[] = [];
  ELEMENT_DATA: comments[];


  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]};

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getEvent();
  }


  getEvent(){
    this.event = this.events[this.i];
    this.profileData = this.profile;
    console.log('profileData', this.profileData);
    console.log('tHIS EVENT', this.event);
    /* 
    //Recibe comentariuos, solo en cuentas commerciales
    const url = 'https://graph.facebook.com/v7.0/' + this.event.id + '/comments?access_token=' + Constants.instagramAccessToken;
    this.http.get(url)
      .subscribe(data => {
        this.ELEMENT_DATA = data['data'];
        this.ELEMENT_DATA.sort(function (a, b) {
          var keyA = new Date(a.timestamp),
            keyB = new Date(b.timestamp);
          // Compare the 2 dates
          if (keyA < keyB) { return -1; }
          if (keyA > keyB) { return 1; }
          return 0;
        });
        console.log(this.ELEMENT_DATA);
        this.commentsElement = this.ELEMENT_DATA;
      });
      */
  }


  changeSlide(index: number) {
    this.i = index;
    this.getEvent();
  }

}


