
import { Component, OnInit } from '@angular/core';
import { InstagramAuthService } from '../../services/instagram-auth.service';
import { Constants } from 'src/app/shared/globals';
import { Functions } from '@angular/fire/functions';
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

@Component({
  selector: 'app-page-social-login',
  templateUrl: './page-social-login.component.html',
  styleUrls: ['./page-social-login.component.css']
})
export class PageSocialLoginComponent implements OnInit {


  instagramAuthUrl: string;

  constructor() { }

  loginInstagram() {
    const code = Constants.page_id;
    const client_id = Constants.app_id;
    const redirect_uri = Constants.redirect_uri;
    const secret = Constants.app_secret;

    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const loginInstagramFunction = httpsCallable(functions, 'loginInstagram')


    loginInstagramFunction({ "code": code, "redirect_uri": redirect_uri, "client_id": client_id, "secret": secret }).then((result) => {
      // Procesar el token de acceso
      console.log("RESULTADO LOGIN INSTSGRSM", result);
    }, (error) => {
      // Manejar el error
      console.error(error);
    });
  }









  ngOnInit(): void {
    this.loginInstagram();
  }






  //old
  getCode(response_type: string) {
    // https://api.instagram.com/oauth/authorize ?client_id=990602627938098 &redirect_uri=https://socialsizzle.herokuapp.com/auth/ &scope=user_profile,user_media &response_type=code

    const url = `https://api.instagram.com/oauth/authorize?client_id=${Constants.app_id}&redirect_uri=${Constants.redirect_uri}&scope=user_profile,user_media&response_type=${response_type}`;
    // return this.http.get(url);
  }
  getAccessToken(code: string) {
    const url = `https://api.instagram.com/oauth/access_token?client_id=${Constants.app_id}&client_secret=${Constants.app_secret}&redirect_uri=${Constants.redirect_uri}&code=${code}`;
    //return this.http.post(url, null);
  }
}

//https://api.instagram.com/oauth/authorize/ ? client_id=ID-CLIENTE & redirección_uri=URI-REDIRECTA & tipo_respuesta=código
//https://api.instagram.com/oauth/authorize?client_id=${Constants.app_id}&redirect_uri=${Constants.redirect_uri}&response_type=${response_type}  
