import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsComponent } from './events/events.component';
import { EventComponent } from './event/event.component';
import { AuthGuardData, AuthGuardAdmin } from '../core/auth.guard';
import { EventsAdminComponent } from './events-admin/events-admin.component';
import { EventEditComponent } from './event-edit/event-edit.component';



const routes: Routes = [
  {
    path: "events",
    children: [
      { 
        path: '',   
        redirectTo: 'events', 
        pathMatch: 'full' 
      },
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'admin',
        component: EventsAdminComponent,
        canActivate: [AuthGuardAdmin]
      },
      {
        path: 'create',
        component: EventEditComponent,
        canActivate: [AuthGuardAdmin]
      },
      {
        path: 'edit/:id',
        component: EventEditComponent,
        canActivate: [AuthGuardAdmin]
      },

    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
