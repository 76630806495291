import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  role = 'test';
}
export const GlobalVariable = Object.freeze({
  BASE_API_URL: 'http://salinas.no/',
  SITE_TITLE: 'Veronica Salinas',
  // Trivias
  categories: ['Categoría 1', 'Categoría 2', 'Categoría 3'],
  subcategories: ['Subcategoría 1','Subcategoría 2','Subcategoría 3'],
  states: ['Ciudad Autónoma de Bs As', 'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'Córdoba', 'Corrientes', 'Entre Rios', 'Formosa', 'Jujuy', 'La Pampa', 'La Rioja', 'Mendoza', 'Misiones',
    'Neuquen', 'Rio Negro', 'Salta', 'San Juan', 'Santa Cruz', 'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucuman'],
  sexs: [
    { type: 'F', name: 'F' },
    { type: 'M', name: 'M' }
  ],

  templates: [
    'Predeterminada', 
    'Simple', 
    'Principal', 
    'Home', 
    'Main', 
    'Agenda', 
    'Prensa', 
    'Multimedia'
  ],

  components: [
    { type: 'card', name: 'Cards' },
    { type: 'content', name: 'Contenedor' },
    { type: 'list', name: 'Listado' },
    { type: 'tab', name: 'Tabloides' },
    { type: 'slider', name: 'Slider' },
    { type: 'carousel', name: 'carousel' },
    { type: 'grid', name: 'Grilla' },
    { type: 'split', name: 'Credencial' },
    { type: 'gallery', name: 'Galeraía' },
    { type: 'dashboard', name: 'Pint' },

  ],
  itemTemplates: [
    { type: 'single', name: 'Predeterminada' },
    { type: 'card-23', name: 'Card 2/3' },
    { type: 'card', name: 'Card' },
    { type: 'media', name: 'Media' },

  ],

  answerTypes: [
    { type: 'mc', name: 'Opción multiple' },
    { type: 'cv', name: 'Casillas de verificación' },
    { type: 'tof', name: 'Verdadero o falso' },
    { type: 'select', name: 'Desplegable' },
    { type: 'text', name: 'Respuesta textual' },
    { type: 'number', name: 'Respuesta numberica' },
    { type: 'date', name: 'Fecha' }
  ],

  defaultMediaUrl: 'assets/img/sin-imagen.jpg',
  homePage: 'YORvEx68rsfhDlAuWkCw'

  // ... more of your variables
});




export class Constants {



  static readonly DATE_FMT = 'dd/MMM/yyyy';
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;

  /* Para obtener un token de mas duracion seguir las instrucciones en este sitio:
     https://stackoverflow.com/questions/17197970/facebook-permanent-page-access-token/28418469#28418469

     Depurador de tokens ( Me dice a que aplica un token)
    1 - Ir Graph API Explorer y obtener un token de pagina
    //EAAJPWEwTdqYBANSfABH6Qp3ZAc1yJbZCaI866xOLxED9nbBWrXCYgOxfN5BQlWuZBZBHqsM5RdXdYZAi39GeSkf5QtwSQcqQfhs53vtXFd3CpmVTS4vZBGOYmIpzb1k8JqjHKkn5J3XoekdTiQJqdda0mPM1rfYzTdOiBcPwus0IRBSb4CqRl4ZA9PWWrYCnuMZD


    2 - Con el token obtenido ir a: 
    https://graph.facebook.com/v2.10/oauth/access_token?grant_type=fb_exchange_token&client_id={app_id}&client_secret={app_secret}&fb_exchange_token={short_lived_token}
    3- con ek token del paso dos ir a :
    https://graph.facebook.com/v3.30/me?access_token=EAAJPWEwTdqYBAPQtPLsSTW694FonZBiHG6acxFU4SzWwomXmzz0FHSmnZBwLKlwSL6cZCSFEurnHGz7v9D6AvMCix0QTHq92xkIZAvum3QPy0eiD7HGivLbzF2LuSOyANUlo63JuojDZBNDvDNTNZAHH35mEEFpZB5Tq4L5QQTDRwZDZD

    
    4-
    https://graph.facebook.com/v2.10/{account_id}/?fields=access_token&access_token={long_lived_access_token}
    https://graph.facebook.com/v2.10/1626228790998566/?fields=access_token&access_token=EAAJPWEwTdqYBAPQtPLsSTW694FonZBiHG6acxFU4SzWwomXmzz0FHSmnZBwLKlwSL6cZCSFEurnHGz7v9D6AvMCix0QTHq92xkIZAvum3QPy0eiD7HGivLbzF2LuSOyANUlo63JuojDZBNDvDNTNZAHH35mEEFpZB5Tq4L5QQTDRwZDZD

       */
  // Facebook
  static readonly app_id = '650190605481638'; // Salinas
  static readonly app_secret = 'ae79ce87b97732a0638d586d608d05c1'; // Salinas App Secret
  // static readonly page_id = '2486081901613980'; // Empresa Test
  static readonly page_id = '1626228790998566'; // Salinas
  // tslint:disable-next-line: max-line-length
  static readonly accessToken = 'EAAJPWEwTdqYBAH0LB9UM2YnoDZCUfGw9UF34zxUvZA1bfOoEg6BFrdMFn5cBfHFUhZBvXZAAe7Jsrc4UDlxXrsSUh6h1cTWYpETQBAkV6re1ZBe48R7fjX46EZAezv1vT0myVRxfKwYcFLTmRfZARTG2IUgcGrYvZAZAQeq7HXHuHRgZDZD';

  //Instagram
  //From Web
  //static readonly instagramAccessToken = 'AQBJeVa8ED7PTTpg2sdDMESzDW7FsD92j0h5lYtWsnEyKTSX7MN2ql5WTAyY5yAtc4nXFWrxG4QwMQ7ObHJoRYgFIUhRfvhkyNeQZlIrHW0Hw8cKJoHgwRjELPAgYKwU-_IiIIeyRh6xNLbfF7uO0RlnOp-N6e-VnXIRhAM8fEUdd10o-OVRsNSa15vBABj1VxcnZEoazvCrzJXVbIFPfSQN7zKn6l9Ezr0IPsgYbbQJ_g'
  //From Developer Panel
  //static readonly instagramClientId = '650190605481638'; 
  //Instagram Salinas ID = 8058452878 
  static readonly instagramAccessToken = 'IGQWROUXk1Q2lQcWRfVHFKQ1RncFEwdkhtUmpDQkhDenZAFQ2U0eHdyZAkZAZAX1hUZAU5XMEo0UkotSU9ZAdFlPSlRwYnowQ0dmRjZArdzdkNXJBeXMxZAy1PYW4zc1BMUUtkbUJ6cHliWUg3QUhmS0N2R2dqNEVSSjFDUW8ZD'

  static readonly scope = 'public_profile,email,user_birthday,user_location,user_friends,user_photos,user_videos,user_gender';
  static readonly redirect_uri = 'https://localhost:4200/pages/socialLogin';
}

//Proceso para renovar el token de instagram
//Entrar en el sitio Facebook developers, selecionar la aplicacion de Salinas y En el complemento de instagram loguearse con el usuario veronicamsalinas
//Copiar el Token actualizar la credencial en el archivo globals.ts
//Pagina para generar el token
//https://developers.facebook.com/apps/650190605481638/instagram-business/API-Setup/?business_id=409164003358611https://developers.facebook.com/apps/650190605481638/instagram-business/API-Setup/?business_id=409164003358611



//Esta no me funciono
//https://api.instagram.com/oauth/access_token?client_id=650190605481638&client_secret=$ae79ce87b97732a0638d586d608d05c1&redirect_uri=http://localhost:4200/pages/socialLogin&code=code