
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
//NO_ERRORS_SCHEMA Evita que se cuelgue con los colores
import { CommonModule } from '@angular/common';

import { IndexRoutingModule } from './index-routing.module';
import { IndexComponent } from './index.component';
import { SharedModule } from './../shared/shared.module';
import { MaterialModule } from "../shared/material.module";

import { LayoutModule } from '@angular/cdk/layout';
import { ContactComponent, ContactDialog } from '../components/contact/contact.component';
import { IndexEditComponent } from './index-edit/index-edit.component';






@NgModule({
    imports: [
        CommonModule,
        IndexRoutingModule,
        SharedModule,
        MaterialModule,
        LayoutModule
    ],
    declarations: [IndexComponent, ContactComponent, ContactDialog, IndexEditComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class IndexModule { }
