import { Component, VERSION, isDevMode, HostBinding, OnInit } from '@angular/core';

import { UserService } from './services/user.service';
import { fadeAnimation, slider } from './shared/animations/fadeIntRoute';
import { fader, stepper, transformer, slideInAnimation } from './shared/animations/route-animations';
// import { BreakpointState } from '@angular/cdk/layout';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import { AuthService } from './core/auth.service';
import { Router, RouterOutlet, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { waitForAsync } from '@angular/core/testing';
import { User } from './core/user';

import { Observable, of } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactDialogComponent } from './components/contact-dialog/contact-dialog.component';
import { Constants, GlobalVariable } from './shared/globals';
import 'rxjs/add/operator/filter';
import { trigger, transition, query, style, group, animate, animateChild } from '@angular/animations';
// import { Observable } from 'rxjs';

declare var $: any;
window.onscroll = function () { scrollFunction(); };

function scrollFunction() {
  /*if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    document.getElementById('app-menu').style.top = '200';
  } else {
    document.getElementById('app-menu').style.top = '-30px';
  }*/
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  /*animations: [
    // stepper
    fader,
    // slider,
    // fadeAnimation
    // transition(':enter, :leave', [
    //  query('@*', animateChild(), { optional: true })
    // ]),


  ],*/
  providers: [UserService]
})
export class AppComponent implements OnInit {
 
  @HostBinding('class.fxFlex') true;
  title: string;
  angular_v: string;
  production_mode: string;
  dev_mode: boolean;
  // isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  mode = 'push';
  showNav = false;
  user: User;
  contactDialogRef: MatDialogRef<ContactDialogComponent>;
  activePage = '';
  typePage = '';
  slug: any;
  nav = 'close';

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver) {
    this.title = GlobalVariable.SITE_TITLE;
    this.dev_mode = isDevMode();
    this.production_mode = `Dev Mode: ` + isDevMode();
    this.angular_v = `Angular Version: ${VERSION.full}`;

    this.route.params.subscribe(value => {
      this.slug = value['slug'];
    });



  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
  ngOnInit() {

    this.router.events.filter(e => e instanceof NavigationEnd)
      .forEach(e => {
        this.activePage = this.route.root.firstChild.snapshot.data['title'];
        this.typePage = this.route.root.firstChild.snapshot.data['type'];
        // console.log('activePage', this.activePage);
      });
    this.auth.user$.subscribe(user => this.user = user);
    // Run Geolocalizador
    // if (navigator.geolocation) {
    // navigator.geolocation.getCurrentPosition(this.setGeoLocation.bind(this));
    // }
  }
  onActivate(event) {
    console.log("onActivate",event);
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
}
  setGeoLocation(position: any) {
    this.userService.setLocation(
      position['coords'].latitude,
      position['coords'].longitude
    );
  }

  toggleNav() {
    (this.nav === 'close') ? this.openNav() : this.closeNav();
  }

  openNav() {
    this.nav = 'open';
    const sidenav = document.getElementById('sidenav');
    const main = document.getElementById('main');
    sidenav.style.width = '280px';
    main.style.marginLeft = '0px';
    main.style.opacity = '0.5';
    main.style.position = 'static';
  }

  closeNav() {
    this.nav = 'close';
    const sidenav = document.getElementById('sidenav');
    const main = document.getElementById('main');
    sidenav.style.width = '0';
    main.style.marginLeft = '0';
    main.style.opacity = '1';
    main.style.position = 'relative';
   }


  logout() {
    this.auth.logout();
    this.router.navigate(['']);
  }

  public openContactDialog(): void {
    
    this.contactDialogRef = this.dialog.open(ContactDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box'
    });
    // this.contactDialogRef.componentInstance.pageId = parentId;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.contactDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.contactDialogRef = null;
    });
  }
}
