import { Component, OnInit, Input, OnChanges, AfterViewInit, Sanitizer } from '@angular/core';
import { MediasService } from 'src/app/medias/medias.service';
import { DomSanitizer } from '@angular/platform-browser';

import { trigger, transition, animate, style, state } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ]),
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class VideoComponent implements OnInit {


  @Input() id: string;
  @Input() mediaClass: string;
  @Input() selectedMedia: string;
  showVideo = true;


  media: any;
  classmedia = 'img-responsive';
  preload: boolean;


  public YT: any;
  public player: any;
  public reframed: Boolean = false;

  constructor(
    private mediasService: MediasService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }
  ngOnInit(): void {
     if (this.mediaClass) {
      this.classmedia = this.mediaClass;
    }
    this.media = 'https://www.youtube.com/embed/' + `${this.id}` + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0';
  
  }


  init() {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
  ngAfterViewInit() {
    const doc = (<any>window).document;
    const playerApiScript = doc.createElement('script');
    playerApiScript.type = 'text/javascript';
    playerApiScript.src = 'https://www.youtube.com/iframe_api';
    doc.body.appendChild(playerApiScript);
  }



   onYouTubeIframeAPIReady(code: string) {
    console.log('VIDEO onYouTubeIframeAPIReady', this.id);
    this.player = new (<any>window).YT.Player('player' + this.id, {
      height: '100%',
      width: '100%',
      events: {
        'onReady': (event) => {
          this.onPlayerReady(event);
          this.player.loadVideoById(code);
        },
        'onStateChange': (event) => { this.onPlayerStateChange(event); }
      },
      playerVars: {
        autoplay: 1,
        controls: 0,
        disablekb: 1,
        modestbranding: 1,
        rel: 0,
        fs: 0,
        showInfo: 0
      }
    });
  }

onPlayerStateChange(event) {
   if (event.data === 0) {
    }

  }
  onPlayerReady(event) {
    console.log('Player Ready');
    this.loadVideo(this.id);
    console.log(event);
    event.target.playVideo();
  }

  getVideo() {
    return '60ItHLz5WEA';
  }
  loadVideo(videoId) {
  
    if (this.player) {
      console.log('es player');
      this.player.loadVideoById(videoId);
    } else {
      this.onYouTubeIframeAPIReady(videoId);
    }
  }




}
