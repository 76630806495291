import { Component, OnInit, Input } from '@angular/core';
import { ContactDialogComponent } from '../components/contact-dialog/contact-dialog.component';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from '../core/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../core/user';
import { Router } from '@angular/router';
import { trigger, transition, state, style, animate, query } from '@angular/animations';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
 
})
export class MenuComponent implements OnInit {

  @Input() activeLink: string;
  public contactDialogRef: MatDialogRef<ContactDialogComponent>;
  user: User;




  constructor(public dialog: MatDialog,
    public auth: AuthService,
    private router: Router
  ) {
    this.auth.user$.subscribe(user => this.user = user);

  }

  ngOnInit(): void {
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }
  openContactDialog(): void {
    this.contactDialogRef = this.dialog.open(ContactDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box'
    });
    // this.contactDialogRef.componentInstance.pageId = parentId;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.contactDialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.contactDialogRef = null;
    });
  }
}
