import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})

export class GalleryDialogComponent implements OnInit{

  itemsArray: [];
  gallery: any;
  slideConfig = {
    "slidesToShow":1,
    "slidesToScroll": 1,
    "nextArrow":'<div class="nav-btn next-slide"><mat-icon>chevron_left</div></div>',
    "prevArrow":'<div class="nav-btn prev-slide"><mat-icon>chevron_right</div></div>',
    "dots": true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]};
  constructor(
    public dialog: MatDialog,
    public dialogRefSection: MatDialogRef<GalleryDialogComponent>
 ) {}

  ngOnInit() {

    console.log('INIT Gallery Dialog Controller')
    this.itemsArray.forEach( element => {
      this.gallery = element;
    });
  }
  slickInit(e){

  }
}
