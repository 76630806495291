import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-facebook-event-dialog',
  templateUrl: './facebook-event-dialog.component.html',
  styleUrls: ['./facebook-event-dialog.component.scss']
})
export class FacebookEventDialogComponent implements OnInit {
  @Input() events: any[];
  @Input() i: number;
  event: any;
  index: number;
  selectedIndex: number;
  constructor() { }

  ngOnInit() {
    this.getEvent();
  }

  getEvent() {
    console.log('getEvent', this.i);
    this.event = this.events[this.i];
    this.index = this.i;
  }


  changeSlide(index: number) {
    this.i = index;
    this.downSelected(index);
    this.getEvent();
  }



  downSelected(i) {
    console.log('Tamaño', this.events.length);
    console.log('I', i);
    // this.transform = 100 - (i) * 50;
    if (i > this.events.length - 1) {
      //this.selectedIndex = 0;
      this.i = 0;
    }
    if (i < 0) {
      //this.selectedIndex = 0;
      this.i = this.events.length - 1;
    }

  }

}
