
export class Page {
    id?: string;
    createdOn?: Date;
    updatedOn: Date;
    title: string;
    subtitle?: string;
    content?: string;
    excerpt?: string;
    template: string;
    showdata: boolean = true;
    showcontainer: boolean = true;
    slug: string;
    media?: string;
    parent?: string;
    order?: number;
    status: boolean = false;
    private: boolean = false;
    config: any;
    
    
    //  questions: Question[];
    //  responses?: Response[];

    constructor(data: any) {

        if (data) {
            this.id = data.id;
            this.createdOn = data.createdOn,
            this.updatedOn = data.updatedOn,
            this.content = data.content;
            this.excerpt = data.excerpt;
            this.media = data.media;
            this.title = data.title;
            this.subtitle = data.subtitle;
            this.slug = data.slug;
            this.order = data.order;
            this.showdata = data.showdata;
            this.showcontainer = data.showcontainer;
            this.status = data.status;
            this.private = data.private;
            this.template = data.template;
            this.parent = data.parent;
            this.config = data.config;
           
            /*this.questions = [];
            data.questions.forEach(q => {
                this.questions.push(new Question(q));
            });
            data.responses.forEach(q => {
                this.responses.push(new Response(q));
            });*/
        }
    }
}