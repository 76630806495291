import { Component, AfterViewInit, ViewChild, isDevMode, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthService } from "../../core/auth.service";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { UserService } from "src/app/services/user.service";
import { User } from '../../core/user';
import { leftJoin, leftJoinDocument, docJoin, FirestoreService } from 'src/app/services/firestore.service';
import { shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase/compat/app';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements AfterViewInit, OnInit {
  participation;
  joined;
  joined2;
  user: User;
  dev_mode: boolean;
  displayedColumns = ['componentId', 'type', 'date', 'attempt', 'score'];
  displayedColumns2 = ['componentId', 'type', 'date', 'attempt'];
  dataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<any>;

  //  dataSource= new MatTableDataSource();
  @ViewChild('sort1', {static: false}) sort1: MatSort;
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;

  @ViewChild('sort2', {static: false}) sort2: MatSort;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;

  constructor(private db: FirestoreService, private afs: AngularFirestore, public dialog: MatDialog, private userService: UserService,
    public authService: AuthService, ) { }


  ngAfterViewInit() {

    this.authService.user$.subscribe(user => {
      this.user = user
      this.getUserParticipations(user);
    })
    this.dev_mode = isDevMode();
  }
  getUserParticipations(user){
   
    //this.dataSource = new MatTableDataSource(users);
    this.afs.collection('users').doc(user.uid).collection('participations', ref => ref.where('type', '==', 'trivia')).valueChanges().pipe(
      leftJoinDocument(this.afs, 'componentId', 'trivias'),
      shareReplay(1)
    ).subscribe(obj1 => {
      const mapped1 = Object.create(obj1 as object);
      const mapped = Object.keys(obj1).map(value => ({ value: obj1[value] }));
      this.dataSource = new MatTableDataSource(mapped1);
      this.dataSource.paginator = this.paginator1;
      this.dataSource.sort = this.sort1;
      console.log(this.dataSource.data);
    });

    this.afs.collection('users').doc(user.uid).collection('participations', ref => ref.where('type', '==', 'poll')).valueChanges().pipe(
      leftJoinDocument(this.afs, 'componentId', 'polls'),
      shareReplay(1)
    ).subscribe(obj => {
      const mapped2 = Object.create(obj as object);
      const mapped1a = Object.keys(obj).map(value => ({ value: obj[value] }));
      this.dataSource2 = new MatTableDataSource(mapped2);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2.sort = this.sort2;
      console.log(this.dataSource2.data);
    });
  };
    
  ngOnInit() {
    //  this.dataSource = new MatTableDataSource();
    console.log(this.joined);
   
    //this.dataSource = new MatTableDataSource( this.joined2);
    //console.log(...this.joined2);

    //this.dataSource.sort = this.sort;
    //console.log([this.dataSource.data]);
    /* 
     this.userService.getUserParticipations(this.loggedUser.$key).subscribe(snap => {
       this.dataSource = new MatTableDataSource(snap);
       console.log(this.dataSource.data);
 
       //this.dataSource.data.forEach(snap => {
       //console.log([snap]);   
 
       //});
       this.dataSource.sort = this.sort;
       console.log(this.dataSource.data);
     });*/


  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }
  
  openDialog(data): void {
    //const dialogRef = this.dialog.open(EditDialogComponent, {
    //width: '350px',
    //data: data
    //});
  }

  trackByUid(index, item) {
    return item.uid
  }


}
