import { Component, OnInit } from '@angular/core';
import { Time } from '@angular/common';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MediaDialogComponent } from 'src/app/medias/media-dialog/media-dialog.component';
import { AngularFireStorageReference, AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreService } from 'src/app/services/firestore.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GlobalVariable } from 'src/app/shared/globals';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ProgramDialogComponent } from '../program-dialog/program-dialog.component';
import { Observable } from 'rxjs';

export interface Evento {
  id: string;
  title?: string;
  subtitle?: string;
  location?: any;
  content?: Text;
  excerpt?: Text;
  media?: string;
  start?: Date;
  startTime?: Time;
  year?: Date;
  end?: Date;
  endTime?: Time;
  tickets: string;
  status: boolean;
  highlight: boolean;
  tags: any;
  createdOn: Date;
  updatedOn: Date; // program?: any;
}
export interface Tag {
  name?: string;
}

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})

export class EventEditComponent implements OnInit {
  event: any;
  eventProgram = [];
  eventForm: UntypedFormGroup;
  documentId: string = null;
  currentStatus = 1;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogMediaRef: MatDialogRef<MediaDialogComponent>;
  dialogProgramRef: MatDialogRef<ProgramDialogComponent>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: Tag[] = [];
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  uploadState: Observable<string>;
  downloadURL: Observable<string>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private firestorage: AngularFireStorage,
    private afs: AngularFirestore,
    private db: FirestoreService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.setForm();
    const sub = this.activatedRoute.params.subscribe(params => {
      const id = params['id']; // (+) converts string 'id' to a number
      this.documentId = id;
      if (params['id']) {
        this.getEventByID(id);
        this.currentStatus = 2;
        console.log('event', this.event);
      } else {
        this.currentStatus = 1;
      }
    });
    this.setForm();

  }

  setForm() {
    this.eventForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      subtitle: [null, []],
      excerpt: [null, []],
      content: [null, []],
      location: [null, []],
      media: [null, []],
      start: [null, [Validators.required]],
      startTime: [null, []],
      end: [null, []],
      endTime: [null, []],
      tags: ['', []],
      tickets: [null, []],
      status: [false, []],
      highlight: [false, []],
    });
  }
  getEventByID(id: string) {
    const x = this.afs.collection('events').doc(id).snapshotChanges();
    x.subscribe(item => {
      const itemData = item.payload.data() as Evento;
      itemData['id'] = id;
      this.event = itemData;

      const x = this.afs.collection('events').doc(id).collection('programs', ref => ref.orderBy('order', 'asc')).snapshotChanges();
      x.subscribe(programItems => {
        this.eventProgram = [];

        programItems.forEach(item => {
          this.eventProgram.push({
            id: item.payload.doc.id,
            data: item.payload.doc.data()
          });
        });
      });

      if (this.event.tags) {
        this.tags.length = 0;
        this.event.tags.forEach(tag => {
          this.tags.push(tag);
        });
      }
      //console.log("TAGS A", this.tags);
      this.eventForm = this.formBuilder.group({
        title: [this.event.title, [Validators.required]],
        subtitle: [this.event.subtitle, []],
        content: [this.event.content, []],
        excerpt: [this.event.excerpt, []],
        location: [this.event.location, []],
        media: [this.event.media, []],
        start: [this.event.start, [Validators.required]],
        startTime: [this.event.startTime, []],
        end: [this.event.end, []],
        endTime: [this.event.endTime, []],
        tags: ['', []],
        status: [this.event.status, []],
        highlight: [this.event.highlight, []],
        tickets: [this.event.tickets, []],
      });
      //console.log(this.pollQuestions);
    });
  }

  onSubmit() {
    //console.log(`Status: ${this.currentStatus}`);
    //console.log('Form:', this.pollForm);
    if (this.eventForm.valid) {
      var date = new Date(this.eventForm.get('start').value);   
      if (!this.documentId) {
        console.log('Creando un nuevo documento!');
        console.log(this.tags);
        var idBefore = this.afs.createId();
        const data = {
          id: idBefore,
          title: this.eventForm.get('title').value,
          subtitle: this.eventForm.get('subtitle').value,
          content: this.eventForm.get('content').value,
          excerpt: this.eventForm.get('excerpt').value,
          location: this.eventForm.get('location').value,
          media: this.eventForm.get('media').value,
          start: this.eventForm.get('start').value,
          startTime: this.eventForm.get('startTime').value,
          year: date.getFullYear(),
          end: this.eventForm.get('end').value,
          endTime: this.eventForm.get('endTime').value,
          tags: this.tags,
          status: this.eventForm.get('status').value,
          //status: true,
          highlight : this.eventForm.get('highlight').value,
          tickets : this.eventForm.get('tickets').value,
          createdOn: new Date(),
          updatedOn: new Date(),
        }
        this.afs.collection('events').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.router.navigate(['events/admin/']);
        }, (error) => {
          console.log(error);
        });
      } else {
        console.log('Editando el documento');
        console.log('TAGS', this.tags);
        const data = {
          // config: this.config,
          title: this.eventForm.get('title').value,
          subtitle: this.eventForm.get('subtitle').value,
          content: this.eventForm.get('content').value,
          excerpt: this.eventForm.get('excerpt').value,
          location: this.eventForm.get('location').value,
          media: this.eventForm.get('media').value,
          start: this.eventForm.get('start').value,
          startTime: this.eventForm.get('startTime').value,
          year: date.getFullYear(),
          end: this.eventForm.get('end').value,
          endTime: this.eventForm.get('endTime').value,
          tags: this.tags,
          status: this.eventForm.get('status').value,
          tickets: this.eventForm.get('tickets').value,
          highlight: this.eventForm.get('highlight').value,        
          updatedOn: new Date(),
        }
        this.afs.collection('events').doc(this.documentId).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
          this.router.navigate(['events/admin']);
        }, (error) => {
          console.log(error);
        });
      }
    } else {
      console.log('El Formulario no es valido');
    }
  }
  getRedirectTitle(id: any) {
    // const y = this.pollQuestions.find(res=>res.id ===id)
    // return y.title;
     const x = this.eventProgram.filter(
       item => {
          if(item.id === id){
           console.log('ANSWER',item);
           console.log('ANSWER',item.data.title);
           return item.data.title;
           
         }
       })[0];
      
         return x.data.title;
      // console.log("X",x);
      
   }

  ////////////////////////////////TAGS////////////////////////////////
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
      console.log(this.tags);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  //Tags Remove
  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }


  ////////////////////////////////QUESTIONS////////////////////////////////
  public deleteItemProgram(id, itemProgramId) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = 'Seguro deseas modificar el tipo de pregunta? Todos los registros y estadisticas relacionadas seran tambien eliminados'
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
      this.afs.collection('events').doc(id).collection('programs').doc(itemProgramId).delete().then(() => {
        console.log('Documento eliminado!');
        return true;
      });
    }
      this.dialogRef = null;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.eventProgram, event.previousIndex, event.currentIndex);
    /* console.log("PreviousIndex" + event.previousIndex);
     console.log("CurrentIndex" + event.currentIndex);
     console.log("Poll ID" + this.PollModel.id);
     console.log("Question ID" + this.pollQuestions[event.currentIndex].id);
    */
    this.eventProgram.forEach((item, index) => {
      if (item !== undefined) {
        console.log('item'); // 1, 2, 3
        console.log(item.id); // 1, 2, 3
        console.log('Index'); // 1, 2, 3
        console.log(index); // 0, 1, 2
        this.db.upsert('/polls/' + this.event.id + '/programs/' + item.id, { order: index });
      }
    });
    console.log('Haz realizado cambios en el orden del listado');
    //return false;
  }

  openItemProgramDialog(id:string, itemProgramId:string = null): void {
     this.dialogProgramRef = this.dialog.open(ProgramDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '90vw',
    });
    
    this.dialogProgramRef.componentInstance.id = id;
    this.dialogProgramRef.componentInstance.programId = itemProgramId;
    //const sub = this.dialogQuestionRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
    //  selectedMediaId = data.id;
    //  selectedMediaUrl = data.url
    //});
    this.dialogProgramRef.afterClosed().subscribe(data => {
      if (data) {
        //sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogProgramRef = null;
    });
  }
  openMediaDialog(e, field, group = null, subgroup = null): void {
    var selectedMediaId = null;
    var selectedMediaUrl = null;
    //if (this.sectionId) {
    this.dialogMediaRef = this.dialog.open(MediaDialogComponent, {
      disableClose: false
    });
    this.dialogMediaRef.componentInstance.field = field;
    this.dialogMediaRef.componentInstance.confirmMessage = 'Estas seguro en cambiar la plantilla? Al confirmar todos los items y contenidos de la página seran eliminados';
    const sub = this.dialogMediaRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
      selectedMediaId = data.id;
      selectedMediaUrl = data.url
    });
    this.dialogMediaRef.afterClosed().subscribe(data => {
      if (data) {
        //  debugger
        if (selectedMediaId) {
          if (e.target.parentNode.previousSibling) {
            var m = e.target.parentNode.previousSibling.firstChild.querySelector('img');
            var n = e.target.parentNode.nextSibling;
            e.target.nextSibling.disabled = false;
          }
          m.src = selectedMediaUrl;
          n.value = selectedMediaId;
          if (group != null) {
            this.getFormGroup(group, subgroup).controls[field].setValue(selectedMediaId);
          } else {
            this.eventForm.controls[field].setValue(selectedMediaId);
          }
        }
        sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogMediaRef = null;
    });
  }

  getMediaValue(field, group = null, subgroup = null) {
    if (this.getFormGroup(group, subgroup).controls[field].value === '' || this.getFormGroup(group, subgroup).controls[field].value) {
      return this.getFormGroup(group, subgroup).controls[field].value;
    } else {
      return GlobalVariable.defaultMediaUrl;
    }
  }
  
  getFormGroup(group, subgroup): UntypedFormGroup {
    const groupA = this.eventForm.get(group) as UntypedFormArray;
    const formGroup = groupA.controls[subgroup] as UntypedFormGroup;
    return formGroup;
  }

  cleanMediaField(e, field, group=null, subgroup = null): void {
    if (e.target.parentNode.previousSibling) {
      var m = e.target.parentNode.previousSibling.firstChild.querySelector('img');
      var n = e.target.parentNode.nextSibling;
      e.target.disabled = true;
    }
    m.src = GlobalVariable.defaultMediaUrl;
    n.value = '';

    if (subgroup != null) {
      this.getFormGroup(group, subgroup).controls[field].reset('');
    } else {
      this.eventForm.controls[field].reset('');
    }
  }
}