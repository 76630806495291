import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { GlobalVariable, Globals } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, debounceTime, take } from 'rxjs/operators';
import { MediaDialogComponent } from 'src/app/medias/media-dialog/media-dialog.component';
import { SlugPipe } from 'src/app/shared/pipes/slugPipe';

class Config {
  container: boolean;
  showdata: boolean;
  type: string;
  cols: string;
  backgroung: any;
  seo: any;  
}

class Block {
  title: string;
  subtitle: string;
  excerpt: string;
  content: string;
  media: string;
  slug: string;
  order: number;
  status: boolean;
  private: boolean;
  items: any;
  config: any;
}
@Component({
  selector: 'app-block-dialog',
  templateUrl: './block-dialog.component.html',
  styleUrls: ['./block-dialog.component.scss']
})
export class BlockDialogComponent implements OnInit {
  blockForm: UntypedFormGroup;
  blockModel: Block = new Block();
  
  pageId: string;
  blockId: string;
  blocks: any[];
  block: any;

  
  types = GlobalVariable.components;
  
  panelOpenState:boolean;
  //nextSelectedTemplate: string;
  //selectedTemplate: string;
  //activeTemplate: string;
  public config: any = {
    'showdata': true,
    'container': true,
    'background': {
      type: 'image',
      media: null,
      position: 'responsive',
      color: 'transparent',
    
    },
    'width': '100',
    'align': 'center',
    
    //'pageData': true
};
  
  
  public templates = GlobalVariable.templates;
  public pageModel;
  defaultMediaUrl = GlobalVariable.defaultMediaUrl;
  media: string = "";
  currentStatus = 1;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogMediaRef: MatDialogRef<MediaDialogComponent>;
  selectedType: any;
  previousSelectedType: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRefBlock: MatDialogRef<BlockDialogComponent>
 
  ) { }

  ngOnInit() {
    this.setForm();
    if (this.blockId != '') {
      this.afs.collection('pages').doc(this.pageId).valueChanges().subscribe((x) => {
        this.pageModel = x;
      })
      if (this.pageId && this.blockId) {
        this.getItemByID(this.pageId, this.blockId);
        this.currentStatus = 2;
        //console.log("PageModel", this.PageModel)
      } else {
        this.currentStatus = 1;
       
      }
    } else {
      this.setForm();
      this.currentStatus = 1;
    }

  }



  setForm() {
    this.blockForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      subtitle: ['', []],
      content: ['', []],
      media: ['', []],
      order: ['', []],
      status: [false, []],
      private: [false, []],
      config: this.formBuilder.group({
        background: this.formBuilder.group({
         type: [null, []],
         media: [null, []],
         style: [null, []],
         color: [null, []],
        }),
        showdata: [false, []],
        container: [true, []],
        width: ['100', []],
        align: ['left', []],
      }),
      options: this.formBuilder.array([]) // create empty form array   
    })
  }

  getItemByID(pageId: string, blockId: string) {
    //this.item 
    console.log("getItemByID pageId:", pageId);
    console.log("getItemByID blockId", blockId);
    const x = this.afs.collection('pages').doc(pageId).collection('blocks').doc(blockId).snapshotChanges();
    x.subscribe(block => {
      const data = block.payload.data() as Block;
      this.blockModel = data as Block;
      console.log("ITEMS", this.blockModel.items);
      this.config = this.blockModel.config;
      this.blockForm = this.formBuilder.group({
        title: [this.blockModel.title, [Validators.required]],
        subtitle: [this.blockModel.subtitle, []],
        content: [this.blockModel.content, []],
        media: [this.blockModel.media, []],
        order: [this.blockModel.order, []],
        status: [this.blockModel.status, []],
        private: [this.blockModel.private, []],
        config: this.formBuilder.group({
          background: this.formBuilder.group({
           type: [this.blockModel.config.background.type, []],
           media: [this.blockModel.config.background.media, []],
           style: [this.blockModel.config.background.style, []],
           color: [this.blockModel.config.background.color, []],
          }),
          showdata: [this.blockModel.config.showdata, []],
          container: [this.blockModel.config.container, []],
          width: [this.blockModel.config.width, []],
          align: [this.blockModel.config.align, []],
        })
      });
      
    });
  }


  onSubmit() {
    //console.log(`Image: ${this.media}`);
    //console.log(`Image: ${ JSON.stringify(f.value)}`);
    console.log('Form:', this.blockForm);
    if (this.blockForm.valid) {
      if (!this.blockId) {
        console.log('Creando un nuevo documento!');
        var idBefore = this.afs.createId();
        const data = {
          pageId: this.pageId,
          id: idBefore,
          // config: this.config,
          title: this.blockForm.get('title').value,
          subtitle: this.blockForm.get('subtitle').value,
          content: this.blockForm.get('content').value,
          media: this.blockForm.get('media').value,
          //tags: this.pollForm.get('tags').value,
          order: this.blockForm.get('order').value,
          status: this.blockForm.get('status').value,
          private: this.blockForm.get('private').value,
          config: this.blockForm.get('config').value,
          createdOn: new Date(),
          updatedOn: new Date(),
          // level: this.pollForm.get('level').value,
        }
        this.afs.collection('pages').doc(this.pageId).collection('blocks').doc(idBefore).set(data).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefBlock.close();
        }, (error) => {
          console.log(error);
        });;

      } else {
        console.log('Editando el documento');
        const data = {
          // config: this.config,
          title: this.blockForm.get('title').value,
          subtitle: this.blockForm.get('subtitle').value,
          content: this.blockForm.get('content').value,
          media: this.blockForm.get('media').value,
          //tags: this.pollForm.get('tags').value,
          order: this.blockForm.get('order').value,
          status: this.blockForm.get('status').value,
          private: this.blockForm.get('private').value,
          config: this.blockForm.get('config').value,
          updatedOn: new Date(),
          /**/
        }
        this.afs.collection('pages').doc(this.pageId).collection('blocks').doc(this.blockId).set(data, { merge: true }).then(() => {
          console.log('Documento guardado exitósamente');
          this.dialogRefBlock.close();
        }, (error) => {
          console.log(error);
        });
      }
    } else {
      console.log("El Formulario no es valido");
    }
  }

  openMediaDialog(e, field,group  = null,subgroup = null): void {
    var selectedMediaId = null;
    var selectedMediaUrl = null;
    //if (this.blockId) {
    this.dialogMediaRef = this.dialog.open(MediaDialogComponent, {
      disableClose: false
    });
    this.dialogMediaRef.componentInstance.field = field;
    this.dialogMediaRef.componentInstance.confirmMessage = "Estas seguro en cambiar la plantilla? Al confirmar todos los items y contenidos de la página seran eliminados";
    const sub = this.dialogMediaRef.componentInstance.selectedChildMedia.subscribe((data: any) => {
      //console.log(data.id)
      selectedMediaId = data.id;
      selectedMediaUrl = data.url
    });
    this.dialogMediaRef.afterClosed().subscribe(data => {
      if (data) {
        //  debugger
        if (selectedMediaId) {
          //this.media = selectedMediaId;
          if (e.target.parentNode.previousSibling.firstChild) {
            var m = e.target.parentNode.previousSibling.firstChild;
            var n = e.target.parentNode.nextSibling;
            e.target.nextSibling.disabled = false;
          }
          //n.setValue(selectedMediaId);
          m.src = selectedMediaUrl;
          n.value = selectedMediaId;

          if (group != null) {
           // if(subgroup != null){
              this.getFormGroup(group,subgroup).controls[field].setValue(selectedMediaId);
         
            //}else{
              //this.getFormGroup(group).controls[field].setValue(selectedMediaId);
         
            //}

            } else {
            this.blockForm.controls[field].setValue(selectedMediaId);
          }

          //n.valueChanges();
          // n.controls[field].setValue(selectedMediaId);
          //setValue = ;
        }
        sub.unsubscribe();
        // var query = this.afs.collection('pages').doc(this.pageId).collection('items').ref.where('pageId', '==', this.pageId);
        // query.get().then(function (querySnapshot) {
        //   querySnapshot.forEach(function (doc) {
        //     doc.ref.delete();
        //   });
        // });
      } else {
        //this.nextSelectedTemplate = this.selectedTemplate;
        return false;
      }
      this.dialogMediaRef = null;
    });
    //}
  }


  getMediaValue(field,group = null,subgroup = null) {
   // console.log('Group', group);
   // console.log('Subgroup', subgroup);
   // console.log('Field', field);
   
    //console.log(this.getItemFormGroup(index).controls[field].value)
    if (this.getFormGroup(group,subgroup).controls[field].value == "" || this.getFormGroup(group,subgroup).controls[field].value) {
      return this.getFormGroup(group,subgroup).controls[field].value;
    } else {
      return GlobalVariable.defaultMediaUrl;
    }
  }
  getFormGroup(group,subgroup): UntypedFormGroup {
     const groupA = this.blockForm.get(group) as UntypedFormArray;
    const formGroup = groupA.controls[subgroup] as UntypedFormGroup;
    return formGroup;
  }
  
  cleanMediaField(e, field, group = null,subgroup = null): void {
    if (e.target.parentNode.previousSibling) {
      var m = e.target.parentNode.previousSibling.firstChild.getElementsByTagName('img')[0];
      var n = e.target.parentNode.nextSibling;
      e.target.disabled = true;

    }
    m.src = GlobalVariable.defaultMediaUrl;
    n.value = ""

    if (subgroup != null) {
      this.getFormGroup(group,subgroup).controls[field].reset("");
    } else {
      this.blockForm.controls[field].reset("");

    }
    //this.media = "";
  }

 

}


