import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Media } from './media';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediasService {
   constructor(
    private afs: AngularFirestore
  ) {}
  //Crea un nuevo gato
  public createMedia(data:Media) {
    return this.afs.collection('medias').add(data);
  }
  //Obtiene un gato
  public getMedia(documentId: string) {
    return this.afs.collection('medias').doc(documentId).snapshotChanges();
  }
 
  //Obtiene todos los gatos
  public getMedias() {
    return this.afs.collection('medias', ref => ref.orderBy('createdOn', 'desc')).snapshotChanges();
  }
  //Actualiza un gato
  public updateMedia(documentId: string, data: any) {
    return this.afs.collection('medias').doc(documentId).set(data,{merge:true});
  }
  public deleteMedia(documentId: string) {
    return this.afs.collection('medias').doc(documentId).delete();
  }
}

export class YoutubeService {

  apiKey : string = 'AIzaSyDzSKGK7upjC8cuWkP7Oi8lEep9iQF_kws';

  constructor(public http: HttpClient) { }

    getVideosForChanel(channel, maxResults): Observable<Object> {
    let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.apiKey + '&channelId=' + channel + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults
    return this.http.get(url)
      .pipe(map((res) => {
        return res;
      }))
  }
}