import { Injectable } from '@angular/core';
import {ContactForm} from '../models/contact-form';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  type:any;
  url: string;
    
  constructor( private http: HttpClient) {
    this.url = 'assets/send.php'
   }
  getResponseEmail(_body: ContactForm): Observable<any>{
     //this.http.post('assets/send.php',_body).subscribe(data =>{
     //  console.log(data);
     //  this.type = JSON.stringify(data);
     //});
    return this.http.post(this.url,_body);
  }

}