import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MediasService } from '../medias.service';
import { Observable, from } from 'rxjs';
import { Media } from '../media';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss']
})
export class MediasComponent implements OnInit {
  medias: Media[];
  cols: number = 3;
  @Output() selectedMedia: EventEmitter<Media> = new EventEmitter()
  watcher: any;
  activeMediaQuery: string;
  selectedMediaItem: any;

  constructor(private mediasService: MediasService, private mediaObserver: MediaObserver) {
    

  }
  onSelect(media: any) { this.selectedMediaItem = media; }
  ngOnInit() {
    this.watcher = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      changes.forEach(change => {
        this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';

        // Access individual MediaChange properties:
        console.log(`Media query changed: '${change.mqAlias}' = (${change.mediaQuery})`);

        switch (change.mqAlias) {
          case 'xs':
            this.cols = (this.cols > 3) ? 2 : 1;
            break;
          case 'sm':
            this.cols = (this.cols > 3) ? 2 : 1;
            break;
          case 'md':
            this.cols = (this.cols == 1) ? 1 : 2;
            break;
          case 'lg':
            this.cols = this.cols;
            break;
          case 'xg':
            this.cols = this.cols;
            break;
          default:
            // this.cols = this.cols;
            // break;
        }
      });
    });
    // this.medias = this.mediasService.getMedias();
    //this.medias = this.mediasService.getMedias();
    this.mediasService.getMedias().subscribe(data => {
      this.medias = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {})
        } as Media;
      })
    });
  }
  setSelectedMedia(media: Media) {
    this.selectedMedia.emit(media);
    console.log("selectedMedia", media);

  }
}
