import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterByCategory'
})
export class FilterByCategoryPipe implements PipeTransform {

  transform(items: any, select?: any): any {
    if (select !== "All") {
      return select
        ? items.filter(item => item["category"] === select)
        : items;

    } else {
      return items;
    }
  }

}
