import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";


import { MediasRoutingModule } from './medias-routing.module';
//import { MediaComponent } from './media/media.component';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { MediasComponent } from './medias/medias.component';

@NgModule({
    declarations: [MediaDialogComponent, MediasComponent],
    imports: [
        CommonModule,
        SharedModule,
        MediasRoutingModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MediasModule { }
