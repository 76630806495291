import { Component, OnInit, Input } from '@angular/core';
//import { PARAMETERS } from '@angular/core/src/util/decorators';

@Component({
  selector: 'app-slick',
  templateUrl: './slick.component.html',
  styleUrls: ['./slick.component.scss']
})
export class SlickComponent implements OnInit {
  @Input() itemsArray: any[];
  @Input() cols:number;
 
  constructor() { }
  slideConfig:any;
  ngOnInit() {
    this.slideConfig = {
      "slidesToShow": this.cols, 
      "slidesToScroll": 1, 
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: this.cols,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: (this.cols>2)?2:this.cols,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: (this.cols>3)?2:1,
          slidesToScroll: 1
        }
      }
    ]};
    
  }

/*  slides = [
    {media: "https://firebasestorage.googleapis.com/v0/b/mptdatas.appspot.com/o/images%2Fheader-permiso-de-internet.jpg?alt=media&token=b0a2ca50-440d-4bf5-8132-1885e38c402f",link:"/trivias/front/zVbM1wIBpmLXDFVMnDMO",target:"_self"},
    {media: "https://firebasestorage.googleapis.com/v0/b/mptdatas.appspot.com/o/images%2Fheader-alimentacion.jpg?alt=media&token=6865e87e-6289-45ca-8de1-7f9e33e7ce80",link:"/trivias/front/8wThnfRWIMrkqydRvKqs",target:"_self"},
    {media: "https://firebasestorage.googleapis.com/v0/b/mptdatas.appspot.com/o/images%2Fheader-derechos-tute.jpg?alt=media&token=94cc423a-32af-4dc6-b9ba-8ad1769d255f",link:"http://www.mptutelar.gob.ar/",target:"_blank"}
  ];*/
  
  
  addSlide() {
    this.itemsArray.push({media: "http://placehold.it/350x150/777777",link:"/",target:"_self"})
  }
  
  removeSlide() {
    this.itemsArray.length = this.itemsArray.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }

}
