import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
//  encapsulation: ViewEncapsulation.Native
})
export class SliderComponent implements OnInit {
  selectedIndex: number;
  transform: number;
  @Input() itemsArray: [];
  constructor() {
    this.selectedIndex = 0;
    this.transform = 100;
  }
  
  //sliderArray = [];

  ngOnInit() {
   
  }

  selected(x) {
    this.downSelected(x);
    this.selectedIndex = x;

   }

   keySelected(x) {
     this.downSelected(x);
     this.selectedIndex = x;
   }
   downSelected(i) {
    this.transform =  100 - (i) * 50;
      this.selectedIndex = this.selectedIndex + 1;
      if(this.selectedIndex > 4) {
        this.selectedIndex = 0;
      }
   }

   imageSrc = '';
   messageText = '';
   imageButtons = [ {src:'https://images.pexels.com/photos/617278/pexels-photo-617278.jpeg?auto=compress&cs=tinysrgb&h=350', name: 'image-1'}, {src:'https://images.pexels.com/photos/104827/cat-pet-animal-domestic-104827.jpeg?auto=compress&cs=tinysrgb&h=350', name: 'image-2'}, 
   {src:'https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350', name: 'image-3'}]

   onClick(imageNameObject) {
     this.imageSrc = imageNameObject.image;
     this.messageText = imageNameObject.name;
   }
 
 



}
