import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/globals';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FacebookEventDialogComponent } from './facebook-event-dialog/facebook-event-dialog.component';

export interface EventElement {
  id: string;
  name: string;
  description: string;
  place?: any;
  start_time?: string;
  end_time?: string;
  ticket_uri?: string;
  cover?: any;
}
@Component({
  selector: 'app-facebook-events',
  templateUrl: './facebook-events.component.html',
  styleUrls: ['./facebook-events.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FacebookEventsComponent implements OnInit {
  //ELEMENT_DATA: EventElement[];
  ELEMENT_DATA: any[];
  dataSource = [];
  columnsToDisplay = ['start_time', 'name', 'ticket_uri'];
  expandedElement: EventElement;
  events: any;
  paging: any;
  public dialogRef: MatDialogRef<FacebookEventDialogComponent>;
 


  //Slider fields
  selectedIndex: number;
  transform: number;
  dataSize: number;
  type = 'slider';
  constructor(public dialog: MatDialog, private http: HttpClient) { }

  ngOnInit() {
    this.getEvents();

    //Slider fields
    this.selectedIndex = 0;
    this.transform = 100;
  }
  getEvents() {
    console.log('Geting Events');
    let date: Date = new Date();
    let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    let fakeDate =  '2019-' + date.getMonth() + '-' + date.getDate();
    const url = 'https://graph.facebook.com/' + Constants.page_id + '/events?access_token=' + Constants.accessToken + '&fields=event_times,name,start_time,ticket_uri,place,cover,description,end_time,timezone,type&since=' + fakeDate + '';
    console.log('DATA EVENTS', url);
    this.http.get(url)
      .subscribe(data => {
        console.log('DATA EVENTS', data);
        //evento sin class
        this.ELEMENT_DATA = data['data'];
        this.ELEMENT_DATA.sort(function (a, b) {
          var keyA = new Date(a.start_time),
            keyB = new Date(b.start_time);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        console.log(this.ELEMENT_DATA);
        this.dataSource = this.ELEMENT_DATA;
        this.dataSize = data['data'].length;
      });
  }


  openFacebookEventDialog( index: number): void {
    this.dialogRef = this.dialog.open(FacebookEventDialogComponent, {
      disableClose: false,
      maxHeight: '80vh',
      width: '850px',
      maxWidth: '80vw',
      panelClass: 'custom-dialog-box',
    });
    console.log('openFacebookEventDialog', index);
    this.dialogRef.componentInstance.events = this.dataSource;
    this.dialogRef.componentInstance.i = index;
    // this.contactDialogRef.componentInstance.blockId = id;
    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        // sub.unsubscribe();
      } else {
        return false;
      }
      this.dialogRef = null;
    });
  }

  /* Slider */
  // Slider Controlls
  selected(x) {
    this.downSelected(x);
    this.selectedIndex = x;
  }
  selectedPrev(x) {
    console.log('Prev Slider', x);
    this.downSelected((x - 1));
    this.selectedIndex = (x - 1);
  }
  selectedNext(x) {
    console.log('Next Slider', x);
    this.downSelected((x + 1));
    this.selectedIndex = (x + 1);
  }



  downSelected(i) {
    this.transform = 100 - (i) * 50;
    this.selectedIndex = this.selectedIndex + 1;
    if (this.selectedIndex > this.dataSource.length) {
      this.selectedIndex = 0;
    }
  }
  /*
    getPosts() {
      console.log("Geting Post");
      console.log("get facebook events");
      // tslint:disable-next-line: max-line-length
      //const accessToken = 'EAAJPWEwTdqYBADhPteSWUwGFLhE5zYprqGtdi6fUfUDs2NAfZB9EDAklrekCAppowceYk9eF468v63IMvvNxwuA94DaSvRYiDH6L3dAevxJbIxVzsxf5BJVKdHOZBTZClzFXbYv428kbZBe0639poG4J7wkEqhAKyOaV229ifAZDZD';
      // tslint:disable-next-line: max-line-length
      const accessToken = 'EAAJPWEwTdqYBAKQx3loGWsnhfnVadPOcpZBL1h8vY0uX0ZAkVJDjoWivtJiVIz9ZCC7tfNCHI2M2iZBFfZBCe2aiIuFd3YelA1O6nSOVzuIDuQtYRtjna7R6ShZBWmts2hiVVmpMz092FPAnvJfdBKFEjUBWK8l1ZBrZBWQEZAswpqAZDZD';
      return this.fb.api(
          "/126539221037/events/?access_token=" + accessToken,
          'get',
          { "type": "attending", "q": "a", "limit": "100", "since": "0", "until": "next year" }
        );
    }*/
}
