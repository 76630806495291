import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MediasService } from 'src/app/medias/medias.service';
import { GlobalVariable } from 'src/app/shared/globals';
import { Media } from 'src/app/medias/media';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, style, transition, animate, query, stagger, animateChild } from '@angular/animations';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
 animations: [
  trigger('fade', [
    //state('in', style({ opacity: 1 })),
    //state('out', style({ opacity: 0 })),
    transition('* <=> in', [
      style({  opacity: 0.1}),
     // query('.background', stagger(300, animateChild()), { optional: true }),
     animate('1s ease-out',style({ opacity: 1 }))
    ]),
    transition('* <=> out', [
     // query('.background', stagger(300, animateChild()), { optional: true }),
     animate('1s ease-out',style({ opacity: 0.1 }))
    ])
  ])
    /*trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2s ease-out',style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out',style({ opacity: 0 }))
      ]),
      
    ])*/
  ]
})
export class BackgroundComponent implements OnInit {
  state = 'in';
  counter = 0;
  enableAnimation: any;
  finaldata: any;

  constructor(
    private mediasService: MediasService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }
  @Input() id: any;
  @Input() mediaClass: string;
  @Input() type = 'image';
  @Input() align = 'center';

  media = 'assets/img/transparentImg.png'; // Just a random loading gif found on google.
  class = 'img-responsive';  preload: boolean;
  // throw new Error("Method not implemented.");


  tmpImg: HTMLImageElement; // will be used to load the actual image before showing it
  ngOnInit(): void {
    //console.log('IS ID VALUE', this.id);
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.enableAnimation = true;
    this.counter = 0;
    this.toggleState();
     //console.log("Media ID",this.id);
    // console.log("Media Class", this.mediaClass);
    if (this.id !== '') {
      this.mediasService.getMedia(this.id).subscribe(data => {
        const mediaData = data.payload.data() as Media;
        // console.log(mediaData)
        if (mediaData) {
          if (mediaData.url == null || mediaData.url === '') {
            //this.generateImage(GlobalVariable.defaultMediaUrl);
            this.finaldata = GlobalVariable.defaultMediaUrl;
            this.generateImage();
          } else {
            //this.generateImage(mediaData.url);
            this.finaldata = mediaData.url;
            this.generateImage();
           }
        }
      });
    } else {
      this.media = GlobalVariable.defaultMediaUrl;
    }
  }

  generateImage() {
    //this.media = 'assets/img/transparentImg.png'; // Just a random loading gif found on google.
    this.preload = true;
    const loaded = () => { // wait for image to load then replace it with loadingGIF
      this.toggleState();
      this.media = this.finaldata;
      this.preload = false;
    };

    // background loading logic
    if (this.tmpImg) {
      this.tmpImg.onload = null; // remove the previous onload event, if registered
    }
    this.tmpImg = new Image();
    this.tmpImg.onload = loaded;  // register the onload event
    this.tmpImg.src =  this.finaldata;
  }

  toggleState() {
    if (this.counter < 2) {
      this.state = this.state === 'in' ? 'out' : 'in';
      this.counter++;
    }
  }

  onDone($event) {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        this.generateImage();
      }
      
    }
  }

}
